
.form-radiobutton {
    position: relative;

    vertical-align: top;

    & + & {
        margin-top: $grid-spacing;
    }

}

.form-radiobutton__label {
    display: flex;
    justify-content: space-between;

    width: 100%;

    @include respond-min($s) {
        width: toRem(240px);
    }

    @include respond-min($m) {
        width: 50%;
    }

    cursor: pointer;

    padding-left: toRem($grid-spacing-2);

    &:focus {
        outline: none;
    }

}

.form-radiobutton__input {
    @include accHide;

    & + .form-radiobutton__label {

        &:before {
            position: absolute;
            left: 0;
            top: toRem(2px);

            width: toRem(20px);
            height: toRem(20px);

            background-color: $white;

            border: toRem(1px) solid $ns-grey-6;
            border-radius: 50%;

            padding: 0;

            content: '';

            cursor: pointer;
        }

        &:after {
            position: absolute;
            left: toRem(5px);
            top: toRem(7px);

            display: block;
            width: toRem(10px);
            height: toRem(10px);

            background-color: $white;

            border-radius: 50%;

            padding: 0;

            line-height: 0;

            content: '';

            cursor: pointer;

            transition: background-color 0.2s ease-in-out;
        }

    }

    &:checked + .form-radiobutton__label {
        color: $ns-blue;

        &:after {
            background-color:$ns-blue-3;
        }

    }

    &:focus + .form-radiobutton__label {

        &:before {
            outline: toRem(1px) dotted;
            outline-offset: toRem(5px);
        }

    }

}

// START IE11 workaround for flexbox bug
// https://github.com/philipwalton/flexbugs/issues/128
.form-radiobutton__label-text,
.form-radiobutton__label-add-text {
    @include target('ie11') {
        flex-shrink: 1;
        width: 50%;
    }
}
.form-radiobutton__label-add-text {
    @include target('ie11') {
        text-align: right;
    }
}
// END IE11 workaround for flexbox bug

.form-radiobutton__label-add-text {
    position: relative;
    top: toRem(-1px); // pull up text pushed down by bold rendering

    color: $ns-blue;
}
