// ---------------------------------------------------------------------------
// +++ COVERALL +++
// ---------------------------------------------------------------------------

// Sets an element to cover all of its container

// Usage:
// - Default is 0, set other values if so desired.
// - Container needs to have position other than static defined!


@mixin coverAll($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;

  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// ---------------------------------------------------------------------------
// +++ ACCHIDE +++
// ---------------------------------------------------------------------------

// hides content from view, but keeps it exposed to assistive technologies.

@mixin accHide {
  position:absolute;

  left: -999em;

  clip-path: polygon(0 0, 0 0, 0 0, 0 0);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE11
    // deprecated!
    clip: rect(1px 1px 1px 1px);
  }

  @supports (-ms-ime-align:auto) { // Edge
    // deprecated!
    & { clip: rect(1px 1px 1px 1px); }
  }

}

// shows content hidden by accHide, for instance on state.

@mixin accShow($position: static, $left: auto) {
  position: $position;

  left: $left;

  clip-path: none;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE11
    // deprecated!
    clip: auto;
  }

  @supports (-ms-ime-align:auto) { // Edge
    // deprecated!
    & { clip: auto; }
  }

}

// ---------------------------------------------------------------------------
// +++ FONTSMOOTHING +++
// ---------------------------------------------------------------------------

// applies font-smoothing for webkit and mozilla.

// Usage:
// - Only set this on large (bold) text, or decorative symbols if needed.
// - handle with care

@mixin fontSmoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// ---------------------------------------------------------------------------
// +++ TARGET BROWSER +++
// ---------------------------------------------------------------------------

// tool for targeting specific browsers and keep hacks maintainable

@mixin target($browser) {

  @if ($browser == "ie11") {
    @media screen and (min-width:0\0) {
      @content;
    }
  }

  @if ($browser == "edge") {
    @supports (-ms-ime-align:auto) {
      @content;
    }
  }

  @if ($browser == "chrome") {
    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
      @content;
    }
  }

  @if ($browser == "firefox") {
    @media screen and (min--moz-device-pixel-ratio:0) {
      @content;
    }
  }

  @if ($browser == "safari") {
    @media not all and (min-resolution:.001dpcm) {
      @content;
    }
  }

}

// ---------------------------------------------------------------------------
// +++ HYPHENATE +++
// ---------------------------------------------------------------------------

// USAGE:
// - if auto and 1200px max viewport, no args needed
// - available args for value: none | manual | auto
// Use entire variable name as arg for viewport size, including '$'!
// If value is none or manual, no viewport arg is needed.
// Example: @include hyphenate(auto, $xxxl);
// https://css-tricks.com/almanac/properties/h/hyphenate/

@mixin hyphenate($value: auto, $maxViewport: $xxl) {

  hyphens: $value;

  @include respond-min($maxViewport) {
    hyphens: none;

    @if ($value == "manual") {
      hyphens: $value;
    }

  }

  @supports not (hyphens: $value) {

    @if ($value == "auto") {
      word-break: break-word;

      @include respond-min($maxViewport) {
        word-break: normal;
      }

    }

    @if ($value == "none") {
      word-break: keep-all;
    }

    @if ($value == "manual") {
      word-break: normal;
    }

  }

}


// ---------------------------------------------------------------------------
// +++ NS BOX SHADOW +++
// ---------------------------------------------------------------------------

@mixin ns-box-shadow($opacity: 0.15, $position: bottom) {
    @if ($position == bottom) {
        box-shadow: 0 toRem(2px) 0 0 transparentize(ns-grey(1), (1-$opacity));
    }
    @else {
        box-shadow: 0 toRem(-2px) 0 0 transparentize(ns-grey(1), (1-$opacity));
    }
}

// ---------------------------------------------------------------------------
// +++ NS TEXT SHADOW +++
// ---------------------------------------------------------------------------

@mixin ns-text-shadow($opacity: 0.15, $position: bottom) {
    @if ($position == bottom) {
        text-shadow: 0 toRem(2px) toRem(2px) transparentize($black, (1-$opacity));
    }
    @else {
        text-shadow: 0 toRem(-2px) toRem(2px) transparentize($black, (1-$opacity));
    }
}

// ---------------------------------------------------------------------------
// +++ BOUNDING BOX +++
// ---------------------------------------------------------------------------

@mixin bounding-box($top: 10px, $right: 10px, $bottom: 10px, $left: 10px, $border-radius: none) {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: calc(100% + #{toRem($left)} + #{toRem($right)});
        height: calc(100% + #{toRem($top)} + #{toRem($bottom)});
        left: toRem(-$left);
        top: toRem(-$top);
        @if($border-radius != 'none') {
            border-radius: $border-radius;
        }
    }
}

// ---------------------------------------------------------------------------
// +++ OUTLINE +++
// ---------------------------------------------------------------------------

@mixin outline {
    outline: toRem(1px) dotted $ns-grey-5;
}

// ---------------------------------------------------------------------------
// +++ OBJECT FIT +++
// ---------------------------------------------------------------------------

// Styles an image to cover a container using object fit, has fallback.

@mixin objectFit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0;

    .no-object-fit & {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        height: auto;
        max-width: none;

        &.is-wider {
            height: 100%;
            width: auto;
        }
    }
}
