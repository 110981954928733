/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<textarea class="form-textarea">This is a simple texarea</textarea><br><br>

*/

.form-textarea {
    @include input;
    height: auto;
    max-width: 100%;
}

.form-textarea--fullWidth {
    width: 100%;
}
