/* ===== TOOLS
 * This contains the globally available tooling – namely mixins and functions.
 * Any mixin or function that does not need accessing globally should belong in the partial to which it relates.
 */

@import '~@ns/nsdesign-base/src/scss/functions';
@import '~@ns/nsdesign-base/src/scss/mixins';
@import '~@ns/nsdesign-base/src/scss/_mixins.tools';

@import 'functions';
@import 'mixins';
@import 'animations';
@import 'grid';
@import 'svg-icons';
@import 'z-indexes';

@mixin unlist {
    list-style: none;
    margin: 0;
    padding: 0;

    li:before {
        content: none;
    }
}
%unlist {
    @include unlist;
}
