.page-service__side {
    width: 100%;
    margin-bottom: -$grid-spacing-2;

    // Edge's <select> border-bottom is cut away by negative margin
    @include target('edge') {
        margin-bottom: -($grid-spacing-2 - 1);
    }

    @include respond-min($m) {
        margin-bottom: $grid-spacing-2;
    }

    @include respond-min($l) {
        @include grid-column(2/5);
    }

    @include respond-min($xl) {
        @include grid-column(1/3);
    }
}

.page-service__body {
    width: 100%;
    margin-bottom: $grid-spacing;

    @include respond-min($l) {
        @include grid-column(3/5);
        margin-bottom: 0;
    }

    @include respond-min($xl) {
        @include grid-column(2/3);
    }
}
