/*

@name icon-content
@description icon-content; block that has a list of icons and texts
@section Organisms
@type UI
@extended false

@markup
<section class="icon-content">
    <h2 class="icon-content__title">Inbegrepen</h2>
    <ul class="icon-content__list">
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#dagretour"></use>
            </svg>
            <p class="icon-content__description">Dagretour naar keuze</p>
        </li>
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#voucher"></use>
            </svg>
            <p class="icon-content__description">Voucher voor koffie en macaron XXL bij Grand Café Krasnapolsky</p>
        </li>
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#schedule"></use>
            </svg>
            <p class="icon-content__description">Te koop t/m 30 aug, geldig t/m 31 sep</p>
        </li>
    </ul>
</section>

*/

// BASE
// ----

.icon-content {
    width: 100%;
}

// PARTS
// -----

// TITLE

.icon-content__title {
    margin-bottom: toRem($grid-spacing-2);
}

// LIST

.icon-content__list {
    @include unlist;

    @include respond-min($m) {
        display: flex;
        flex-flow: row wrap;
    }
}

// ITEM

.icon-content__item {
    margin-bottom: toRem(24px);
    display: flex;
    padding: 0;

    &:last-child {
        margin-bottom: 0;
    }

    // Encapsulated for small as overriding align-items in IE11 means trouble
    @include respond-minMax($s, $m) {
        align-items: center;
    }

    @include respond-min($m) {
        @include grid-column(1/2 2);
        flex-direction: column;
        align-items: left;
    }

    @include respond-min($l) {
        @include grid-column(1/3);
        margin-bottom: 0;
    }
}

// ICON

.icon-content__icon {
    flex: 0 0 auto;
    margin-right: toRem(15px);

    @include respond-min($m) {
        margin-right: 0;
        margin-bottom: toRem(8px);
    }
}

// DESCRIPTION

.icon-content__description {
    margin: 0;
    color: $ns-grey-3;
}

// OPTIONS
// -------

// PADDED

.icon-content__icon--padded {
    padding: toRem(4px);
}

// ICON IN LINE

.icon-content--icon-in-line {

    .icon-content__item {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        @include respond-min($m) {
            align-items: center;
        }

        width: auto;
    }

    .icon-content__icon {
        margin-right: $grid-spacing-2 / 2;
    }

    .icon-content__description {
        font-size: toRem(14px);

        @include respond-min($m) {
            font-size: toRem(16px);
        }

    }

    .icon--ml #schedule {
        width: 48px;
        height:41px;
    }

}

// CENTERED

.icon-content--centered {
    width: 100%;

    margin: 0 0 toRem($grid-spacing-2);

    @include respond-min($m) {
        padding: toRem($grid-spacing-2) 0;
    }

    .icon-content__list {

        @include respond-min($ml) {
            justify-content: center;
        }

    }

    .icon-content__item {
        margin: $grid-spacing 0;

        @include respond-min($m) {
            margin: $grid-spacing ($grid-spacing * 4) $grid-spacing 0;

            &:last-child {
                margin-right: 0;
            }

        }

        @include respond-min($ml) {
            margin: 0 ($grid-spacing * 4) 0 0;
        }

    }

}
