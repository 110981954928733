/* Component name is in camelcase to reuse NS Design template */
.timetableList {
    margin: 0;
}

.timetableList__key {
    display: inline;
    font-weight: normal;
    color: $body-color;
}

.timetableList__value {
    display: inline;

    &:after {
        content: '\A';
        white-space: pre-wrap;
    }

}
