/*

@name Button
@description Button; used for form-actions and major CTA links.
@section Atoms
@type UI
@extended false

@modifier .button--arrow                    - Button with an arrow
@modifier .button--secondary                - Button with secondary colour
@modifier .button--link                     - Button that looks like a link
@modifier .button--icon                     - Button that looks like an icon

@markup
<button class="button {{modifier_class}}">Default Button</button>
<button disabled="disabled" class="button {{modifier_class}}">
    Disabled Button
</button>
<a href="javascript:void(0)" class="button {{modifier_class}}">Anchor Button</a>

*/

$button-primary__background: $ns-light-blue;
$button-primary__hover: darken($ns-light-blue, 10%);
$button-primary__color: $white;

$button-secondary__background: $ns-yellow;
$button-secondary__hover: darken($ns-yellow, 10%);
$button-secondary__color: $ns-blue;

$button-tertiary__background: transparent;
$button-tertiary__hover: $ns-blue;
$button-tertiary__color: $ns-light-blue;

$button-disabled__background: $ns-grey-8;
$button-disabled__hover: $ns-grey-7;
$button-disabled__color: $ns-grey-6;

@mixin button-disabled {
    background-color: $button-disabled__background;
    color: $button-disabled__color;
    cursor: not-allowed;
    box-shadow: 0 toRem(-2px) 0 $button-disabled__hover inset;
    transition: none;

    &:focus,
    &:hover {
        color: $button-disabled__color;
        box-shadow: 0 toRem(-2px) 0 $button-disabled__hover inset;
        cursor: not-allowed;
    }
}

.button {
    position: relative;
    padding-left: toRem(24px);
    padding-right: toRem(24px);
    height: toRem(45px);
    background-color: $button-primary__background;
    box-shadow: 0 toRem(-2px) 0 $button-primary__hover inset;
    color: $button-primary__color;
    transition: box-shadow $transition-speed $default-easing;
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -webkit-inline-box; // Needed for Safari/IOS Safari (CMS-7543)
    justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    white-space: nowrap;

    &:focus,
    &:hover {
        color: $button-primary__color;
        text-decoration: none;
        cursor: pointer;
        box-shadow: 0 toRem(-50px) 0 $button-primary__hover inset;
    }

    &[disabled] {
        @include button-disabled;
    }
}

.button--secondary {
    background-color: $button-secondary__background;
    box-shadow: 0 toRem(-2px) 0 $button-secondary__hover inset;
    color: $button-secondary__color;

    &:focus,
    &:hover,
    &:active {
        color: $button-secondary__color;
        box-shadow: 0 toRem(-50px) 0 $button-secondary__hover inset;
    }
}


.button--tertiary {
    background-color: $button-tertiary__background;
    color: $button-tertiary__color;
    border: 1px solid $button-tertiary__color;
    border-bottom: 0;
    box-shadow: 0 toRem(-2px) 0 $button-tertiary__color inset;

    &:focus,
    &:hover,
    &:active {
        box-shadow: 0 toRem(-2px) 0 $button-tertiary__hover inset;
        border-color: $button-tertiary__hover;
        color: $button-tertiary__hover;
    }

    &[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            border-color: $button-disabled__hover;
            color: $button-disabled__color;
        }

    }
}

.button--link {
    padding: 0;
    background: none;
    box-shadow: none;
    color: $ns-light-blue;
    display: inline;
    height: auto;
    text-align: left;

    &:focus {
        color: $ns-light-blue;
        box-shadow: none;
    }

    &:hover {
        color: $ns-blue;
        box-shadow: none;
    }
}

.button--icon {
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    height: auto;
    line-height: $line-height-base / $font-size-base;
    &:focus,
    &:hover {
        box-shadow: none;
    }
}

.button--full {
    width: 100%;
}

.button--square {
    width: toRem(45px);
    padding-left: 0;
    padding-right: 0;
}

.button--icon {
}

.button__icon {
    // Nothing special
}

.button__text {
    vertical-align: middle;
}

.button__icon--left {
    margin-right: toRem($spacing);
}

.button__icon--right {
    margin-left: toRem($spacing);
}

.button--disabled {
    @include button-disabled;
}
