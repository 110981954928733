.page-order {

}

.page-order__inner {
    width: 100%;
    padding-top: 0;
    padding-bottom: $grid-spacing;

    @include respond-min($m) {
        padding-bottom: $grid-spacing-2;
    }

    @include respond-min($l) {
        padding-top: toRem(30px);
        padding-bottom: toRem(30px);
    }
}

.page-order__configurator {
    width: 100%;
    margin-bottom: $grid-spacing;

    @include respond-min($l) {
        @include grid-column(3/5);
        margin-bottom: 0;
    }

    @include respond-min($xl) {
        @include grid-column(2/3);
    }
}

.page-order__ticket {
    width: 100%;

    @include respond-min($l) {
        @include grid-column(2/5);
    }

    @include respond-min($xl) {
        @include grid-column(1/3);
    }
}

.page-order__icon-content {
    display: none;
    width: 100%;

    @include respond-min($l) {
        display: block;
    }

}
