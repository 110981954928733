/*
@name pageIntro
@description Represents intro title text update on on the page.
@section components

@markup
<div class="pageIntro">
        <h1 class="pageIntro__title heading heading--xxxl heading--bold">title</h1>
        <div class="pageIntro__text">
            <p>text</p>
            <p>text</p>
        </div>
        <p  class="pageIntro__updatedOn">Bijgewerkt op 12 juni 2020</p>
</div>
*/

.pageIntro {
    @include container-content();

    @include respond-min($m) {
        @include grid-column(6/6);
        @include grid-offset(0);
    }

    @include respond-min($ml) {
        @include grid-column(5/6);
        @include grid-offset(1/12);
        padding-top: $grid-spacing-2;
        padding-bottom: $grid-spacing-2;
        text-align: center;
    }
}

.pageIntro__text {
    margin-top: toRem($spacing-4);
    font-size: toRem(18px);
    color: $ns-blue;

    @include respond-min($ml) {
        font-size: toRem(20px);
    }
}

.pageIntro__updatedOn {
    color: $ns-grey-3;
    font-size: toRem(14px);
    margin-top: toRem($spacing-4);

    @include respond-min($ml) {
        margin-top: toRem($spacing-6);
    }
}
