// WIDTHS
// ======

// FIXED 5% 10% ... 100%

$i: 0;
@while $i < 100 {
  $i: $i + 5;
  .h-w-#{$i} {
    width: #{$i}% !important;
  }
}
