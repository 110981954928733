// #### CTA BOX ####
// =================

// BASE
// ----

.cta-box {
    background-color: $white;

    padding: $spacing-4 $spacing-6 $spacing-6;

    @include ns-box-shadow;

    > * + * {
        margin-top: $spacing-4;
    }

}

// PARTS
// -----

// BANNER

.cta-box__banner {}

// GRAPHIC

.cta-box__graphic {

    .ns-blue {
        fill: $ns-blue;
    }

    .ns-yellow {
        fill: $ns-yellow;
    }

}

// TEXT

.cta-box__text {
    margin-bottom: 0;
    color: $ns-blue;

    @include fontSize('xs');
}
