/*

@name Price
@description Price; used for showing a price
@section Atoms
@type UI
@extended false

@markup
<span class="price">€ 4</span>

*/

.price {
    font-size: toRem(36px);
    font-weight: 700;
    @include fontSmoothing;
    color: $ns-blue;
}
