// default container
.container {

}
.container {
    position: relative;
    @include grid-wrapper();

    @media print {
        width: 100%;
    }
}

.container--no-flex {
    // In future, we want this to be the default container behavior, with the current container flexbox variant becoming a modifier
    @include grid-wrapper(false);
}

.container--full-small {
    padding-right: 0;
    padding-left: 0;

    @include respond-min($m) {
        padding-left: toRem($grid-gutter);
        padding-right: toRem($grid-gutter);
    }
}

.container--full-medium {
    padding-left: 0;
    padding-right: 0;

    @include respond-min($l) {
        padding-left: toRem($grid-gutter);
        padding-right: toRem($grid-gutter);
    }
}

.container--compact {
    max-width: toRem(640px);

    @include respond-min($l) {
        max-width: $grid-outer-width;
    }
}

.container--paddedBottom {
    padding-bottom: $grid-spacing-3;

    /* in the template the h-mb-* classes can not be excluded on the last-child with handlebars */
    & :last-child {
        margin-bottom: 0 !important;
    }

    @include respond-min($m) {
        padding-bottom: $grid-spacing-5;
    }
}

@mixin container-content() {
    width: 100%;
    margin-bottom: $grid-spacing-2;
    padding-top: $grid-spacing;
    padding-bottom: $grid-spacing;

    @include respond-min($m) {
        @include grid-column(5/6);
        @include grid-offset(1/12);
        padding-top: $grid-spacing-2;
        padding-bottom: $grid-spacing-2;
    }

    @include respond-min($l) {
        @include grid-column(4/6);
        @include grid-offset(1/6);
    }
}

.container__content {
    @include container-content();
}
