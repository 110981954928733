// SHOWCASE
// ========

// SETTINGS
// --------

// VARS

$showcaseSpacing: $grid-gutter / 2;

// BASE
// ----

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $grid-gutter;
    width: 100%;
    margin-bottom: $grid-gutter;

    @include respond-min($m) {
        grid-template-columns: repeat(12, 1fr);
    }
    // Qualified to target only :before pseudo when it's in a list-item. forgive me.
    // TODO: refactor list so :before styling is no longer applied on base level. After that, remove this.
    li:before {
        display: none;
    }

    .no-cssgrid & {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% + #{$grid-gutter});
        margin: 0 toRem(-$grid-gutter/2);
    }
}

// PARTS
// -----

// ITEM

.grid__item {

    .no-cssgrid & {
        width: 100%;
        margin: 0 toRem($grid-gutter/2) toRem($grid-gutter);
    }

    &-m-col-6 {
        @include respond-min($m) {
            grid-column: span 6;

            .no-cssgrid & {
                width: calc(100%/2 - #{$grid-gutter});
            }
        }
    }

    &-m-col-12 {
        @include respond-min($m) {
            grid-column: span 12;

            .no-cssgrid & {
                margin-top: 0;
                margin-bottom: $showcaseSpacing;
            }
        }
    }

    &-l-col-4 {
        @include respond-min($l) {
            grid-column: span 4;

            .no-cssgrid & {
                width: calc(100%/3 - #{$grid-gutter});
            }
        }
    }

    &-xxl-col-4 {
        @include respond-min($xxl) {
            grid-column: span 4;

            .no-cssgrid & {
                width: calc(100%/3 - #{$grid-gutter});
            }
        }
    }

    &-xxl-col-8 {
        @include respond-min($xxl) {
            grid-column: span 8;

            .no-cssgrid & {
                margin: 0 toRem($grid-gutter/2) toRem($grid-gutter);
                width: calc(100%/3 - #{$grid-gutter});
            }
        }
    }

    &-xxl-row-2 {
        @include respond-min($xxl) {
            grid-row: span 2;
        }
    }

}


// OPTIONS
// -------
