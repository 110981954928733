.darkener {

    width: 100%;
    height: 100%;
    min-height:100%;
    top: 0;
    left: -100%;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    background-color: transparentize(ns-grey(1), 0.7);
    cursor: pointer;
    backface-visibility: hidden; // performance

    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity $transition-speed $default-easing;
    }

    &.fade-enter,
    &.fade-leave-to {
        opacity: 0
    }

    &.is-visible {
        left: 0;
    }
}
