/*

@name Hero image
@description Hero image for the top of the detail page
@section Molecules
@type UI
@extended true

@markup
<header class="hero">
    <picture class="hero__picture">
        <source srcset="images/hero_thema_1400x480.jpg" media="(min-width: 912px)">
        <source srcset="images/hero_thema_912x480.jpg" media="(min-width: 640px)">
        <source srcset="images/hero_thema_640x300.jpg" media="(min-width: 0px)">
        <img class="hero__image" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" class="img--contain" loading="eager">
    </picture>
    <div class="container hero__container">
        <h1 class="hero__title">Dagje uit en chocola</h1>
    </div>
</header>
*/

// HERO
// ====

// SETTINGS
// --------

// VARS

$hero-footer-height: 58px;

// BASE
// ----
.hero {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    overflow: hidden;
}

// PARTS
// -----

// PICTURE

.hero__wrapper {
    position: relative;
    min-height: toRem(260px);
    height: auto;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    @include respond-min($m) {
        min-height: toRem(480px);
        height: auto;
    }
}

.hero__wrapper--small {
    min-height: toRem(198px);

    @include respond-min($m) {
        min-height: toRem(320px);
    }

    @include respond-min($xxxl) {
        min-height: toRem(480px);
    }
}

.hero__picture {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:not(&--noGradient):after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    }
}

// IMAGE

.hero__image {
   @include objectFit;
}

// CONTAINER

.hero__container {
    @include grid-row-container;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    justify-content: flex-end;
    justify-self: flex-end;

    padding-top: $grid-spacing-2;

    height: 100%;
    width: $grid-outer-width;

    max-width: 100%;
    min-height: toRem(260px);

    @include respond-min($m) {
        min-height: toRem(480px);
    }
}

// TITLE

.hero__title {
    order: 2;

    margin-bottom: toRem($grid-spacing);

    &:last-child {

        margin-bottom: toRem($grid-spacing + $hero-footer-height);

        @include respond-min($m) {
            margin-bottom: toRem($grid-spacing-2 + $hero-footer-height);
        }
    }

    color: white;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
    font-weight: 700;
    @include fontSmoothing;
    @include fontSize('xxxxl');

    @include hyphenate(auto, $xl);

    @include respond-min($m) {
        margin-bottom: toRem($grid-spacing-2);
    }

    @include respond-min($ml) {
        @include grid-column(5/6);
    }

    @include respond-min($xxl) {
        @include grid-column(2/3);
    }
}

// STICKER CONTAINER

.hero__sticker-container {
    display: flex;
    order: 1;
    flex-flow: row wrap;

    max-width: 100%;

    margin-bottom: toRem($grid-gutter / 2);
}

// SUBTITLE

.hero__subtitle {
    order: 3;

    margin-top: toRem(-$grid-spacing);
    margin-bottom: toRem($grid-spacing-2);

    font-size: toRem(24px);
    @include respond-min($m) {
        font-size: toRem(38px);
    }
    color: white;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
}

// LOGO

// defined in .hero--logo-only
.hero__logo {}

// FOOTER

.hero__footer {

    display: flex;
    align-items: center;
    order: 3;

    min-height: toRem($hero-footer-height - 1);
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    @include respond-min($m) {
        @include grid-column(1/2);
    }
    @include respond-min($l) {
        @include grid-column(2/3);
    }
}

// OPTIONS
// -------

// FULL WIDTH FOOTER

.hero--full-width-footer {
    .hero__footer {
        @include grid-column(1/1);
    }
}

// LOGO ONLY

.hero--logo-only {

    .hero__wrapper,
    .hero__container {
        min-height: 0;

        @include respond-min($m) {
            min-height: 0;
        }
    }

    .hero__container {
        padding-bottom: $grid-spacing * 2;
    }

    .hero__title {
        margin-bottom: 0;

        line-height: 0;
    }

    .hero__subtitle {
        @include accHide;
    }

    .hero__logo {
        max-width:100%;
        max-height:toRem(150px);

        width: auto;
        height: auto;

        margin: 8vw 0;

        @include respond-min($m) {
            margin: $grid-spacing-4 0;
        }

    }

}
