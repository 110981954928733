// STAR RATING
// ===========

// BASE

.star-rating {
    display: flex;
}

// PARTS
// -----

// STAR

.star-rating__star {
    & + &  {
        margin-left: 2px;
    }
}

// GRAPHIC

.star-rating__icon {
}

// TEXT

.star-rating__text {
    @include accHide;
}
