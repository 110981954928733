button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

address {
    font-style: normal;
}

figure {
    margin: 0;
}

button,
[type="button"],
[type="submit"],
[type="file"],
::-webkit-file-upload-button {
    border-radius: 0; // override Chrome62 UA styling (border-radius: 4px)
}
