
.component-overlay {
    background-color: rgba($ns-grey-8, 0.88);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.component-overlay__loader {
    position: absolute;
    width: 56px;
    height: 56px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.component-overlay__response {
    display: flex;
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.component-overlay__response-icon {
    flex: 0 0 auto;
    margin-right: $grid-spacing;
}

.component-overlay__response-body {
    flex: 1 1 auto;
}

.component-overlay--alert {
    color: $ns-red-alert;
}

.component-overlay--calendar {
    width: calc(100% + #{$grid-gutter * 2} + 2px);
    margin-left: -#{$grid-gutter + 1px};
    height: calc(100% - 77px);
    top: 77px;

    @include respond-min($m) {
        width: 100%;
        margin-left: 0;
        top: 93px;
        height: calc(100% - 93px);
    }
}
