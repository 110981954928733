$menu-width: toRem(280px);
$menu-outer-width: toRem(290px);

.navigation {}

.navigation__list {

    z-index: z('menu');
    cursor: default;
    @include list-reset;

    @include respond-minMax(0, $l) {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        top: 0;
        right: -100%;
        background-color: $ns-white;
        transition: transform $transition-speed $default-easing;
        &.is-open {
            transform: translateX(-100%);
        }
    }

    @include respond-minMax($m, $l) {
        max-width: $menu-width;
        right: -$menu-outer-width;
        &.is-open {
            transform: translateX(-$menu-outer-width);
        }
    }

    @include respond-min($l) {
        display: inline;
    }
}

.navigation__list-item {
    @include respond-minMax(0, $l) {
        &:first-child {
            margin-top: toRem($global--header-height + $grid-spacing-2);
        }
    }
    @include respond-min($l) {
        display: inline;
        &:not(:last-of-type) {
            margin-right: toRem($grid-spacing/2);
        }
    }
}

.navigation__list-item--close {
    position: absolute;
    top: toRem(($global--header-height - 25px)/2); // 25px = icon height
    right: toRem($grid-spacing-2);
    @include respond-min($l) {
        @include accHide;
    }
}

.navigation__link {

    $link-h-padding: toRem($grid-spacing/2);
    $link-v-padding: toRem($grid-spacing-2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: toRem($grid-spacing/2) 0;
    padding: $link-h-padding $link-v-padding;
    color: $ns-blue;

    &:focus {

        @include respond-minMax($s, $l) {
            outline: 1px dotted $ns-grey-4;
        }

    }

    @include respond-min($l) {

        position: relative;
        display: inline-block;
        margin: 0;
        padding: toRem($grid-spacing/2) toRem($grid-spacing/2);

        &:after {
            content: '';
            position: absolute;
            width: calc(100% - #{$link-h-padding} * 2);
            height: toRem(3px);
            bottom: toRem(-12px);
            left: $link-h-padding;
            background-color: $ns-blue;
            transition: transform $transition-speed $default-easing;
            transform: scaleX(0);
            transform-origin: 0;
        }

        &:hover:after,
        &:focus:after {
            transform: scaleX(1);
        }
    }
}

.navigation__link-text {

    width: fit-content;

    @include respond-min($l) {
        display: inline-block;
        transition: transform $transition-speed $default-easing;
        .navigation__link:hover &,
        .navigation__link:focus & {
            transform: translateY(toRem(-2px));
        }
    }
}

.navigation__link-icon {

    transition: transform $transition-speed $default-easing;
    .navigation__link:hover & {
        transform: translateX(toRem(10px));
    }

    @include respond-min($l) {
        display: none;
    }

}

.navigation__button {
    right: toRem(-$spacing-4);

    height: toRem($global--header-height - 2);
    width: toRem($global--header-height - 2);

    overflow: hidden;

    @include respond-min($l) {
        display: none;
    }

    // dirty selector for re-aligning the close button when there's a notification bar
    .notification-bar + .site-header &--close {
        position: fixed;
        top: toRem(18px);
        right: toRem(2px);
    }
}

.navigation__button--close {
    z-index: z('menu') + 1;
}
