dl {

}

dt {
    font-weight: 700;
    color: $ns-blue;
    @include fontSmoothing;
}

dd {
    margin-left: 0;
    margin-bottom: toRem($grid-gutter / 2);
}

dd:last-child {
    margin-bottom: 0;
}
