$z-indexes: (

    // generic
    "header":                  20,
    "sticky":                  10,
    "menu":                    30,
    "overlay":                 40
);


@function z($index) {
    @if not map-has-key($z-indexes, $index) {
        @warn "No z-index found for `#{$index}` in $z-indexes map. Property will return null.";
    }
    @return map-get($z-indexes, $index);
}
