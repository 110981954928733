// PRODUCT OVERVIEW
// ================


// BASE
// ----

.product-overview {
    @include unlist;
    width: 100%;
}

// PARTS
// -----

// ITEM

.product-overview__item {
    @include grid-row-container;
    margin-bottom: toRem($grid-gutter);
    padding: 0;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    @include respond-min($m) {
        margin-bottom: toRem($grid-gutter * 2);
    }
}

.product-overview__item-content {
    width: 100%;

    @include respond-min($l) {
        @include grid-column(1/2);
    }

    @include respond-min($xl) {
        @include grid-column(5/12 2);
    }
}

.product-overview__item-gallery {
    width: 100%;

    @include respond-min($l) {
        @include grid-column(1/2);
    }

    @include respond-min($xl) {
        @include grid-column(6/12 2);
    }
}

// TITLE

.product-overview__title {
    margin-bottom: toRem($spacing);

    @include respond-min($m) {
        margin-bottom: toRem($spacing-2);
    }
}

// SUBTITLE

.product-overview__sublist {
    @include unlist;
    color: $ns-blue;
    display: flex;

    margin-bottom: toRem($spacing-2);
}

// SUBTITLE ITEM

.product-overview__sublist-item {
    padding-left: 0;
    @include fontSize('body-scaled');

    & + & {
        padding-left: toRem($spacing-2);
        border-left: 1px solid $ns-grey-6;
        margin-left: toRem($spacing-2);
    }

    @include respond-min($m) {
        padding-right: toRem($spacing-3);

        & + & {
            padding-left: toRem($spacing-3);
            margin-left: 0;
        }
    }
}

.product-overview__sublist-price {
    font-weight: 700;
    @include fontSmoothing;
    white-space: nowrap;
}

.product-overview__rating {
    margin-top: toRem(-$spacing-2);
    margin-bottom: toRem(2px);

    @include respond-min($sm) {
        margin-bottom: toRem($spacing-2);
    }

}

.product-overview__textlink {
    margin-bottom: toRem(14px);

    @include respond-min($l) {
        margin-bottom: 0;
    }
}
