// SHOWCASE
// ========

// SETTINGS
// --------

// VARS

$showcaseSpacing: $grid-gutter / 2;

// BASE
// ----

.showcase {
    width: 100%;

    margin-bottom: toRem($grid-gutter);

    // Qualified to target only :before pseudo when it's in a list-item. forgive me.
    // TODO: refactor list so :before styling is no longer applied on base level. After that, remove this.
    li:before {
        display: none;
    }

}

// PARTS
// -----

// ITEM

.showcase__item {
    margin: 0 toRem($showcaseSpacing) toRem($showcaseSpacing * 2);
}

// OPTIONS
// -------

// ONE-TWO

.showcase--one-two {

    @include respond-min($m) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .showcase__item {
            width: calc(50% - #{$showcaseSpacing * 2});

            margin: toRem($showcaseSpacing) toRem($showcaseSpacing) 0;

            &:first-child {
                width: 100%;
                margin-top: 0;
                margin-bottom: $showcaseSpacing;
            }

        }

    }

    @include respond-min($xxl) {
        flex-direction: column;
        align-items: center;
        height: toRem(548px);

        .showcase__item {
            width: calc((100% / 3) - #{$showcaseSpacing * 2});
            height: calc((100% / 2) - #{$showcaseSpacing});

            min-height: initial;

            margin: 0;

            &:first-child {
                width: calc(((100% / 3) * 2) - #{$showcaseSpacing * 2});
                height: 100%;

                margin-bottom: 0;
            }

        }

    }

}

// THREE IN LINE
// FOUR IN LINE

.showcase--three-in-line,
.showcase--four-in-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .showcase__item {
        width: 100%;

        margin: toRem($showcaseSpacing * 2) 0 0;

        &:first-child {
          margin-top: 0;
        }

    }

}

// THREE IN LINE

.showcase--three-in-line {

    @include respond-min($m) {

        .showcase__item {
            @include grid-column(1/2 2);

            &:nth-child(-n+2) {
                margin-top: 0 ;
            }

        }

    }

    @include respond-min($xxl) {

        .showcase__item {
            @include grid-column(1/3 3);

            &:nth-child(-n+3) {
  		        margin-top: 0;
            }

        }

    }

}

// FOUR IN LINE

.showcase--four-in-line {

    @include respond-min($m) {

        .showcase__item {
            @include grid-column(1/2 2);

            &:nth-child(-n+2) {
                margin-top: 0 ;
            }

        }

    }

    @include respond-min($l) {

        .showcase__item {
            @include grid-column(1/3 3);

            &:nth-child(-n+3) {
                margin-top: 0;
            }

        }

    }

    @include respond-min($xxl) {

        .showcase__item {
            @include grid-column(1/4 4);

            &:nth-child(-n+4) {
                margin-top: 0;
            }

        }

    }

}

// Showcase with variable width content

.showcase--mix {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + #{$grid-gutter});
    margin: 0 toRem(-$grid-gutter/2);
    .showcase__item {
        margin: 0 toRem($grid-gutter/2) toRem($grid-gutter);
    }
}

.showcase__item--1-2 {
    width: 100%;
    @include respond-min($m) {
        width: calc(100%/2 - #{$grid-gutter});
    }
}

.showcase__item--1-3 {
    width: 100%;
    @include respond-min($m) {
        width: calc(100%/2 - #{$grid-gutter});
    }
    @include respond-min($l) {
        width: calc(100%/3 - #{$grid-gutter});
    }
}

.showcase__item--2-3 {
    width: 100%;
    @include respond-min($m) {
        width: calc(100%/2 - #{$grid-gutter});
    }
    @include respond-min($l) {
        width: calc(100%/3*2 - #{$grid-gutter});
    }
}


// EXTENSIONS
// ----------

// CONTAINER

.showcase-container {
    width: 100%;

    .showcase {
        width: calc(100% + (#{toRem($showcaseSpacing)} * 2));
        margin-left: toRem(-$showcaseSpacing);
    }

}
