.button-group {
    display: flex;
}

.button-group__button {
    overflow: hidden;

    z-index: 1;

    & + & {
        margin-left: -1px;

        &[disabled] {
            z-index: 0;
        }
    }
}
