.tag-list {
    @include grid-row-container;
    @include list-reset;

    display: inline-flex;

    .tag-list__item {
        margin-right: toRem($grid-spacing);

        &:last-child {
            margin-right: 0;
        }
    }
}

.tag-list__item {
    padding: toRem(5px) 0;
    margin-right: toRem($grid-spacing);

    &:last-child {
        margin-right: 0;
    }
}

.tag-list__anchor {

    border-bottom: 1px solid currentColor;
    line-height: 1;

    .tag-list--inverted & {
        color: $ns-white;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}
