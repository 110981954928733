$pane-padding-default: $grid-gutter * 1.5;
$pane-padding-small: 20px;
$pane-padding-mobile: $grid-gutter;

.cart-configurator__item {
    position: relative;
    margin: 0;
    width: 100%;
    padding: 0;
    padding-bottom: toRem($pane-padding-mobile);
    border: 0;

    & + &, h3 + & {
        border-top: 1px solid $ns-grey-7;
        padding-top: toRem($pane-padding-mobile);
    }

    &:last-child {
        padding-bottom: 0;

        @include respond-min($l) {
            padding-bottom: toRem($pane-padding-mobile);
            border-bottom: 1px solid $ns-grey-7;
        }
    }

    @include respond-min($l) {
        border-top: 1px solid $ns-grey-7;
        padding-top: toRem($pane-padding-mobile);
    }
}

.cart-configurator__item-inner {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    min-height: toRem(45px);
}

.cart-configurator__item-content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;

    @include respond-min($sm) {
        margin-right: toRem($spacing-3);
    }

    @include respond-min($m) {
        margin-right: toRem($spacing-4);
    }

    @include respond-min($xl) {
        flex-direction: row;
        align-items: center;
    }
}

.cart-configurator__item-title {
    color: $ns-blue;

    @include respond-min($l) {
        width: 100%;
        margin-right: toRem($spacing-4);
    }
}

.cart-configurator__item-price {
    width: 100%;
    color: $ns-blue;
    white-space: nowrap;

    @include respond-min($l) {
        width: auto;
        flex-basis: auto;
    }
}

.cart-configurator__item-qty {
    flex: 0 0 toRem(45px);
    margin-right: toRem($spacing-3);

    @include respond-min($m) {
        margin-right: toRem($spacing-4);
    }
}

.cart-configurator__item-buttons {
    flex: 0 0 auto;
}

.cart-configurator__item-button {
    touch-action: manipulation;
}

.cart-configurator__item-unavailable {
    width: toRem(89px);

    color: $ns-red-alert;
    font-size: toRem(14px);
}

.cart-configurator__note {
    padding-top: toRem($pane-padding-mobile);
    border-top: 1px solid $ns-grey-7;
    @include fontSize('body-small');
    color: $ns-grey-4;
}
