/*

@name Expander
@description Component that equalizes heights between two DOM elements and applies a fade-out effect on the target.
@section components

@modifier .expander--280-220 - Modifier that sets min/maxHeight on mobile to 280 and 220 on desktop

@markup
<div class="expander {{modifier_class}}">
    <div class="expander__target"></div>
</div>

*/

.expander {

}

.expander__target {
    position: relative;
    overflow: hidden;
    transition: max-height $default-transition-speed $default-easing;

    .is-expandable & {
        &:after {
            content: '';
            position: absolute;
            height: toRem(100px);
            width: 100%;
            bottom: 0;
            background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        }
    }

    &.is-expanded {
        max-height: 3000px !important; // Use a large container

        &:after {
            display: none;
        }
    }
}

.expander__button {
    margin-top: toRem($grid-gutter);
}
