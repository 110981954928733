/*
@name Collapsible list
@description
An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.

@section Molecules
@type UI
@extended false

@markup
<ul class="list collapsibleList is-collapsible">
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <div class="collapsibleList__body">Body</section>
    </li>
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <section class="collapsibleList__body">Body</section>
    </li>
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <section class="collapsibleList__body">Body</section>
    </li>
</ul>
*/

.collapsibleList {
    width: 100%;
    margin-top: toRem(16px);
    margin-bottom: toRem(16px);
}

.collapsibleList__header {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: toRem(17px) toRem(35px) toRem(17px) 0;
    margin: 0;
    font-size: toRem($font-size-base);
    color: $ns-light-blue;

    &:hover {
        color: $ns-blue;
    }
}

.collapsibleList__item {
    border-bottom: toRem(1px) solid $ns-grey-7;
    list-style: none;
    margin: 0;
    padding: 0;

    &:first-child {
        border-top: toRem(1px) solid $ns-grey-7;
    }

    &:before {
        content: none;
    }
}

.collapsibleList__icon {
    display: none;
    position: absolute;
    right: 5px;
    top: calc(50% - 8px);
}

.collapsibleList__body {
    padding-bottom: toRem(17px);
}

.is-collapsible {
    .collapsibleList__body {
        display: none;

        &.is-expanded {
            display: block;
        }
    }

    .collapsibleList__icon {
        display: block;
    }
}
