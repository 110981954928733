// CONTACT
// =======

// BASE
// ----

.contact {}

// PARTS
// -----

// SECTION

.contact__section {
    @include grid-row-container;

    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;

    margin-bottom: toRem(28px);

    &:last-child {
        margin-bottom: 0;
    }

}

// TITLE

.contact__title {
    margin-bottom: toRem($grid-spacing-2);

    width: 100%;
}

// ICON

.contact__icon {
    margin-right: toRem(16px);

    flex-shrink: 0;
}

// SUBTITLE

.contact__subtitle {
    font-size: 1rem;

    margin: 0;
}

// DESCRIPTION

.contact__description {
    margin: 0;

    color: $ns-grey-4;

    @include fontSize('body-small');
}

.contact__button {

    &[disabled] {
        box-shadow: none;
        background: none;
        color: $ns-grey-4;

        &:focus,
        &:hover {
            color: $ns-grey-4;
            box-shadow: none
        }
    }
}

.contact__status {
    display: none;
    font-weight: normal;
    font-style: italic;

    @include fontSize('body-small');

    &::before {
        content: '\02013';
        margin-right: toRem(5px);
    }
}

// OPTIONS
// -------

// INLINE

.contact--inline {

    @include respond-min($m) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        width: 100%;
    }

    .contact__section {

        @include respond-min($m) {
            @include grid-column(1/2 2);
        }

        @include respond-min($l) {
            @include grid-column(1/3 3);

            margin-bottom: 0;
        }

    }

}
