.visuallyhidden {
    @include accHide;
}

// Helper directive for Vue.js
[v-cloak] {
    @include accHide;
}

@each $breakpoint in map_keys($breakpoints) {
    .h-visible-#{$breakpoint} {
        @include accHide;
        @include respond-min(#{map-get($breakpoints, $breakpoint)}) {
            @include accShow;
        }
    }
}
