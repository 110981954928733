.vcard {

}

.vcard__maplink {
    position: static;
}

.vcard__address:not(:last-child) {
    margin-bottom: $grid-spacing / 2;
}

.vcard__maplink {
    margin-bottom: $grid-spacing;
}
