//  Global animation settings
// ---------------------------------------------------------------------------

// Easing method
$default-easing: cubic-bezier(0.700, 0.270, 0.270, 1);

// Speed
$default-transition-speed: .3s;

$transition-speed: $default-transition-speed;
$transition-speed-slow: $default-transition-speed * 2;
$transition-speed-fast: $default-transition-speed / 2;
