.page-detail {

}

.page-detail__head {
    position: relative;
    @include grid-row-container;
    //margin-bottom: $grid-spacing-2;
    width: 100%;

    @include respond-min($m) {
        margin-bottom: $grid-spacing-4;
    }
    @include respond-min($l) {
        margin-bottom: $grid-spacing-5;
    }

    // no focus outline when this container is focused by scroll-down link.
    &:focus {
        outline: 0;
    }
}

.page-detail__content-box {
    margin-top: $grid-spacing-2;
    margin-bottom: $grid-spacing-2;

    @include respond-min($m) {
        order: 1;
        @include grid-column(1/2);
        margin-top: toRem($grid-spacing-3);
        margin-bottom: 0;
    }
    @include respond-min($l) {
        @include grid-column(2/3);
    }
    @include respond-min($xxl) {
        @include grid-column(7/12);
    }
}

.page-detail__content-box--extra-wide {
    @include respond-min($xxl) {
        @include grid-column(8/12);
    }
}

.page-detail__cta-box {
    order: 1;
    width: 100%;
    margin-bottom: $grid-spacing-2;
    align-self: flex-start;

    @include respond-min($m) {
        @include grid-column(1/2 2);
        order: 2;
        margin-top: -$book-it-header-height;
    }
    @include respond-min($l) {
        @include grid-column(1/3 2);
        order: 0;
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
    }
}

.page-detail__cta-box--inline {
    order: 2;
    align-self: initial;
    width: initial;
    margin-top: $grid-spacing-2;
    margin-bottom: $grid-spacing-2;

    @include respond-min($m) {
        @include grid-column(1/2 2);
        margin-top: toRem($grid-spacing-3);
        order: 2;
    }

    @include respond-min($l) {
        @include grid-column(1/3 2);
        margin-bottom: 0;
        order: 2;
        position: initial;
        right: initial;
        top: initial;
    }
}

.page-detail__intro {
    width: 100%;
    order: 2;

    @include respond-min($m) {
        order: 3;
        margin-bottom: 0;
    }
    @include respond-min($l) {
        @include grid-column(2/3);
    }
    @include respond-min($xxl) {
        @include grid-column(7/12);
    }
}

.page-detail__content-box + .page-detail__intro {

    @include respond-min($m) {
        margin-top: $grid-spacing-5;
    }

}

.page-detail__gallery {
    margin-bottom: $grid-spacing-2;

    @include respond-min($m) {
        margin-bottom: $grid-spacing-4;
    }

    @include respond-min($l) {
        margin-bottom: $grid-spacing-5;
    }

    // TODO: Refactor this
    // prevent double border when info directly follows gallery.
    // original styles set on .boxed-content, hence doubling up.
    // >> quick fix!
    @include respond-minMax($s, $m) {
        + .page-detail__info.boxed-content {
            border-top: 0;
            padding-top: $grid-spacing-2;
        }
    }

}

.page-detail__product-overview {
    width: 100%;

    // TODO: Refactor this
    // prevent double border when info directly follows gallery.
    // original styles set on .boxed-content, hence doubling up.
    // >> quick fix!
    @include respond-minMax($s, $l) {
        .touch & + .page-detail__info.boxed-content {
            border-top: 0;
            padding-top: 0;
        }
    }
}

.page-detail__content {

    @include container-content();

    &.page-detail__content {
        padding-top: 0;
    }
}

.page-detail__info {
    width: 100%;

    // TODO: Refactor this
    // Make .form__row and .definition-list stay longer in one column
    // when they are within .page-detail__info, as this container normally
    // takes up 50% of viewport.
    // >> quick fix!
    .form__row,
    .definition-list {
        display: block;

        @include respond-min($l) {
            display: flex;
        }

    }

}
