.arrow-link {
    display: inline-block;
    position: relative;

    font-size: toRem($base-font-size);

    > .arrow-link__icon {
        position: absolute;
        margin-top: toRem(8px);
        margin-left: toRem(3px);
        transition: transform $transition-speed $default-easing;

    }

    > .arrow-link__text {
        position: relative;
        display: inline-block;
        margin-left: toRem(25px);
    }

    &:hover > .arrow-link__icon,
    &:focus > .arrow-link__icon {
        transform: translateX(toRem(5px));

    }

    &.is-active {
        color: $ns-blue;
    }

}
