.site-header {
    position: relative;
    z-index: z('header');
    background: white;
    height: toRem($global--header-height);
    width: 100%;
    @include ns-box-shadow();
}

.site-header__container {
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.site-header__logo-container {
    display: flex;
    align-items: center;
    margin-left: toRem(-8px);
    margin-right: toRem(-5px);

    @include respond-min($sm) {
        margin-left: toRem(-22px);
    }
}

.site-header__logo-link {
    margin-top: toRem(-8px);
    line-height: 0;
    white-space: nowrap;
    display: block;
    padding: toRem(8px);

    @include respond-min($sm) {
        padding: toRem(6px) toRem(22px);
    }
}

.site-header__logo-ns {
    display: inline-block;
    width: toRem(51px);
    margin-top: toRem(5px);
}

.site-header__logo-spoordeelwinkel {
    width: toRem(135px);
}

.site-header__verticale-separator {
    width: 1px;
    height: toRem(24px);
    margin-top: toRem(10px);
    background-color: $ns-grey-6;
}

.site-header__back-link {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.site-header__back-link-icon {
    width: toRem(18px);
    margin-right: toRem(5px);
    fill: currentColor;
}


.site-header__back-link-text {

}

.site-header__back-link-extended {
    display: none;

    @include respond-min($m) {
        display: inline;
    }
}
