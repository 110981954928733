// STICKER
// =======

// BASE
// ----

// STICKER

.sticker {
    display: inline-block;

    width: auto;

    padding: 2px 8px;

    margin-bottom: 0;

    background-color: $ns-yellow;

    color: $ns-blue;
    font-weight: 700;
    font-size: toRem(14px);
    @include fontSmoothing;

    &:empty {
        display: none;
    }

}

// OPTIONS
// -------

// NOWRAP

.sticker--nowrap {
    overflow: hidden;

    max-width: 100%;

    white-space: nowrap;
}

// ABSOLUTE

.sticker--absolute {
    position: absolute;
}

// RESP FONT SIZE

.sticker--resp-font-size {
    font-size: toRem(14px);

    @include respond-min($m) {
        font-size: toRem(18px);
    }

}

// SIZES
// -----

// L

.sticker--l {
    padding: 4px 10px;
}

// VARIATIONS
// ----------

// SECONDARY

.sticker--secondary {
    background-color: $white;
}

// ALERT

.sticker--alert {
    background-color: $ns-red-alert;
    color: $ns-white;
}
