// CAPTION
// =======

// BASE
// ----

.caption {
    width: 100%;
    color: $ns-blue;
    font-size: toRem(14px);
    border-bottom: 1px solid $ns-grey-7;
    padding: $spacing-3 0;
}

// PARTS
// -----

// ICON

.caption__icon {
    margin-right: toRem($grid-gutter / 4);
}
