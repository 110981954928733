$calendar-table-border: 1px solid $ns-grey-7;
$calendar-table-cell-height-small: 49px;
$calendar-table-cell-height-large: 57px;

.calendar {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
}

.calendar--configurator {
    width: calc(100% + #{$grid-gutter*2} + 2px);
    margin-left: -#{$grid-gutter + 1px};
    margin-top: calc(-#{$grid-gutter + 1px});
    margin-bottom: $grid-spacing-2;

    @include respond-min($m) {
        width: 100%;
        margin: 0 0 $grid-spacing-3;
    }

    @include respond-min($xl) {
        @include grid-column(8/8);
    }
}

.calendar__header-row {
    border: $calendar-table-border;
}

.calendar__body-row:first-child {
    border: $calendar-table-border;
}

.calendar__title {
    height: toRem($calendar-table-cell-height-small);
    color: $ns-grey;
    font-weight: normal;
    vertical-align: middle;

    @include respond-min($m) {
        height: toRem($calendar-table-cell-height-large);
    }
}

.calendar__control {
    height: toRem($calendar-table-cell-height-small);
    color: $ns-light-blue;
    vertical-align: middle;

    &[aria-disabled] {
        @include accHide();
    }

    @include respond-min($m) {
        height: toRem($calendar-table-cell-height-large);
    }
}

.calendar__control--prev,
.calendar__control--next {
    cursor: pointer;
    user-select: none;
}

.calendar__header-cell {
    color: $ns-grey;
    font-weight: normal;
    vertical-align: middle;
    height: toRem(28px);
    @include respond-min($m) {
        height: toRem(36px);
    }
}

.calendar__cell {

    vertical-align: middle;
    user-select: none;
    height: $calendar-table-cell-height-small;

    @include respond-min($m) {
        height: $calendar-table-cell-height-large;
    }

    &:not(.is-empty) {
        border: $calendar-table-border;
        text-align: center;
        color: $ns-light-blue;
        cursor: pointer;
    }

    &:focus,
    &:hover:not(.is-empty) {
        border-color: $ns-light-blue;
        border-style: double;
    }
    &.is-today {
        position: relative;
        color: $ns-light-blue;
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: toRem(4px);
            height: toRem(4px);
            background-color: $ns-light-blue;
            top: toRem(36px);
            @include respond-min($m) {
                top: toRem(40px);
            }
            left: calc(50% - #{toRem(2px)});
            border-radius: 50%;
        }
    }
    &.is-selected {
        background-color: $ns-light-blue;
        color: $ns-white;
        &:after {
            background-color: $ns-white;
        }
    }
    &.is-disabled {
        pointer-events: none;
        color: $ns-grey-5;
        &:after {
            background-color: $ns-grey-5;
        }
    }
    &:not(.is-disabled) {
        font-weight: 700;
        @include fontSmoothing;
    }
}

.calendar__wrapper {
    position: relative;
}
