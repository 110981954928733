.ad {
    padding: $grid-spacing;
    background-color: $ns-grey-8;
}

.ad__heading {
    color: $ns-blue;
    margin-bottom: $spacing;
}
.ad__body {
    @include fontSize('body-small');
    margin-bottom: $spacing-2;
}
