// BANNER
// ======

// SETTINGS
// --------

// VARS

$bannerVisualHeight-s: 30vw;
$bannerVisualHeight-sm: 40vw;
$bannerVisualHeight-m: 50vw;
$bannerVisualHeight-l: 240px;

// BASE
// ----

.banner {
    display: flex;
    flex-direction: column;

    position: relative;

    width: 100%;

    overflow: hidden;

    padding: $spacing-4;

    @include respond-min($m) {
        flex-direction: row;
        flex-grow: 1;

        padding: 0;
    }

    > * {
        position: relative;
        z-index: 1;
    }

}

// PARTS
// -----

// VISUAL

.banner__visual {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    margin-bottom: $grid-spacing;

    @include respond-min($m) {
        margin-bottom: 0;
    }

}

// IMAGE

// DEFINED in BANNER--B AND BANNER--C
.banner__image {}

// BACKGROUND

.banner__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 0;
}

// BACKGROUND IMAGE

.banner__background-image {
    position: absolute;
    bottom: 0;
    right: $grid-spacing-3;
    display: none;
    width: 60%;

    @include respond-min($m) {
        display: block;
    }

    @include respond-min($l) {
        bottom: -$grid-spacing-3;
    }
}

// BACKGROUND IMAGE LIGHT

.banner__background-image--light {
    opacity: 0.3;
}

// BACKGROUND IMAGE DARK

.banner__background-image--dark {
    opacity: 0.1;
}

// HEADING

// DEFINED in BANNER--A
.banner__heading {}

// TEXT

.banner__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: $grid-spacing;

    &:last-child,
    &:empty {
        margin-bottom: 0;
    }

    @include respond-min($m) {
        margin-bottom: 0;
    }

}

// TEXT BODY

.banner__text-body {
    color: $ns-blue;

    &:empty {
        display: none;
    }

}

// BUTTON

.banner__button {
    white-space: nowrap;
}

// OPTIONS
// -------

// TWO COLUMN

.banner--two-column {

    .banner__visual,
    .banner__text {

        @include respond-min($m) {
            flex-basis: 50%;
        }

    }

}

// THREE COLUMN

.banner--three-column {

    .banner__visual,
    .banner__text {

        @include respond-min($m) {
            flex-basis: calc(100% / 3);
        }

    }

}

// DARK BG

.banner--dark-bg {

    .banner__heading,
    .banner__text-body {
        color: $white;
    }

}

// VARIATIONS
// ----------

// A

.banner--a {
    border-bottom: 1px solid $ns-grey-6;

    @include respond-min($m) {
        border-top: 1px solid $ns-grey-6;
    }

    .banner__visual {
        display: block;

        order: 2;

        max-height: $bannerVisualHeight-s;

        @include respond-min($sm) {
            max-height: $bannerVisualHeight-sm;
        }

        @include respond-min($m) {
            order: 0;

            max-height: $bannerVisualHeight-m;

            padding: $spacing-6 3vw 0;
        }

         @include respond-min($l) {
            max-height: $bannerVisualHeight-l;

            padding: $spacing-6 8% 0;
        }

    }

    .banner__text {
        order: 1;

        padding: 0 0 $spacing-4;

        @include respond-min($m) {
            order: 0;

            padding: $spacing-8 $spacing-4 $spacing-8 $grid-spacing;
        }

    }

    .banner__text-body {
        @include respond-min($l) {
            font-size: toRem(18px);

            padding-right: $spacing-6;
        }

        @include respond-min($xl) {
            padding-right: $spacing-9;
        }
    }

    .banner__heading {
        margin-bottom: $grid-spacing;
    }

}

// B C

.banner--b,
.banner--c {

    @include respond-min($m) {
        align-items: stretch;
    }

    .banner__visual {

        @include respond-min($m) {
            padding: $spacing-4 $grid-spacing $spacing-4 $spacing-4;
        }

    }

    .banner__text {

        @include respond-min($m) {
            padding: $spacing-4 $grid-spacing;

            &:last-child {
                padding-right: $spacing-4;

                text-align: right;
            }

            .banner__button {
                text-align: left;
            }

        }

    }

    .banner__text-body {
        @include fontSize('body-small');

        margin-bottom: $spacing-2;
    }

    .banner__button {
        width: 100%;

        @include respond-min($m) {
            width: auto;
        }

    }

}

// B

.banner--b {

    @include respond-min($m) {
        align-items: center;
    }

    .banner__visual {
        display: block;
    }

}

// C

.banner--c {
    padding-top: 0;

    .banner__visual {
        height: 123px;

        margin-right: -$spacing-4;
        margin-left: -$spacing-4;

        overflow: hidden;

        @include respond-min($m) {
            height: auto;

            margin-right: 0;
            margin-left: 0;
        }

    }

    .banner__image {
        @include respond-min($m) {
            position: absolute;
            top: 50%;
            left: 0;

            transform: translateY(-50%);
        }
    }

}
