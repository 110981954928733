/*
@name map
@description Google maps and google maps info window styling.

@section Molecules
@category google map
@type UI
@extended false

@modifiers

@markup

<div ref="map-container" class="map"></div>

*/

/* info-window */
$info-window-width: 238px;

/* info-window-triangle */
$info-window-triangle-height: 14px;
$info-window-triangle-width: 8px;
$info-window-triangle-position-vertical: -13px;

.map {
    width: 100%;
    height: toRem(240px);

    @include respond-min($m) {
        height: toRem(480px);
    }

    @include respond-min($l) {
        height: toRem(524px);
    }
}

.map__info-window {
    font-family: $font-normal;
    background-color: $white;
    padding: $spacing-4;
    @include fontSize('body-small');
    width: toRem($info-window-width);
    overflow: visible !important;
    @include ns-box-shadow();
    animation: fadeIn $transition-speed $default-easing;

    &:before,
    &:after {
        border: 0 solid transparent;
        border-left-width: $info-window-triangle-width;
        border-right-width: $info-window-triangle-width;
        margin-left: -($info-window-triangle-width/2);
        left: 50%;
        content: '';
        display: inline-block;
        position: absolute;

        border-top-width: $info-window-triangle-height;
        bottom: $info-window-triangle-position-vertical;
        border-top-color: $white;
    }

    &:before {
        border-top-width: $info-window-triangle-height + 1;
        bottom: $info-window-triangle-position-vertical - 4;
        border-top-color: transparentize(ns-grey(1), (0.85));
    }

    /* this is the close image of the info-window.
       it comes from the infobox utility.
       it dont have a class name and it is difficult to set a class name on it on the right way.
       that is why we use here an img element.
    */
    img {
        width: toRem(16px);
    }
}

.map__info-window-title {
    color: $ns-blue;
    font-weight: 700;
    margin-bottom: ($grid-spacing/2);
}

.map__info-window-content {
    margin-bottom: 0;
}
