/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<input class="form-text" type="text" value="input type text" /><br><br>
<input class="form-text" type="email" value="input type email" /><br><br>
<input class="form-text" type="search" value="input type search" /><br><br>
<input class="form-text" type="password" value="input type password" /><br><br>
<input class="form-text" type="text" readonly="readonly" value="input readonly" /><br><br>

<div class="textInput">text in a dummy textInput</div><br>

*/

$input-height:             45px;
$input-line-height:        25px;
$input-vertical-padding:   ($input-height - $input-line-height) / 2;
$input-horizontal-space:   15px;
$focus-color:              $ns-blue;


// Lineheight is bepaald op 25px. Om de tekst optisch in het midden van een
// textinput van 45px hoog te zetten, is het nodig om hem net onder het absolute
// midden te zetten. Daarom de padding-top + 1px, en de padding-bottom - 1px.
@mixin inputLineHeightFix {
    padding-top: toRem($input-vertical-padding + 1px);
    padding-bottom: toRem($input-vertical-padding - 1px);
}

@mixin input {
    @include inputLineHeightFix();

    padding-left: toRem($input-horizontal-space);
    padding-right: toRem($input-horizontal-space);
    background-color: $white;
    color: ns-grey(1);
    height: toRem($input-height);
    line-height: toRem($input-line-height);
    border: toRem(1px) solid $ns-grey-6;
    border-radius: 0;

    &:focus {
        outline: 0;
    }
}

@mixin input--readonly {
    color: ns-grey(5);
    background-color: $off-white;
}

.form-text {
    @include input;

    // Remove webkit number spinners
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // Firefox
    -moz-appearance:textfield;
}

.form-text--fullWidth {
    width: 100%;
}

.form-text--largeDigit {
    &[readonly="readonly"] {
        background: transparent;
        padding: 0;
        border: 0;
        color: $ns-blue;
        height: auto;
        font-size: toRem(32px);
        line-height: 1;
        width: 100%;
        text-align: right;

        @include respond-min($m) {
            font-size: toRem(36px);
        }
    }
}


// style placeholder color. somehow below selectors cannot be combined.
$placeholder-color: $ns-grey-4;

::placeholder {
    color: $placeholder-color;
}

// remove all webkit search decoration
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[readonly="readonly"] {
    @include input--readonly;
}
