/*
@name Table
@description
Class to create a table markup

@section Atoms
@type UI
@extended false
@category table

@modifier .table--reset                    - Borders are collapsed into a single border when possible

@markup
table__header-col
<table class="table">
    <tr class="table_row">
        <th class="table__header-col"></th>
        <th class="table__header-col"></th>
    </tr class="table_row">
        <td class="table__data"></td>
        <td class="table__data"></td>
    </tr>
<table>

table__header-row:
<table class="table">
    <tr class="table_row">
        <th class="table__header-row" scope="row"></th>
        <td class="table__data"></td>
    </tr>
<table>
*/

.table {
}

.table--reset {
    border-collapse: collapse;
}

.table__header-row {
    font-weight: normal;
    text-align: left;
}
