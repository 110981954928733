$sticky-book-it-height: 65px;

// BASE
// ----

.sticky-book-it {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: toRem($sticky-book-it-height);
    z-index: z('sticky');
    transition: transform $default-transition-speed $default-easing;

    background-color: $ns-grey-8;
    border-top: solid 1px $border-color;
    border-bottom: solid 1px $border-color;

    @include respond-min($m) {
        position: sticky;
        transform: translateY(toRem(65px));

        &.is-sticky {
            transform: translateY(0);
        }
    }

    &.l-hidden {
        @include respond-min($l) {
            display: none;
        }
    }
}

// PARTS
// -----

// CONTAINER

.sticky-book-it__container {
    align-items: center;
    flex-flow: row nowrap;

    height: 100%;

    padding-right: $grid-gutter / 2;
    padding-left: $grid-gutter / 2;

    @include respond-min($sm) {
        padding-right: $grid-gutter;
        padding-left: $grid-gutter;
    }

    @include respond-min($m) {
        justify-content: flex-end;
    }
}

// HEADER

.sticky-book-it__header {
    @include respond-min($m) {
        min-width: toRem(120px);
        margin-right: $spacing-4;
    }
}

// PRICE DESCRIPTION

.sticky-book-it__price-description {
    margin-top: 4px;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .6);
    font-size: toRem(14px);
    line-height: 1;
}

// PRICE

.sticky-book-it__price {
    font-size: toRem(32px);
    line-height: 1;
}

// PRICE CENTS

.sticky-book-it__price-cents {
    font-size: toRem(16px);
    top: toRem(-10px);
}

// DESCRIPTION

.sticky-book-it__description {
    margin-bottom: 0;
}

.sticky-book-it__description--secondary {
    color: $ns-blue;
}

.sticky-book-it__description--responsive {
    display: none;

    @include respond-min($sm) {
        display: inline;
    }

}

.sticky-book-it__description-sub {
    display: none;

    @include respond-min($l) {
        display: inline;
    }
}

// OFFER

.sticky-book-it__offer {
    font-weight: 700;
    @include fontSmoothing;
    color: $ns-blue;
}

// BUTTON MEDIUM/SMALL

.sticky-book-it__button--full {
    width: 100%;

    @include respond-min($sm) {
        width: auto;
    }
}

// STATUS TITLE

.sticky-book-it__status-title {
    color: $ns-red-alert;
    margin: 0;
    font-weight: normal;
    width: auto;
}


.sticky-book-it__button--medium {
    display: none;
}
@include respond-min($sm) {
    .sticky-book-it__button--small {
        display: none;
    }
    .sticky-book-it__button--medium {
        display: flex;
    }
}

// Add padding to body so sticky footer doesn't overlap privacy bar
body.has-sticky-footer {
    padding-bottom: $sticky-book-it-height;
}

@supports (position: sticky) {

    @include respond-min($m) {

        body.has-sticky-footer {
            padding-bottom: 0;
        }

    }

}
