/*
@name Accessibility helper
@description Helpers for accessibility to hide/show DOM elements.
@section helpers
*/

$acc-breakpoints: (
    'sm': $sm,
    'm': $m,
    'ml': $ml,
    'l': $l,
    'xl': $xl
);

@mixin render-acc-helpers() {
    .acc-show, // deprecated
    .h-acc-show {
        @include accShow();
    }

    .acc-show-relative, // deprecated
    .h-acc-show-relative {
        @include accShow(relative);
    }

    .acc-show-absolute, // deprecated
    .h-acc-show-absolute {
        @include accShow(absolute);
    }

    .acc-show-fixed, // deprecated
    .h-acc-show-fixed {
        @include accShow(fixed);
    }

    .acc-hide, // deprecated
    .h-acc-hide {
        @include accHide();
    }

    @each $breakpoint-name, $breakpoint in $acc-breakpoints {
        @include respond-min(#{$breakpoint}) {
            .h-acc-show-#{$breakpoint-name} {
                @include accShow();
            }
            .h-acc-show-relative-#{$breakpoint-name} {
                @include accShow(relative);
            }
            .h-acc-show-absolute-#{$breakpoint-name} {
                @include accShow(absolute);
            }
            .h-acc-show-fixed-#{$breakpoint-name} {
                @include accShow(fixed);
            }
            .h-acc-hide-#{$breakpoint-name} {
                @include accHide();
            }
        }
    }
}

@include render-acc-helpers();


// NO OUTLINE
// NB: Only use for elements that cannot be interacted with,
// and focus has only been set for screenreaders.
.h-acc-no-outline {

    &:focus {
        outline: 0 !important;
    }

}
