html, body {
    font-family: $font-normal;
}

html {
    font-size: 100%;
}

body {
    background-color: $body-background-color;
    color: $body-color;
    line-height: $line-height-base / $font-size-base; // Relative to base font-size
    min-width: toRem($min-site-width);

    @media print {
        background-color: $white;
        padding-top: 0;
        max-width: toRem(960px);
        margin: 0 auto;
    }
}
