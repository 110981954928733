/**
 * Fade in animation
 */
@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

/**
 * Fade in Downs animation
 */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
