/* ===== TRUMPS
 * This layer trumps all other layers, and has the power to override anything at all
 * that has gone before it. May contain utility and helper classes, hacks and overrides.
 * A lot of the declarations in this layer will carry !important. This is the highest
 * specificity layer – it includes the most explicit types of rule, with the most narrow focus.
 */

html {
    &.wf-active {
        body {
            font-family: "NS Sans",
                "Helvetica Neue",
                Helvetica,
                Arial,
                sans-serif;
        }
    }
}
