.collapse {
    &:not([open]) {
        .collapse__icon--expand {
            display: block;
        }
    }

    .no-details &:not([open]) {
        .collapse__icon--expand {
            display: none;
        }
    }

    &[open] {
        .collapse__icon--collapse {
            display:block;
        }
    }
}

.collapse__icon {
    display: none;
    position: absolute;
    right: 5px;
    top: calc(50% - 8px);
}

.collapse__header {
    display: block;
    position: relative;
    cursor: pointer;
    padding: toRem(17px) toRem(35px) toRem(17px) 0;
    color: $ns-light-blue;

    &::-webkit-details-marker {
        display: none;
    }

    &:hover {
        color: $ns-blue;
    }

    .no-details & {
        cursor: auto;

        &:hover {
            color: $ns-light-blue;
        }
    }
}

.collapse__body {
    padding-bottom: toRem(17px);
}
