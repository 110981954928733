.rich-content {

    a:not([class]), a[class=""] {
        box-shadow: 0 1px 0 $link-color;

        &:hover {
            box-shadow: 0 1px 0 $link-hover-color;
        }
    }

    > p {
        margin-bottom: $spacing-3; // Relative to font-size

        color: $ns-grey-3;

        & + &,
        &:last-child {
            margin-bottom: 0;
        }
    }

    > ol, > ul {
        &:last-child {
            margin-bottom: 0;
        }
    }

    > h1,
    > h2,
    > h3 {
        margin-bottom: toRem($spacing-2);
    }

    > h1 {
        @include fontSize('xxl');
        font-weight: 700;
        @include fontSmoothing;
    }

    > h2 {
        @include fontSize('l');
    }

    > h3 {
        @include fontSize('s');
        font-weight: 700;
        @include fontSmoothing;
    }

    > h4,
    > h5,
    > h6 {
        @include fontSize('xs');
        font-weight: 700;
        margin-bottom: toRem($spacing-2);
        @include fontSmoothing;
    }
}

.rich-content--large {
    @include respond-min($m) {
        font-size: toRem(18px);
        line-height: (28 / 18);
    }
}

.rich-content--thema {
      width: 100%;

      @include respond-min($l) {
          @include grid-column(8/12);
      }
}
