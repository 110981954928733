
// HIDE S -> SM

.h-hide-s-sm {

  @include respond-minMax($s, $sm) {
    @include accHide;
  }

}

// HIDE S -> M

.h-hide-s-m {

  @include respond-minMax($s, $m) {
    @include accHide;
  }

}
