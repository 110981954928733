// TILE
// ====

// SETTINGS
// --------

// VARS

$priceBoxSize: 84px;

// BASE
// ----

.tile {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    min-height: toRem(240px);

    overflow: hidden;

    padding: 0;
    padding-top: toRem(45px);

    border-bottom: 4px solid $ns-yellow;

    box-shadow: 0 2px 20px rgba(0,0,0,0);
}

// PARTS
// -----

// PICTURE

.tile__picture {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: calc(100% - #{$priceBoxSize});
    overflow: hidden;

    display: block;

    transform: scale(1);

    &:after {
        @include coverAll;

        display: block;

        background-image: linear-gradient(to bottom, transparent 0%, transparent 10%, rgba(0,0,0,0.55) 60%, rgba(0,0,0,0.85) 100%);

        content: "";

        opacity: 0.45;
    }

}

// IMAGE

.tile__image {
    @include objectFit;
}

// TITLE

.tile__title {
    position: relative;

    display: block;

    padding: 0 $spacing-4;

    margin-bottom: $spacing-4;

    color: $white;
    font-weight: 700;
    line-height: 1.2;
    font-size: toRem(24px);

    text-shadow: rgb(0, 0, 0) 1px 1px 1px;
    @include fontSmoothing;
}

// TEXT

.tile__text {
    position: relative;

    display: block;

    min-height: toRem($priceBoxSize);

    padding: $spacing-3 toRem(104px) $spacing-4 $spacing-4;

    margin-bottom: 0;

    background-color: $white;

    color: $ns-blue;
}

// SUPER-TEXT
.tile__super-text {
    display: block;
    position: relative;
    font-size: toRem(16px);
    color: $white;
    font-weight: 700;
    @include fontSmoothing;
    padding: $spacing-3 toRem(104px) 0 $spacing-4;
    text-shadow: $black 1px 1px 1px;

    @include respond-min($m) {
        font-size: toRem(18px);
    }
}

// SUB-TEXT

.tile__sub-text {
    display: block;

    font-size: toRem(12px);

    color: $ns-grey-4;
}

// PRICE, OFFER

.tile__price,
.tile__offer {
    position: absolute;

    top: -$spacing-2;
    right: $spacing-2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: toRem($priceBoxSize);
    height: toRem($priceBoxSize);

    padding: $spacing-2;

    background-color: $ns-yellow;

    color: $ns-blue;
}

// OFFER

.tile__offer {
    justify-content: space-between;

    padding: 0 0 toRem($spacing-2);
}

// OFFER DESCRIPTION

.tile__offer-description {
    display: inline-block;

    padding: toRem(3px) toRem($spacing-2);

    background-color: $white;

    font-size: toRem(12px);
    text-transform: uppercase;
}

// OFFER DISCOUNT

.tile__offer-discount {
    display: inline-block;

    padding: 0 toRem($spacing-2);

    font-weight: 700;
    line-height: 1.2;
    font-size: toRem(18px);
    @include fontSmoothing;
}

// PRICE TEXT

.tile__price-text {
    font-size: toRem(12px);
}

// PRICE AMOUNT

.tile__price-amount {
    font-weight: 700;
    font-size: toRem(32px);
    line-height: .9;
    @include fontSmoothing;
}

.tile__price--small .tile__price-amount {
    font-size: toRem(18px);
}

.tile__price--tiny .tile__price-amount {
    font-size: toRem(16px);
}

// STICKER

.tile__sticker {
    position: absolute;
    top: $spacing-2;
    left: $spacing-2;

    overflow: hidden;

    &:empty {
        display: none;
    }

    max-width: calc(100% - 20px);

    white-space: nowrap;
}

// LINK

.tile__link {
    @include coverAll;

    outline-color: $white;
    outline-offset: -3px;

    // IE11
    @media screen and (min-width:0\0) {
        @include coverAll(2px,2px,2px,2px);

        &:focus {
            outline: 2px dotted $white;
        }

    }

    // EDGE
    @supports (-ms-ime-align:auto) {
        @include coverAll(2px,2px,2px,2px);

        &:focus {
            outline: 2px dotted $white;
        }

    }

}

// - TEXT

.tile__link__text {
    @include accHide;
}

// OPTIONS
// -------

// ANIMATED

.tile--animated {
    transition: box-shadow $transition-speed $default-easing;

    .tile__picture {
        transition: transform $transition-speed $default-easing;

        &:after {
            transition: opacity $transition-speed $default-easing;
        }
    }

    .tile__image {
        transition: transform $transition-speed $default-easing;
    }

    &:hover {
        box-shadow: 0 2px 20px rgba(0,0,0,0.25);

        .tile__picture {
            transform: scale(1.05);

            &:after {
                opacity: 0.7;
            }

        }

    }

    // selector separated as combination causes Chrome 59.x to ignore both!
    // todo: re-combine when support improves.
    &:focus-within {
        box-shadow: 0 2px 20px rgba(0,0,0,0.25);

        .tile__picture {
            transform: scale(1.05);

            &:after {
                opacity: 0.7;
            }
        }

    }

}

// HIGHLIGHTED

.tile--highlighted {

    @include respond-min($l) {
        min-height: 360px;
    }

    // specificity fix (https://csswizardry.com/2014/07/hacks-for-dealing-with-specificity/)
    // fixes source order overwrite by .tile--featured .tile__title
    .tile__title.tile__title {

        @include respond-min($xxl) {
            font-size: toRem(48px);
            line-height: 1.145;
        }

    }

}

// VARIATIONS
// ----------

// FEATURED

.tile--featured {

    .tile__picture {
        height: 100%;
    }

    .tile__title {
        padding-right: toRem($priceBoxSize + 20);

        margin-bottom: 7px;

        font-size: toRem(24px);
        @include respond-min($l) {
            font-size: toRem(32px);
        }

    }

    .tile__text {
        min-height: auto;

        padding: 0 toRem($priceBoxSize + 20) $spacing-4 $spacing-4;

        background-color: transparent;

        color: $white;
    }

    .tile__sub-text {
        display: block;

        font-size: toRem(12px);
        color: $white;
    }

    .tile__price,
    .tile__offer {
        top: auto;
        bottom: $spacing-2;
    }

}
