/*

@name notification-bar
@description notification-bar; bar to display a message above or below the header menu
@section Organisms
@type UI
@extended false

@markup
<aside class="notification-bar notification-bar--alert">
    <div class="container notification-bar__container">
        <div class="notification-bar__icon-wrapper">
            <svg class="icon icon--nsWhite icon--sm notification-bar__icon" viewBox="0 0 16 16" aria-hidden="true">
                <use xlink:href="#alert"></use>
            </svg>
        </div>
        <div class="notification-bar__content">
            <p class="notification-bar__description">
                Landelijke storing OV-chipkaart, in- en uitchecken is tijdelijk niet mogelijk
            </p>
            <span class="notification-bar__links">
                <a href="javascript:void(0)" class="notification-bar__link">Geef feedback</a>
                <a href="javascript:void(0)" class="notification-bar__link">Geef feedback</a>
            </span>
        </div>
    </div>
</aside>
*/


// BASE
// ----

.notification-bar {
    background-color: $ns-blue;
    color: $white;
}

// PARTS
// -----

// CONTAINER

.notification-bar__container {
    min-height: toRem(66px);
    padding-top: toRem($spacing-4);
    padding-bottom: toRem($spacing-4);
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

// ICON WRAPPER

.notification-bar__icon-wrapper {
    flex: 0 0 auto;
}

// ICON

.notification-bar__icon {
    margin-right: toRem($grid-spacing);
}

// CONTENT

.notification-bar__content {
    flex: 1 1 auto;
}

// DESCRIPTION

.notification-bar__description {
    @include fontSize('body-scaled');
    margin-right: toRem($grid-spacing);
    margin-bottom: $spacing;

    @include respond-min($m) {
        display: inline-block;
        margin-bottom: 0;
        width: auto;
    }
}

// LINKS

.notification-bar__links {
    display: block;

    @include respond-min($m) {
        display: inline-block;
    }
}

// LINK

.notification-bar__link {
    display: block;

    margin-right: toRem($grid-spacing);

    & + & {
        margin-top: $spacing;
    }

    @include fontSize('body-scaled');
    text-decoration: underline;
    font-weight: 700;
    color: $white;
    @include fontSmoothing;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    @include respond-min($m) {
        display: inline;

        & + & {
            margin-top: 0;
        }
    }

}

// MODIFIERS
// ---------

// ALERT

.notification-bar--alert {
    background-color: $ns-red-alert;
}

// REFERENCE

.notification-bar--reference {
    background-color: $ns-yellow;
    color: $ns-blue;

    @include respond-min($m) {
        animation: fadeInDown 0.9s $default-easing;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .notification-bar__container {
        padding-top: toRem($spacing-2);
        padding-bottom: toRem($spacing-2);
        @include respond-min($m) {
            align-items: center;
        }
    }

    .notification-bar__icon-wrapper {
        display: none;

        @include respond-min($m) {
            display: block;
        }
    }

    .notification-bar__description {
        @include fontSize('xs');

        // element selector: because the link is inside a rich content
        > a:not([class]), a[class=""] {
            display: inline;
            color: $ns-blue;
            margin-right: 0;
            white-space: nowrap;
            text-decoration: none;
            box-shadow: 0 1px 0 $ns-blue;
            font-weight: 700;
        }
    }
}
