$galleryButtonSize: 40px;

.gallery {
    position: relative;
    width: 100%;
}

.gallery__inner {
    position: relative;
}

.gallery__carousel {
    position: relative;
    background: $ns-grey-8;
    overflow: hidden;
    margin-left: toRem(-$grid-gutter);
    margin-right: toRem(-$grid-gutter);
    transition: max-height $default-transition-speed $default-easing;
    max-height: 0;

    .gallery--thema & {
        margin-left: initial;
        margin-right: initial;
    }

    .touch & {
        overflow-y: auto;
        @include respond-min($xl) {
           overflow-y: hidden;
        }
    }

    &.has-nav:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(0,0,0,0) 70%, rgba(0,0,0,0.4) 100%);
        z-index: 1;
        pointer-events: none;
    }

    &.is-ready {
        max-height: toRem(600px);
    }

    &.is-draggable {
        cursor: move;
        cursor: -moz-grab !important;
        cursor: -webkit-grab !important;
    }

    &.is-dragging {
        cursor: move;
        cursor: -moz-grabbing !important;
        cursor: -webkit-grabbing !important;
    }

    @include respond-min($m) {
        margin-left: 0;
        margin-right: 0;
    }
}

.gallery__list {
    @include unlist;
    white-space: nowrap;
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
}

.gallery__item {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 0;
}


.gallery__picture {
    display: block;
    margin: 0;

    .gallery--thema & {
        height: 100%;
        max-height: toRem(150px);
        overflow: hidden;

        @include respond-min($m) {
            max-height: toRem(300px);
        }
    }
}

.gallery__image {
    display: block;

    .gallery--thema & {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%
    }
}


// Nav
.gallery__nav {
    position: absolute;
    bottom: toRem($spacing-3);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    z-index: 2;
}

.gallery__nav-list {
    @include unlist;
    display: flex;
    flex-flow: row nowrap;
}

.gallery__nav-item {
    padding-left: 0;
    flex: 0 0 auto;
    margin-right: toRem($spacing-2);

    &:only-child,
    &:last-child {
        margin-right: 0;
    }
}

.gallery__nav-link {
    display: block;
    width: toRem(10px);
    height: toRem(10px);
    border: 1px solid $white;
    border-radius: 50%;
    @include ns-box-shadow();
    @include bounding-box($spacing-2, $spacing+1, $spacing-2, $spacing+1);

    .no-touch &:hover,
    .is-active & {
        background-color: $white;
    }
}

// Caption (placeholder)
.gallery__caption {

    @include respond-min($xxl) {
        display: none;
    }

    .no-touch & {
        display: none;
    }

}

// Button
.gallery__button {
    display: none;

    position: absolute;
    top: 50%;
    z-index: 1; // temp. in stacking index!

    width: toRem($galleryButtonSize);
    height: toRem($galleryButtonSize);

    overflow: hidden;

    background-color: #fff;

    border: 0;
    border-radius: 50%;

    transform: translateY(-50%);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4);
    cursor: pointer;

    color: $ns-blue;
    text-align: center;

    .no-touch & {
        display: block;
    }

    &.is-hidden {
        // Important needed to override modernizr specificity level
        display: none !important;
    }

    @include respond-min($xxl) {

        .touch & {
            display: block;
        }

    }

}

.gallery__button--next {
    right: toRem(-$galleryButtonSize / 2);
}

.gallery__button--prev {
    left: toRem(-$galleryButtonSize / 2);

    .gallery__button-icon {
        margin-left: -2px;
        transform: rotate(180deg);
    }

}
