/*

@name book-it
@description book-it; block used for continuing to the order page
@section Organisms
@type UI
@extended false
@uses Button, Price, List

@markup
<section class="book-it">
    <header class="book-it__header">
        <h2 class="visuallyhidden">Bestellen</h2>
        <p class="book-it__price-description">Vanafprijs per persoon:</p>
        <span class="price book-it__price">€ 35</span>
    </header>
    <div class="book-it__box">
        <a href="javascript:void(0)" class="button button--arrow book-it__button">Nu boeken</a>
        <hr class="book-it__seperator" />
        <ul class="list list--checkmark book-it__motivators">
            <li>Veilig betalen met iDeal</li>
            <li>7 dagen per week bereikbaar</li>
            <li>Bespaar 40%</li>
        </ul>
    </div>
</section>

*/

$book-it-header-height: $hero-footer-height;

// BASE
// ----

.book-it {
}

// PARTS
// -----

// HEADER

.book-it__header {
    background: $ns-yellow;
    height: toRem($book-it-header-height);
    padding-right: toRem(28px);
    padding-left: toRem(28px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// PRICE DESCRIPTION

.book-it__price-description {
    color: rgba(0, 0, 0, 0.6);
    font-size: toRem(14px);
    line-height: toRem(20px);
    text-align: right;
    margin: 0;
    width: toRem(80px);
}

// PRICE

.book-it__price {
    margin-left: toRem(14px);
    flex: 0 0 auto;

    @include respond-min($m) {
        margin-left: toRem(20px);
    }
}

// DESCRIPTION

.book-it__description {
    width: auto;
    // IE11
    @media screen and (min-width:0\0) {
        width: 100%;
    }

    margin-bottom: 0;

    padding-right: toRem($grid-gutter / 2);

    color: $ns-blue;
}

// OFFER

.book-it__offer {
    display: inline-block;
    @include fontSmoothing;
    font-weight: 700;
    color: $ns-blue;
    white-space: nowrap;
}

// BOX

.book-it__box {
    border: solid 1px $border-color;
    border-top: 0;
    padding: toRem($grid-gutter);

    padding-right: $grid-gutter / 2;
    padding-left: $grid-gutter / 2;

    @include respond-min($sm) {
        padding-right: $grid-gutter;
        padding-left: $grid-gutter;
    }

    display: flex;
    flex-flow: column;
}

// SEPARATOR

.book-it__seperator {
    width: 100%;
    margin: toRem(28px) 0;
}

// ADDITIONAL CONTENT

.book-it__additional-content {
    border: solid 1px $border-color;

    padding: toRem($grid-gutter);

    margin-top: toRem($grid-gutter / 2);
}

// ADDITIONAL CONTENT HEADING

.book-it__additional-content-heading {
    margin-bottom: toRem($spacing-3);
}

// ADDITIONAL CONTENT BODY

.book-it__additional-content-body {
    margin-bottom: 0;

    + .button {
        margin-top: toRem($spacing-3);
    }

}

// OPTIONS
// -------

// NO PRICE

.book-it--no-price {

    .book-it__header {
        justify-content: space-between;
    }

}

// TEXT

.book-it__extra-info, .book-it__disclaimer {
    color: $ns-grey-4;
    font-size: toRem(14px);

    &.book-it__disclaimer {
        padding-top: toRem(15px);
        margin-bottom: toRem(0px);
    }

}

.book-it__status-title {
    @include fontSize('body');
    margin-bottom: 0px;
}

.book-it--temporarilyUnavailable {
    .book-it__status-title {
        color: $ns-red-alert;
    }

    .book-it__status-description {
        color: $ns-red-alert;
        @include fontSize('body');
    }
}
