//  Global component metrics
// ---------------------------------------------------------------------------

$global--header-height: 60px;

//  Global color assignments
// ---------------------------------------------------------------------------

$list-bullet-color: $ns-blue;
$body-color: $black;
$link-color: $ns-light-blue;
$link-hover-color: $ns-blue;

// Base typography styling
// ---------------------------------------------------------------------------

$body-background-color: white;
$body-color: $ns-almost-black;
$heading-color: $ns-blue;
$border-color: $ns-grey-7;
$font-size-base: 16px;
$font-size-percentage-mobile: 0.875;

$line-height-base: 25px;

$default-margin-bottom: 10px;

// Spacing (often used for inner component paddings)
// ---------------------------------------------------------------------------
$default-spacing: 5px;

$spacing: $default-spacing;
$spacing-2: $spacing * 2;
$spacing-3: $spacing * 3;
$spacing-4: $spacing * 4;
$spacing-5: $spacing * 5;
$spacing-6: $spacing * 6;
$spacing-7: $spacing * 7;
$spacing-8: $spacing * 8;
$spacing-9: $spacing * 9;
$spacing-10: $spacing * 10;

$spacing-array: (
    $spacing,
    $spacing-2,
    $spacing-3,
    $spacing-4,
    $spacing-5,
    $spacing-6,
    $spacing-7,
    $spacing-8,
    $spacing-9,
    $spacing-10
);


// Grid spacing (often used for outer component margins)
// ---------------------------------------------------------------------------
$default-grid-spacing: 14px;

$grid-spacing: $default-grid-spacing;
$grid-spacing-2: $grid-spacing * 2; // 28px
$grid-spacing-3: $grid-spacing * 3; // 42px
$grid-spacing-4: $grid-spacing * 4; // 56px
$grid-spacing-5: $grid-spacing * 5; // 70px
$grid-spacing-6: $grid-spacing * 6; // 84px
$grid-spacing-7: $grid-spacing * 7; // 98px
$grid-spacing-8: $grid-spacing * 8; // 112px

