$grid-base-width: 1172px;
$grid-gutter: 28px;
$grid-outer-width: $grid-base-width + ($grid-gutter * 2);

@lost flexbox flex;
@lost gutter #{$grid-gutter};
@lost rounder 99.9999;

/**
 * Apply grid wrapper to child element
 */
@mixin grid-wrapper($flex: true) {
    @if($flex) {
        lost-center: $grid-outer-width $grid-gutter;
        justify-content: space-between;
    } @else {
        display: block;
        lost-center: $grid-outer-width $grid-gutter no-flex;
    }
}

/**
 * Set column properties on child element
 * $size: Column size that accepts fraction (e.g. 1/3) or factor (e.g. 3)
 * $rounder: Custom rounder to use
 */
 @mixin grid-column($size: 1, $rounder: null) {
    lost-column: $size;
    @if ($rounder) {
        lost-column-rounder: $rounder;
    }
    flex-basis: auto; // IE10+ fix overrides property set by lost-column
}

@mixin grid-offset($offset: null) {
    @if ($offset) {
        lost-offset: $offset;
    }
}

@mixin grid-row-container() {
    lost-flex-container: row;
}

@mixin grid-column-container() {
    lost-flex-container: column;
    flex-flow: row wrap;
    align-items: flex-start;
}

@mixin grid-align-items($align: center) {
    lost-align: $align;
}
