.section {
    padding-top: toRem($grid-gutter);
}

.section--contrast {
    background: $ns-grey-7;
}

.section--compressed {
    padding-top: toRem($grid-gutter / 2);
}
@include respond-min($sm) {
    .section--compressed {
        padding-top: toRem($grid-gutter);
    }
}

.section--spaced {
    padding-top:$grid-spacing-3;
    padding-bottom:$grid-spacing-3;

    @include respond-min($l) {
        padding-top: $grid-spacing-7;
        padding-bottom: $grid-spacing-7;
    }
}
