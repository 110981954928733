.h-text-normal {
    font-weight: 400;
}

.h-text-bold {
    font-weight: 700;
    @include fontSmoothing;
}

.h-text-strikethrough {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: toRem(1px) solid;
        border-color: inherit;
        transform:rotate(-25deg);
    }
}

.h-text-small {
    @include fontSize('body-small');
}

.h-text-large {
    @include fontSize('body-large');
}

.h-text-xs {
    @include fontSize('xs');
}
