$pane-padding-default: $grid-gutter;
$pane-padding-large: $pane-padding-default * 1.5;
$pane-padding-small: $pane-padding-default / 2;


.pane {
    width: 100%;
    background: $white;
    @include ns-box-shadow();
}

.pane__header {
    display: flex;
    flex-flow: column;
    align-items: left;
    justify-content: flex-end;
    position: relative;
    padding: toRem($pane-padding-small) toRem($pane-padding-default);
    min-height: toRem(180px);

    @include respond-min($l) {
        padding: toRem($pane-padding-default)
    }
}

.pane__header--desktop-only {
    display: none;

    @include respond-min($l) {
        display: block;
    }
}

.pane__header-picture {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    }
}

.pane__header-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 1px;
    max-width: none;
}

.pane__title {
    position: relative;
    margin-bottom: 0;
    color: $white;
    font-weight: 700;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
    @include fontSmoothing;
}

.pane__subtitle {
    position: relative;
    color: $white;
    font-weight: normal;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.pane__heading {
    margin-bottom: toRem($grid-spacing);
}

.pane__ad {
    margin-bottom: $grid-spacing-2;
}
.pane__content {
    padding: toRem($pane-padding-default);
    p:last-child {
        margin-bottom: 0;
    }
}


.pane__content-title {
    display: none;
    font-weight: normal;
    margin-bottom: toRem($pane-padding-default);

    @include respond-min($l) {
        display: block;
    }
}

.pane__content-offset {
      width: 100%;

      @include respond-min($l) {
          margin-bottom: $grid-spacing-3;
      }

      @include respond-min($xl) {
          @include grid-column(7/8);
      }

      @include respond-min($xxl) {
          @include grid-column(6/8);
      }
}

/* modifiers */
.pane--large {
    .pane__header,
    .pane__content
    {
        padding: toRem($pane-padding-default);

        @include respond-min($l) {
            padding: toRem($pane-padding-large);
        }
    }
}

// Specific cart-configurator modifier
.pane--desktop-heading-only {

    .pane__title {
        @include fontSize('xxl');
    }

    @include respond-min($l) {
        .pane__header {
            padding-bottom: 0;
            min-height: 0;
        }

        .pane__content {
            padding-top: $grid-spacing-2;
        }

        .pane__header-picture {
            display: none;
        }

        .pane__title {
            @include fontSize('xl');
            font-weight: normal;
            color: $ns-blue;
            text-shadow: none;
        }

        .pane__subtitle {
            display: none;
        }
    }
}

.pane--padded {
    padding: $spacing-6;

    @include respond-min($sm) {
        padding: $spacing-10;
    }

    :last-child {
      margin-bottom: 0;
    }

    // emergency fix CMS-8418, to override helper class.
    // ToDo: refactor this.
    [class*="h-mb-"]:last-child {
        margin-bottom: 0 !important;
    }

}

.pane--primary {
    background-color: $ns-blue;

    color: $white;
}

.pane--flat {
    box-shadow: none;
}

.pane--content-compact {

    .pane__content {
        padding: 0;

        @include respond-min($m) {
            @include grid-column(4/6);
            @include grid-offset(1/6);
        }

        @include respond-min($l) {
            @include grid-column(2/4);
            @include grid-offset(1/4);
        }

    }

}
