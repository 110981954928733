/*

@name Panel
@description Panel; Displaying text and linking to a collection page inside a showcase
@section Organisms
@type UI
@extended false

@modifier .panel--highlighted      - Panel with a bigger font-size and a larger size

@markup
<div class='panel {{modifier_class}}'>
  <svg class="panel__background icon" aria-hidden="true">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#{{background}}"></use>
  </svg>
  <span class='panel__title'>{{title}}</span>
  <p class='panel__text'>{{text}}</p>
  <a href="#0" class="button button--secondary button--arrow" title="">
    <span class="button__text">{{text}}</span>
    <svg class="button__icon button__icon--right icon icon--textColor" width="11" height="11" viewBox="0 0 18 18" aria-hidden="true">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#chevron-mini"></use>
    </svg>
  </a>
</div>

*/

// BASE
// ----

.panel {
    display: block;
    position: relative;
    padding: $spacing-5;
    min-height: auto;
    overflow: hidden;
    background-color: $white;
    border-bottom: 4px solid $ns-yellow;
}

// PARTS
// -----

// BACKGROUND

.panel__background {
    opacity: 0.1;
    width: 140px;
    height: 140px;
    bottom: $grid-spacing-2;
    right: -$grid-spacing;
    position: absolute;
}

// TITLE

.panel__title {
    display: block;
    padding: 0;
    @include fontSize('l');
    font-weight: 400;
    color: $ns-blue;
    text-shadow: none;
    margin-bottom: $grid-spacing;
}

// TEXT

.panel__text {
    padding: 0;
    margin-bottom: $grid-spacing;
    font-size: toRem(16px);
    color: $ns-grey-3;
    min-height: auto;
}

// MODIFIERS

// Can be used to style panels that are highlighted in a showcase
.panel--highlighted {

    .panel__background {
        @include respond-min($l) {
            width: 210px;
            height: 210px;
            bottom: $grid-spacing;
            right: -$grid-spacing-2;
        }
    }

    .panel__title {
        @include fontSize('l');
    }

    .panel__text {
        @include respond-min($l) {
            margin-bottom: $grid-spacing-2;
            font-size: toRem(18px);
        }
    }
}
