p {
    width: 100%;
    margin-top: 0;
    margin-bottom: 1em;
}

b,
strong {
    font-weight: 700;
    @include fontSmoothing;
}
