.site-footer__container {
    @include grid-column-container;
    color: $ns-grey-3;
}

.site-footer__section {
    @include grid-row-container;
    width: 100%;
    padding-top: toRem($grid-spacing-2);
    padding-bottom: toRem($grid-spacing-2);

    @include respond-min($m) {
        padding-top: toRem($grid-spacing-3);
        padding-bottom: toRem($grid-spacing-3);
    }

    & + & {
        border-top: solid 1px $border-color;
    }
}

.site-footer__section-columns {
    @include grid-row-container;
    width: 100%;
}

.site-footer__column {
    @include grid-column(1/1);

    @include respond-min($m) {
         @include grid-column(1/2 2);
    }

    @include respond-min($l) {
         @include grid-column(1/3 3);
    }

    & + & {
        margin-top: $grid-gutter;
    }

    @include respond-min($m) {

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(n+3) {
            margin-top: $grid-gutter;
        }

    }

    @include respond-min($l) {

        &:nth-child(3) {
            margin-top: 0;
        }

        // just in case more columns are added
        &:nth-child(n+4) {
            margin-top: $grid-gutter;
        }

    }

}

.site-footer__column-title {
    margin-bottom: toRem($grid-spacing);
    color: $ns-blue;
    @include fontSize('xxs');
    font-weight: 700;
    @include fontSmoothing;
}

.site-footer__bottom {
    @include grid-row-container;
    width: 100%;
    border-top: solid toRem(1px) $border-color;
    padding-top: toRem($grid-spacing-2);
    padding-bottom: toRem($grid-spacing-2);
}

.site-footer__logo-ns {
    width: toRem(51px);
    height: toRem(20px);
}

.site-footer__logo-facebook {
    width: toRem(19px);
    height: toRem(19px);
}

.site-footer__bottom-links {

    @include grid-column(2/3);
    margin-top: 0;
    margin-bottom: 0;
    order: 1;

    @include respond-min($m) {
        @include grid-column(1/3);
        order: 0;
    }
}

.site-footer__follow {

    width: 100%;
    order: 0;
    margin-bottom: toRem($grid-spacing-2);
    color: $ns-grey-4;

    @include respond-min($m) {
        @include grid-column(1/3);
        order: 1;
        margin-bottom: 0;
    }
}

.site-footer__logo {

    @include grid-column(1/3);
    @include grid-align-items(right);
    order: 2;

    @include respond-min($m) {
        @include grid-column(1/3);
    }
}

.site-footer__logo-link {
    display: block;
}


.site-footer__title {
  width: 100%;
  margin-bottom: toRem($grid-spacing);
}

.site-footer__intro {

    &:last-child {
        margin-bottom: 0;
    }

}

// MODIFIERS

// CUSTOMER SERVICE
.site-footer__section-columns--justified {
    .site-footer__column {
        @include grid-column(1/1);
    }

    .site-footer__column:nth-child(2n+1) {
        @include respond-min($l) {
            @include grid-column(3/5 2);
        }

        @include respond-min($xl) {
            @include grid-column(7/12 2);
        }
    }

    .site-footer__column:nth-child(2n) {
        margin-top: toRem($grid-spacing-2);

        @include respond-min($l) {
            margin-top: 0;
            @include grid-column(2/5 2);
        }

        @include respond-min($xl) {
            @include grid-column(1/3 2);
        }
    }
}

// THIRDS
.site-footer__column--m-full {

    @include respond-min($m) {
        @include grid-column(1/1);

        margin-bottom: $grid-gutter;

        ~ .site-footer__column {
            margin-top: 0;
            margin-left: $grid-gutter;
        }

        + .site-footer__column {
            margin-left: 0;
        }

        .site-footer__intro {
            width: 50%;
        }

    }

    @include respond-min($l) {
        @include grid-column(1/3);

        margin-bottom: 0;

        ~ .site-footer__column {
            margin-left: 0;
        }

        .site-footer__intro {
            width: 100%;
        }

    }

}
