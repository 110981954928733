/*
@name List
@description A list with items, and each item has a square in front of it

@section Molecules
@category Lists
@type UI
@extended false

@modifier .list--arrow - chevrons instead of squares
@modifier .list--checkmark - checkmark instead of squares
@modifier .list--grey - grey links and list item images
@modifier .list--inline - horizontal list of items
@modifier .list--spaced - extra margin between items
@modifier .list--unstyled - unstyled list

@markup
<ul class="list {{modifier_class}}">
    <li><a href="javascript:void(0)">Trein + Uitje</a></li>
    <li><a href="javascript:void(0)">Trein + Hotel</a></li>
    <li><a href="javascript:void(0)">Cadeaukaart</a></li>
</ul>
*/

.list {
    margin-bottom: 0;
}

.list--arrow {
    .list__item {
        padding: 0;

        &:before {
            content: none;
        }
    }

    &.list--inline {
        .list__item {
            margin-bottom: 0;
        }
    }

}

.list--checkmark {
    .list__item {
        background: no-repeat left 5px;
        background-image: url(#{$svg-icon-checkmark});
        background-size: toRem(14px) toRem(12px);
        padding-left: toRem($grid-spacing-2);

        &:before {
            content: none;
        }
    }
}

.list--small {
    .list__item {
        @include fontSize('body-small');
    }
}

.list--inline {
    @include grid-row-container;
    display: inline-flex;

    .list__item {
        margin-right: toRem($grid-spacing-2);

        &:last-child {
            margin-right: 0;
        }
    }
}

.list--grey {
    .list__item {
        color: $ns-grey-4;
    }

    a {
        color: $ns-grey-4;
        &:hover,
        &:focus {
            color: $ns-grey;
        }
    }

    &.list--arrow a {
        &:hover,
        &:focus {
            color: $ns-grey;
        }
    }
}

.list--spaced {
    .list__item {
        margin-bottom: toRem($spacing);
    }
}

// Generate more spaced variants
@each $spacing-variant in $spacing-array {
    $index: index($spacing-array, $spacing-variant);

    .list--spaced-#{$index} {
        .list__item {
            margin-bottom: toRem($spacing-variant);
        }
    }
}

.list--spaced,
.list--spaced-2,
.list--spaced-3,
.list--spaced-4,
.list--spaced-5 {
    .list__item {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin list-reset {
    // Resets default NS styling, not user agent styling
    & > li {
        padding-left: inherit;
        &:before {
            content: none;
        }
    }
}

.list--reset {
    @include list-reset();
}
