.cart-ticket {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
}

.cart-ticket__title {
    width: 100%;
    margin-bottom: $grid-spacing;
}

.cart-ticket__list {
    width: 100%;
    @include unlist();
    color: $ns-blue;
}

.cart-ticket__item {
    display: flex;
    padding-left: 0;
    margin-bottom: $spacing-3;
}

.cart-ticket__item-label {
    flex: 1 1 auto;
    margin-right: toRem($spacing-2);
}

.cart-ticket__item-value {
    text-align: right;
    flex: 1 0 auto;
    white-space: nowrap;
}

.cart-ticket__total {
    display: flex;
    flex-wrap: wrap;
    margin-top: $grid-spacing;
    margin-bottom: $grid-spacing-2;
    padding-top: $grid-spacing;
    padding-left: 0;
    border-top: 2px solid $ns-blue;
}

.cart-ticket__total-label {
    flex: 1 1 auto;
    @include fontSize('body-large', 30px, 30px);
}

.cart-ticket__total-value {
    flex: 1 1 5%;
    @include fontSize('m');
    text-align: right;
    white-space: nowrap;
}

.cart-ticket__total-rule {
    flex: 0 0 auto;
    @include fontSize('body-small');
    color: $ns-grey-4;
    width: 100%;
    margin-top: $spacing-2;
}

.cart-ticket__button {
    margin-bottom: $grid-spacing-2;

    @include respond-min($m) {
        width: 30%;
        flex: 1 1 auto;
        order: 4;
    }

    @include respond-min($l) {
        width: 100%;
        order: 0;
    }
}

.cart-ticket__motivators {
    width: 100%;

    @include respond-min($m) {
        width: 70%;
        flex: 1 1 auto;
        padding-right: $grid-gutter;
        order: 3;
    }

    @include respond-min($l) {
        width: 100%;
        order: 0;
        padding-right: 0;
    }
}
