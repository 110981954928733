/*
@name Definition list
@description A list with items, and each item has a title and description

@section Molecules
@category Lists
@type UI
@extended false

@markup
<dl class="definition-list {{modifier_class}}">
    <dt class="definition-list__title">Title 1</dt>
    <dt class="definition-list__content"><p>Content<p></dt>
    <dt class="definition-list__title">Title 2</dt>
    <dt class="definition-list__content">Content with a <a href="#">link</a> inside it.</dt>
</dl>
*/

.definition-list {
    margin-bottom: 0;
    margin-top: toRem($grid-gutter / 2);

    @include respond-min($sm) {
        display: flex;
        flex-flow: row wrap;

        .definition-list__title,
        .definition-list__content {
            flex: 1 1 50%;
        }
    }
}

.definition-list__title {
    // Nothing special
}

.definition-list__title--spaced {
    margin-bottom: toRem($default-margin-bottom / 4);
}

.definition-list__content {
    // Nothing special
}
