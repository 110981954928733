ul, ol {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: toRem(42px);

    > li {
        padding-left: toRem(22px);
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}


ul {
    > li {
        &:before {
            content: '';
            background-color: $list-bullet-color;
            width: 8px;
            height: 8px;
            display: block;
            top: 0.52em;
        }
    }
}


$ol-list-counter-width: 24px; // Just enough space for 2 digits

ol {
    counter-reset: ol;

    > li {
        padding-left: toRem($ol-list-counter-width + $grid-spacing);

        &:before {
            display: inline-block;
            width: toRem($ol-list-counter-width);
            content: counter(ol) ".";
            counter-increment: ol;
            color: $ns-blue;
            font-weight: 700;
            text-align: right;
            @include fontSmoothing;
        }
    }
}
