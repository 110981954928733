// SKIPLINK
// ========

// BASE
// ----

.skiplink {
  @include accHide;

  top: 10px;
  z-index: 522; // --> 2 or 3 after merge CMS-7675

  display: inline-block;

  padding: .5rem 1rem;

  border: 1px solid $ns-blue;

  background-color: #fff;

  &:focus {
    @include accShow(fixed, 10px);
  }

}
