.page-error {

}

.page-error__text {
    width: 100%;

    @include respond-min($l) {
        @include grid-column(1/2 2);
    }
}

.page-error__image {
    width: 100%;

    @include respond-min($l) {
        @include grid-column(1/2 2);
    }
}
