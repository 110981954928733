// #### ATTENTION BOX ####
// =======================

// BASE
// ----

.attention-box {
    @include respond-min($l) {
        background-color: $white;
        padding: $spacing-7;

        @include ns-box-shadow;
    }
}

// PARTS
// -----

.attention-box__title {
    margin-bottom: toRem($grid-spacing);
    color: $ns-blue;

    @include fontSize('m');
}

.attention-box__link {
    box-shadow: 0 1px 0 $link-color;

    &:hover {
        box-shadow: 0 1px 0 $link-hover-color;
    }
}

.attention-box__linklist {
    @include unlist;

    li {
        display: inline-block;
        padding-left: 0;
        margin-bottom: toRem(8px);
        margin-right: toRem($grid-gutter);
    }
}

// MODIFIERS
// -----

.attention-box--secondary {
    @include respond-min($l) {
        background-color: $ns-grey-8;
        border-left: 4px solid $ns-blue;
    }

    .attention-box__title {
        @include fontSize('xs');
    }

    .attention-box__content {
        @include fontSize('xs');

        > p {
            color: $ns-blue;
        }
    }
}
