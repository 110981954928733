/*
@name Collapsible text
@description
An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.

@section Molecules
@type UI
@extended false

@markup
<div class="list collapsibleText is-collapsible">
    <h3 class="collapsibleText__header">Header</h3>
    <section class="collapsibleText__body">Body</section>
</div>
*/

.collapsibleText {
    width: 100%;
}

.collapsibleText__header {
    position: relative;
    display: none;
    margin-bottom: toRem(14px);
    font-size: toRem($font-size-base);
    color: $ns-light-blue;
    cursor: pointer;

    &:hover {
        color: $ns-blue;
    }
}

.collapsibleText__icon {
    margin-left: toRem(8px);
}

.collapsibleText__body {
    padding-bottom: toRem(17px);
}

// Based on this state class we add some interactivity
.is-collapsible {
    .collapsibleText__header {
        display: block;
    }

    .collapsibleText__body {
        display: none;

        &.is-expanded {
            display: block;
        }
    }
}
