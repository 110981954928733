$fontSizes: (
    'xxxxl': (
        'medium': (80px, 88px),
        'small': (36px, 45px)
    ),
    'xxxl': ( // default value for h1
        'medium': (60px, 75px),
        'small': (28px, 35px)
    ),
    'xxl': ( // default value for h1 in pane
        'medium': (48px, 60px),
        'small': (36px, 45px)
    ),
    'xl': ( // extra size
        'medium': (38px, 45px),
        'small': (32px, 40px)
    ),
    'l': ( // default value for h2
        'medium': (30px, 40px),
        'small': (28px, 35px)
    ),
    'm': ( // extra size
        'medium': (24px, 30px),
        'small': (20px, 30px)
    ),
    's': ( // default value for h3
        'medium': (20px, 25px),
        'small': (18px, 25px)
    ),
    'xs': ( // h4, h5, h6
        'medium': (18px, 25px),
        'small': (16px, 25px)
    ),
    'xxs': ( // h4, h5, h6
        'medium': (16px, 25px),
        'small': (16px, 25px)
    ),
    'body-large': (
        'medium': (18px, 25px),
        'small': (18px, 25px)
    ),
    'body': ( // default
        'medium': ($base-font-size, 25px),
        'small': ($base-font-size, 25px)
    ),
    'body-small': (
        'medium': (14px, 20px),
        'small': (14px, 20px)
    ),
    'body-scaled': (
        'medium': (16px, 25px),
        'small': (14px, 20px)
    )
);

@mixin fontSize($sizeName, $customLineHeightSmall: false, $customLineHeightMedium: false) {
    $specs: map-get($fontSizes, $sizeName);

    // Small (default) font-sizing
    $small: map-get($specs, 'small');
    $smallFontSize: nth($small, 1);
    $smallLineHeight: nth($small, 2);

    font-size: toRem($smallFontSize);

    @if ($customLineHeightSmall != false) {
        line-height: $customLineHeightSmall / $smallFontSize;
    } @else {
        line-height: $smallLineHeight / $smallFontSize;
    }

    // Medium+ font-sizing
    $medium: map-get($specs, 'medium');
    $mediumFontSize: nth($medium, 1);
    $mediumLineHeight: nth($medium, 2);

    $mediumDiffersFromMobile: (($smallFontSize != $mediumFontSize) or ($smallLineHeight != $mediumLineHeight));

    @if ($mediumDiffersFromMobile == true) {
        @include respond-min($m) {
            font-size: toRem($mediumFontSize);

            @if ($customLineHeightMedium != false) {
                line-height: $customLineHeightMedium / $mediumFontSize;
            } @else {
                line-height: $mediumLineHeight / $mediumFontSize;
            }
        }
    }
}

.heading {
    width: 100%;
}

.heading--xxxxl { @include fontSize('xxxxl') }
.heading--xxxl { @include fontSize('xxxl') }
.heading--xxl { @include fontSize('xxl') }
.heading--xl { @include fontSize('xl') }
.heading--l { @include fontSize('l') }
.heading--m { @include fontSize('m') }
.heading--s { @include fontSize('s') }
.heading--xs { @include fontSize('xs') }
.heading--xxs { @include fontSize('xxs') }

.heading--bold {
    font-weight: 700;
    @include fontSmoothing;
}

.heading--inline {
    width: auto;
    @include respond-min($m) {
        display: inline-block;
        margin-right: $grid-spacing;
    }
}
