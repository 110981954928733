@charset "UTF-8";
/* ===== SETTINGS
 * This holds any global settings. Examples of global settings might be things like
 * the base font size, colour palettes, config (for example, $environment: dev;) and so on.
 */
@font-face {
  font-family: 'NS Sans';
  src: url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-regular.woff2") format("woff2"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-regular.woff") format("woff"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-regular.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'NS Sans';
  src: url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-italic.woff2") format("woff2"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-italic.woff") format("woff"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-italic.ttf") format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'NS Sans';
  src: url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bolditalic.woff2") format("woff2"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bolditalic.woff") format("woff"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bolditalic.ttf") format("truetype");
  font-display: swap;
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'NS Sans';
  src: url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bold.woff2") format("woff2"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bold.woff") format("woff"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-bold.ttf") format("truetype");
  font-display: swap;
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'NS Sans';
  src: url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-demi.woff2") format("woff2"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-demi.woff") format("woff"), url("https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/1.0.1/nssans-demi.ttf") format("truetype");
  font-weight: 550;
  font-style: normal;
  font-display: swap; }

/* ===== TOOLS
 * This contains the globally available tooling – namely mixins and functions.
 * Any mixin or function that does not need accessing globally should belong in the partial to which it relates.
 */
/*
 * Strip the units
 */
/*
 * Calculates the number of rem/em units based on the input pixel count.
 */
/**
 * Fade in animation
 */
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/**
 * Fade in Downs animation
 */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@lost flexbox flex;
@lost gutter 28px;
@lost rounder 99.9999;
/**
 * Apply grid wrapper to child element
 */
/**
 * Set column properties on child element
 * $size: Column size that accepts fraction (e.g. 1/3) or factor (e.g. 3)
 * $rounder: Custom rounder to use
 */
/*
 * For now, inline SVG icons are URL encoded to be placed as background images.
 * This works in IE10+. This should be automated in the future!
 */
/* ===== GENERIC
 * This houses very high-level, far reaching styles. It contains things like Normalize.css,
 * global box-sizing rules, CSS resets and so on.
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

address {
  font-style: normal; }

figure {
  margin: 0; }

button,
[type="button"],
[type="submit"],
[type="file"],
::-webkit-file-upload-button {
  border-radius: 0; }

/* ===== ELEMENTS
 * These are bare, unclassed HTML elements. The Elements layer binds
 * onto bare HTML element (or 'type') selectors only.
 */
dt {
  font-weight: 700;
  color: #003082;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

dd {
  margin-left: 0;
  margin-bottom: 0.875rem; }

dd:last-child {
  margin-bottom: 0; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.125rem;
  color: #003082; }

hr {
  border: 0;
  height: 0.0625rem;
  background-color: #e6e6e9; }

html, body {
  font-family: "NS Sans", "Segoe UI", Myriad, Verdana, sans-serif; }

html {
  font-size: 100%; }

body {
  background-color: white;
  color: #060922;
  line-height: 1.5625;
  min-width: 20rem; }
  @media print {
    body {
      background-color: #FFFFFF;
      padding-top: 0;
      max-width: 60rem;
      margin: 0 auto; } }

img {
  max-width: 100%; }

a {
  color: #0063d3;
  text-decoration: none; }
  a:hover {
    color: #003082; }

ul, ol {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 2.625rem; }
  ul > li, ol > li {
    padding-left: 1.375rem;
    position: relative; }
    ul > li:before, ol > li:before {
      position: absolute;
      left: 0;
      top: 0; }

ul > li:before {
  content: '';
  background-color: #003082;
  width: 8px;
  height: 8px;
  display: block;
  top: 0.52em; }

ol {
  counter-reset: ol; }
  ol > li {
    padding-left: 2.375rem; }
    ol > li:before {
      display: inline-block;
      width: 1.5rem;
      content: counter(ol) ".";
      counter-increment: ol;
      color: #003082;
      font-weight: 700;
      text-align: right;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

p {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1em; }

b,
strong {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

th, td {
  vertical-align: top; }

/* ===== OBJECTS
 * Here we find class-based selectors. These are concerned with styling non-cosmetic
 * design patterns, or 'objects'. Objects can range from something as simple as a
 * .wrapper element, to layout systems.
 */
/*

@name Button
@description Button; used for form-actions and major CTA links.
@section Atoms
@type UI
@extended false

@modifier .button--arrow                    - Button with an arrow
@modifier .button--secondary                - Button with secondary colour
@modifier .button--link                     - Button that looks like a link
@modifier .button--icon                     - Button that looks like an icon

@markup
<button class="button {{modifier_class}}">Default Button</button>
<button disabled="disabled" class="button {{modifier_class}}">
    Disabled Button
</button>
<a href="javascript:void(0)" class="button {{modifier_class}}">Anchor Button</a>

*/
.button {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 2.8125rem;
  background-color: #0063d3;
  box-shadow: 0 -0.125rem 0 #004ba0 inset;
  color: #FFFFFF;
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  white-space: nowrap; }
  .button:focus, .button:hover {
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 -3.125rem 0 #004ba0 inset; }
  .button[disabled] {
    background-color: #f3f3f4;
    color: #b5b5bc;
    cursor: not-allowed;
    box-shadow: 0 -0.125rem 0 #e6e6e9 inset;
    transition: none; }
    .button[disabled]:focus, .button[disabled]:hover {
      color: #b5b5bc;
      box-shadow: 0 -0.125rem 0 #e6e6e9 inset;
      cursor: not-allowed; }

.button--secondary {
  background-color: #ffc917;
  box-shadow: 0 -0.125rem 0 #e3ae00 inset;
  color: #003082; }
  .button--secondary:focus, .button--secondary:hover, .button--secondary:active {
    color: #003082;
    box-shadow: 0 -3.125rem 0 #e3ae00 inset; }

.button--tertiary {
  background-color: transparent;
  color: #0063d3;
  border: 1px solid #0063d3;
  border-bottom: 0;
  box-shadow: 0 -0.125rem 0 #0063d3 inset; }
  .button--tertiary:focus, .button--tertiary:hover, .button--tertiary:active {
    box-shadow: 0 -0.125rem 0 #003082 inset;
    border-color: #003082;
    color: #003082; }
  .button--tertiary[disabled], .button--tertiary[disabled]:hover, .button--tertiary[disabled]:focus, .button--tertiary[disabled]:active {
    background-color: transparent;
    border-color: #e6e6e9;
    color: #b5b5bc; }

.button--link {
  padding: 0;
  background: none;
  box-shadow: none;
  color: #0063d3;
  display: inline;
  height: auto;
  text-align: left; }
  .button--link:focus {
    color: #0063d3;
    box-shadow: none; }
  .button--link:hover {
    color: #003082;
    box-shadow: none; }

.button--icon {
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  height: auto;
  line-height: 1.5625; }
  .button--icon:focus, .button--icon:hover {
    box-shadow: none; }

.button--full {
  width: 100%; }

.button--square {
  width: 2.8125rem;
  padding-left: 0;
  padding-right: 0; }

.button__text {
  vertical-align: middle; }

.button__icon--left {
  margin-right: 0.3125rem; }

.button__icon--right {
  margin-left: 0.3125rem; }

.button--disabled {
  background-color: #f3f3f4;
  color: #b5b5bc;
  cursor: not-allowed;
  box-shadow: 0 -0.125rem 0 #e6e6e9 inset;
  transition: none; }
  .button--disabled:focus, .button--disabled:hover {
    color: #b5b5bc;
    box-shadow: 0 -0.125rem 0 #e6e6e9 inset;
    cursor: not-allowed; }

/*

@name Price
@description Price; used for showing a price
@section Atoms
@type UI
@extended false

@markup
<span class="price">€ 4</span>

*/
.price {
  font-size: 2.25rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #003082; }

.container {
  position: relative;
  lost-center: 1228px 28px;
  justify-content: space-between; }
  @media print {
    .container {
      width: 100%; } }

.container--no-flex {
  display: block;
  lost-center: 1228px 28px no-flex; }

.container--full-small {
  padding-right: 0;
  padding-left: 0; }
  @media screen and (min-width: 640px) {
    .container--full-small {
      padding-left: 1.75rem;
      padding-right: 1.75rem; } }

.container--full-medium {
  padding-left: 0;
  padding-right: 0; }
  @media screen and (min-width: 912px) {
    .container--full-medium {
      padding-left: 1.75rem;
      padding-right: 1.75rem; } }

.container--compact {
  max-width: 40rem; }
  @media screen and (min-width: 912px) {
    .container--compact {
      max-width: 1228px; } }

.container--paddedBottom {
  padding-bottom: 42px;
  /* in the template the h-mb-* classes can not be excluded on the last-child with handlebars */ }
  .container--paddedBottom :last-child {
    margin-bottom: 0 !important; }
  @media screen and (min-width: 640px) {
    .container--paddedBottom {
      padding-bottom: 70px; } }

.container__content {
  width: 100%;
  margin-bottom: 28px;
  padding-top: 14px;
  padding-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .container__content {
      lost-column: 0.83333;
      flex-basis: auto;
      lost-offset: 0.08333;
      padding-top: 28px;
      padding-bottom: 28px; } }
  @media screen and (min-width: 912px) {
    .container__content {
      lost-column: 0.66667;
      flex-basis: auto;
      lost-offset: 0.16667; } }

.heading {
  width: 100%; }

.heading--xxxxl {
  font-size: 2.25rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .heading--xxxxl {
      font-size: 5rem;
      line-height: 1.1; } }

.heading--xxxl {
  font-size: 1.75rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .heading--xxxl {
      font-size: 3.75rem;
      line-height: 1.25; } }

.heading--xxl {
  font-size: 2.25rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .heading--xxl {
      font-size: 3rem;
      line-height: 1.25; } }

.heading--xl {
  font-size: 2rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .heading--xl {
      font-size: 2.375rem;
      line-height: 1.18421; } }

.heading--l {
  font-size: 1.75rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .heading--l {
      font-size: 1.875rem;
      line-height: 1.33333; } }

.heading--m {
  font-size: 1.25rem;
  line-height: 1.5; }
  @media screen and (min-width: 640px) {
    .heading--m {
      font-size: 1.5rem;
      line-height: 1.25; } }

.heading--s {
  font-size: 1.125rem;
  line-height: 1.38889; }
  @media screen and (min-width: 640px) {
    .heading--s {
      font-size: 1.25rem;
      line-height: 1.25; } }

.heading--xs {
  font-size: 1rem;
  line-height: 1.5625; }
  @media screen and (min-width: 640px) {
    .heading--xs {
      font-size: 1.125rem;
      line-height: 1.38889; } }

.heading--xxs {
  font-size: 1rem;
  line-height: 1.5625; }

.heading--bold {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.heading--inline {
  width: auto; }
  @media screen and (min-width: 640px) {
    .heading--inline {
      display: inline-block;
      margin-right: 14px; } }

/*
@name Form
@description
Class to create a form markup with labels and their input fields. The `.form__label`
and `.form__input` are the wrappers around the labels and provide the division of
the space between them.

@section Atoms
@type UI
@extended false
@category form

@markup
<div class="form">
    <div class="form__row">
        <div class="form__label">
            <label>Name</label>
        </div>
        <div class="form__input">
            <input type="text" value="" placeholder="Input field"/>
        </div>
    </div>
</div>
*/
.form--centerLabels .form__label {
  justify-content: flex-end;
  padding-right: 0.875rem; }

.form__row:not(:last-child) {
  margin-bottom: 0.625rem; }

@media screen and (min-width: 480px) {
  .form__row {
    display: flex;
    flex-flow: row wrap; } }
  @media screen and (min-width: 480px) and (min-width: 0\0) {
    .form__row {
      flex-wrap: nowrap; } }

.form__label {
  display: flex;
  justify-content: left;
  align-items: center; }
  @media screen and (min-width: 480px) {
    .form__label {
      flex: 1 1 50%; } }

.form__label--spaced {
  margin-bottom: 0.15625rem; }

.form__label--title {
  color: #003082;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.form__label--right-aligned {
  flex-direction: row-reverse;
  padding-right: 10px; }

@media screen and (min-width: 480px) {
  .form__input {
    flex: 1 1 50%; } }

/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<input class="form-text" type="text" value="input type text" /><br><br>
<input class="form-text" type="email" value="input type email" /><br><br>
<input class="form-text" type="search" value="input type search" /><br><br>
<input class="form-text" type="password" value="input type password" /><br><br>
<input class="form-text" type="text" readonly="readonly" value="input readonly" /><br><br>

<div class="textInput">text in a dummy textInput</div><br>

*/
.form-text {
  padding-top: 0.6875rem;
  padding-bottom: 0.5625rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #FFFFFF;
  color: #070721;
  height: 2.8125rem;
  line-height: 1.5625rem;
  border: 0.0625rem solid #b5b5bc;
  border-radius: 0;
  -moz-appearance: textfield; }
  .form-text:focus {
    outline: 0; }
  .form-text::-webkit-inner-spin-button, .form-text::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.form-text--fullWidth {
  width: 100%; }

.form-text--largeDigit[readonly="readonly"] {
  background: transparent;
  padding: 0;
  border: 0;
  color: #003082;
  height: auto;
  font-size: 2rem;
  line-height: 1;
  width: 100%;
  text-align: right; }
  @media screen and (min-width: 640px) {
    .form-text--largeDigit[readonly="readonly"] {
      font-size: 2.25rem; } }

::placeholder {
  color: #6a6a7a; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

input[readonly="readonly"] {
  color: #9c9ca6;
  background-color: #f1f1f1; }

/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<textarea class="form-textarea">This is a simple texarea</textarea><br><br>

*/
.form-textarea {
  padding-top: 0.6875rem;
  padding-bottom: 0.5625rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #FFFFFF;
  color: #070721;
  height: 2.8125rem;
  line-height: 1.5625rem;
  border: 0.0625rem solid #b5b5bc;
  border-radius: 0;
  height: auto;
  max-width: 100%; }
  .form-textarea:focus {
    outline: 0; }

.form-textarea--fullWidth {
  width: 100%; }

/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<select class="form-select">
    <option>Optie 1</option>
    <option>Optie 2</option>
    <option>Optie 3</option>
 </select>

*/
.form-select {
  padding-top: 0.6875rem;
  padding-bottom: 0.5625rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #FFFFFF;
  color: #070721;
  height: 2.8125rem;
  line-height: 1.5625rem;
  border: 0.0625rem solid #b5b5bc;
  border-radius: 0;
  height: 2.8125rem;
  padding-right: 2.34375rem;
  display: block;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2235px%22%20height%3D%2235px%22%20viewBox%3D%220%200%2035%2035%22%20enable-background%3D%22new%200%200%2035%2035%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpolygon%20points%3D%2217.5%2C20.3%2012%2C14.8%2012%2C16.2%2017.5%2C21.7%2023%2C16.2%2023%2C14.8%20%20%20%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20%23003082%3B%20%7D%3C%2Fstyle%3E%3C%2Fsvg%3E");
  overflow: hidden;
  /* Option styling for Firefox */ }
  .form-select:focus {
    outline: 0; }
  .form-select:hover, .form-select:focus, .form-select:active {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2235px%22%20height%3D%2245px%22%20viewBox%3D%220%20-5%2035%2045%22%20enable-background%3D%22new%200%20-5%2035%2045%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20d%3D%22M0-5v45h35V-5H0z%20M23%2C16.2l-5.5%2C5.5L12%2C16.2v-1.4l5.5%2C5.5l5.5-5.5V16.2z%22%2F%3E%3C%2Fg%3E%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20%230063d3%3B%20%7D%3C%2Fstyle%3E%3C%2Fsvg%3E");
    cursor: pointer; }
  .form-select option {
    padding-left: 0.3125rem; }
  .form-select::-ms-expand {
    display: none; }
  .form-select:focus::-ms-value {
    background: transparent;
    color: #003082;
    outline: 0.0625rem dotted; }

.form-select--fullWidth {
  display: block;
  width: 100%; }

/*

@name Separator
@description Separator; used for thema pages.
@section Atoms
@type UI
@extended false

@markup
<hr class="separator" />
*/
.separator {
  width: 100%;
  background-color: #FFFFFF;
  height: 0.25rem; }

/*
@name Table
@description
Class to create a table markup

@section Atoms
@type UI
@extended false
@category table

@modifier .table--reset                    - Borders are collapsed into a single border when possible

@markup
table__header-col
<table class="table">
    <tr class="table_row">
        <th class="table__header-col"></th>
        <th class="table__header-col"></th>
    </tr class="table_row">
        <td class="table__data"></td>
        <td class="table__data"></td>
    </tr>
<table>

table__header-row:
<table class="table">
    <tr class="table_row">
        <th class="table__header-row" scope="row"></th>
        <td class="table__data"></td>
    </tr>
<table>
*/
.table--reset {
  border-collapse: collapse; }

.table__header-row {
  font-weight: normal;
  text-align: left; }

.collapse:not([open]) .collapse__icon--expand {
  display: block; }

.no-details .collapse:not([open]) .collapse__icon--expand {
  display: none; }

.collapse[open] .collapse__icon--collapse {
  display: block; }

.collapse__icon {
  display: none;
  position: absolute;
  right: 5px;
  top: calc(50% - 8px); }

.collapse__header {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1.0625rem 2.1875rem 1.0625rem 0;
  color: #0063d3; }
  .collapse__header::-webkit-details-marker {
    display: none; }
  .collapse__header:hover {
    color: #003082; }
  .no-details .collapse__header {
    cursor: auto; }
    .no-details .collapse__header:hover {
      color: #0063d3; }

.collapse__body {
  padding-bottom: 1.0625rem; }

/* ===== COMPONENTS
 * Here we find class-based selectors for styling explicit, designed pieces of the DOM.
 * We shouldn't find any selectors with a lower specificity than one class in this layer.
 */
/**
 * Icon module
 */
.icon {
  vertical-align: middle; }

.icon--nsWhite {
  fill: #FFFFFF; }

.icon--nsLightBlue {
  fill: #0063d3; }

.icon--nsBlue {
  fill: #003082; }

.icon--nsBlack {
  fill: #000; }

.icon--nsGrey4 {
  fill: #6a6a7a; }

.icon--nsGrey5 {
  fill: #9c9ca6; }

.icon--nsGrey6 {
  fill: #b5b5bc; }

.icon--nsGrey7 {
  fill: #e6e6e9; }

.icon--nsYellow {
  fill: #ffc917; }

.icon--nsRed {
  fill: #db0029; }

.icon--nsGreen {
  fill: #009a42; }

.icon--nsGreenOnGrey7 {
  fill: #017832; }

.icon--socialFacebookBlue {
  fill: #3B5998; }

.icon--socialInstagramPink {
  fill: #8A3AB9; }

.icon--socialLinkedInBlue {
  fill: #0077B5; }

.icon--socialPinterestRed {
  fill: #BD081C; }

.icon--socialTwitterBlue {
  fill: #00ACED; }

.icon--socialYouTubeRed {
  fill: #BB0000; }

.icon--textColor {
  fill: currentColor; }

.icon--s {
  width: 16px;
  height: 16px; }

.icon--sm {
  width: 24px;
  height: 24px; }

.icon--m {
  width: 32px;
  height: 32px; }

.icon--ml {
  width: 40px;
  height: 40px; }

.icon--l {
  width: 48px;
  height: 48px; }

.icon--xl {
  width: 56px;
  height: 56px; }

.icon--xl {
  width: 64px;
  height: 64px; }

.icon--xxl {
  width: 72px;
  height: 72px; }

.icon--xxxl {
  width: 80px;
  height: 80px; }

.icon--flipH {
  transform: scale(-1, 1); }

.icon--flipV {
  transform: scale(1, -1); }

/*

@name Hero image
@description Hero image for the top of the detail page
@section Molecules
@type UI
@extended true

@markup
<header class="hero">
    <picture class="hero__picture">
        <source srcset="images/hero_thema_1400x480.jpg" media="(min-width: 912px)">
        <source srcset="images/hero_thema_912x480.jpg" media="(min-width: 640px)">
        <source srcset="images/hero_thema_640x300.jpg" media="(min-width: 0px)">
        <img class="hero__image" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" class="img--contain" loading="eager">
    </picture>
    <div class="container hero__container">
        <h1 class="hero__title">Dagje uit en chocola</h1>
    </div>
</header>
*/
.hero {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  overflow: hidden; }

.hero__wrapper {
  position: relative;
  min-height: 16.25rem;
  height: auto;
  display: flex;
  box-sizing: border-box;
  overflow: hidden; }
  @media screen and (min-width: 640px) {
    .hero__wrapper {
      min-height: 30rem;
      height: auto; } }

.hero__wrapper--small {
  min-height: 12.375rem; }
  @media screen and (min-width: 640px) {
    .hero__wrapper--small {
      min-height: 20rem; } }
  @media screen and (min-width: 1408px) {
    .hero__wrapper--small {
      min-height: 30rem; } }

.hero__picture {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .hero__picture:not(.hero__picture--noGradient):after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.hero__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0; }
  .no-object-fit .hero__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: auto;
    max-width: none; }
    .no-object-fit .hero__image.is-wider {
      height: 100%;
      width: auto; }

.hero__container {
  lost-flex-container: row;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  justify-content: flex-end;
  justify-self: flex-end;
  padding-top: 28px;
  height: 100%;
  width: 1228px;
  max-width: 100%;
  min-height: 16.25rem; }
  @media screen and (min-width: 640px) {
    .hero__container {
      min-height: 30rem; } }

.hero__title {
  order: 2;
  margin-bottom: 0.875rem;
  color: white;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.25rem;
  line-height: 1.25;
  hyphens: auto; }
  .hero__title:last-child {
    margin-bottom: 4.5rem; }
    @media screen and (min-width: 640px) {
      .hero__title:last-child {
        margin-bottom: 5.375rem; } }
  @media screen and (min-width: 640px) {
    .hero__title {
      font-size: 5rem;
      line-height: 1.1; } }
  @media screen and (min-width: 1024px) {
    .hero__title {
      hyphens: none; } }
  @supports not (hyphens: auto) {
    .hero__title {
      word-break: break-word; }
      @media screen and (min-width: 1024px) {
        .hero__title {
          word-break: normal; } } }
  @media screen and (min-width: 640px) {
    .hero__title {
      margin-bottom: 1.75rem; } }
  @media screen and (min-width: 768px) {
    .hero__title {
      lost-column: 0.83333;
      flex-basis: auto; } }
  @media screen and (min-width: 1200px) {
    .hero__title {
      lost-column: 0.66667;
      flex-basis: auto; } }

.hero__sticker-container {
  display: flex;
  order: 1;
  flex-flow: row wrap;
  max-width: 100%;
  margin-bottom: 0.875rem; }

.hero__subtitle {
  order: 3;
  margin-top: -0.875rem;
  margin-bottom: 1.75rem;
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4); }
  @media screen and (min-width: 640px) {
    .hero__subtitle {
      font-size: 2.375rem; } }

.hero__footer {
  display: flex;
  align-items: center;
  order: 3;
  min-height: 3.5625rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3); }
  @media screen and (min-width: 640px) {
    .hero__footer {
      lost-column: 0.5;
      flex-basis: auto; } }
  @media screen and (min-width: 912px) {
    .hero__footer {
      lost-column: 0.66667;
      flex-basis: auto; } }

.hero--full-width-footer .hero__footer {
  lost-column: 1;
  flex-basis: auto; }

.hero--logo-only .hero__wrapper,
.hero--logo-only .hero__container {
  min-height: 0; }
  @media screen and (min-width: 640px) {
    .hero--logo-only .hero__wrapper,
    .hero--logo-only .hero__container {
      min-height: 0; } }

.hero--logo-only .hero__container {
  padding-bottom: 28px; }

.hero--logo-only .hero__title {
  margin-bottom: 0;
  line-height: 0; }

.hero--logo-only .hero__subtitle {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero--logo-only .hero__subtitle {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .hero--logo-only .hero__subtitle {
      clip: rect(1px 1px 1px 1px); } }

.hero--logo-only .hero__logo {
  max-width: 100%;
  max-height: 9.375rem;
  width: auto;
  height: auto;
  margin: 8vw 0; }
  @media screen and (min-width: 640px) {
    .hero--logo-only .hero__logo {
      margin: 56px 0; } }

.ad {
  padding: 14px;
  background-color: #f3f3f4; }

.ad__heading {
  color: #003082;
  margin-bottom: 5px; }

.ad__body {
  font-size: 0.875rem;
  line-height: 1.42857;
  margin-bottom: 10px; }

.arrow-link {
  display: inline-block;
  position: relative;
  font-size: 1rem; }
  .arrow-link > .arrow-link__icon {
    position: absolute;
    margin-top: 0.5rem;
    margin-left: 0.1875rem;
    transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .arrow-link > .arrow-link__text {
    position: relative;
    display: inline-block;
    margin-left: 1.5625rem; }
  .arrow-link:hover > .arrow-link__icon,
  .arrow-link:focus > .arrow-link__icon {
    transform: translateX(0.3125rem); }
  .arrow-link.is-active {
    color: #003082; }

@media screen and (min-width: 912px) {
  .attention-box {
    background-color: #FFFFFF;
    padding: 35px;
    box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15); } }

.attention-box__title {
  margin-bottom: 0.875rem;
  color: #003082;
  font-size: 1.25rem;
  line-height: 1.5; }
  @media screen and (min-width: 640px) {
    .attention-box__title {
      font-size: 1.5rem;
      line-height: 1.25; } }

.attention-box__link {
  box-shadow: 0 1px 0 #0063d3; }
  .attention-box__link:hover {
    box-shadow: 0 1px 0 #003082; }

.attention-box__linklist {
  list-style: none;
  margin: 0;
  padding: 0; }
  .attention-box__linklist li:before {
    content: none; }
  .attention-box__linklist li {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0.5rem;
    margin-right: 1.75rem; }

@media screen and (min-width: 912px) {
  .attention-box--secondary {
    background-color: #f3f3f4;
    border-left: 4px solid #003082; } }

.attention-box--secondary .attention-box__title {
  font-size: 1rem;
  line-height: 1.5625; }
  @media screen and (min-width: 640px) {
    .attention-box--secondary .attention-box__title {
      font-size: 1.125rem;
      line-height: 1.38889; } }

.attention-box--secondary .attention-box__content {
  font-size: 1rem;
  line-height: 1.5625; }
  @media screen and (min-width: 640px) {
    .attention-box--secondary .attention-box__content {
      font-size: 1.125rem;
      line-height: 1.38889; } }
  .attention-box--secondary .attention-box__content > p {
    color: #003082; }

/*

@name book-it
@description book-it; block used for continuing to the order page
@section Organisms
@type UI
@extended false
@uses Button, Price, List

@markup
<section class="book-it">
    <header class="book-it__header">
        <h2 class="visuallyhidden">Bestellen</h2>
        <p class="book-it__price-description">Vanafprijs per persoon:</p>
        <span class="price book-it__price">€ 35</span>
    </header>
    <div class="book-it__box">
        <a href="javascript:void(0)" class="button button--arrow book-it__button">Nu boeken</a>
        <hr class="book-it__seperator" />
        <ul class="list list--checkmark book-it__motivators">
            <li>Veilig betalen met iDeal</li>
            <li>7 dagen per week bereikbaar</li>
            <li>Bespaar 40%</li>
        </ul>
    </div>
</section>

*/
.book-it__header {
  background: #ffc917;
  height: 3.625rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.book-it__price-description {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  margin: 0;
  width: 5rem; }

.book-it__price {
  margin-left: 0.875rem;
  flex: 0 0 auto; }
  @media screen and (min-width: 640px) {
    .book-it__price {
      margin-left: 1.25rem; } }

.book-it__description {
  width: auto;
  margin-bottom: 0;
  padding-right: 0.875rem;
  color: #003082; }
  @media screen and (min-width: 0\0) {
    .book-it__description {
      width: 100%; } }

.book-it__offer {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: #003082;
  white-space: nowrap; }

.book-it__box {
  border: solid 1px #e6e6e9;
  border-top: 0;
  padding: 1.75rem;
  padding-right: 14px;
  padding-left: 14px;
  display: flex;
  flex-flow: column; }
  @media screen and (min-width: 480px) {
    .book-it__box {
      padding-right: 28px;
      padding-left: 28px; } }

.book-it__seperator {
  width: 100%;
  margin: 1.75rem 0; }

.book-it__additional-content {
  border: solid 1px #e6e6e9;
  padding: 1.75rem;
  margin-top: 0.875rem; }

.book-it__additional-content-heading {
  margin-bottom: 0.9375rem; }

.book-it__additional-content-body {
  margin-bottom: 0; }
  .book-it__additional-content-body + .button {
    margin-top: 0.9375rem; }

.book-it--no-price .book-it__header {
  justify-content: space-between; }

.book-it__extra-info, .book-it__disclaimer {
  color: #6a6a7a;
  font-size: 0.875rem; }
  .book-it__extra-info.book-it__disclaimer, .book-it__disclaimer.book-it__disclaimer {
    padding-top: 0.9375rem;
    margin-bottom: 0; }

.book-it__status-title {
  font-size: 1rem;
  line-height: 1.5625;
  margin-bottom: 0px; }

.book-it--temporarilyUnavailable .book-it__status-title {
  color: #db0029; }

.book-it--temporarilyUnavailable .book-it__status-description {
  color: #db0029;
  font-size: 1rem;
  line-height: 1.5625; }

.boxed-content {
  lost-flex-container: row;
  padding-top: 28px;
  padding-bottom: 28px;
  border-top: solid 0.0625rem #e6e6e9; }
  @media screen and (min-width: 640px) {
    .boxed-content {
      padding-top: 56px;
      padding-bottom: 56px; } }

.boxed-content__section {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .boxed-content__section {
      lost-column: 0.5;
      flex-basis: auto; } }
  .boxed-content__section + .boxed-content__section {
    margin-top: 56px; }
    @media screen and (min-width: 640px) {
      .boxed-content__section + .boxed-content__section {
        margin-top: 0; } }
  .boxed-content__section > *:last-child {
    margin-bottom: 0; }

.boxed-content__header {
  margin-bottom: 0.875rem; }
  @media screen and (min-width: 640px) {
    .boxed-content__header {
      margin-bottom: 1.75rem; } }

.button-group {
  display: flex; }

.button-group__button {
  overflow: hidden;
  z-index: 1; }
  .button-group__button + .button-group__button {
    margin-left: -1px; }
    .button-group__button + .button-group__button[disabled] {
      z-index: 0; }

.calendar {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed; }

.calendar--configurator {
  width: calc(100% + 56px + 2px);
  margin-left: -29px;
  margin-top: calc(-29px);
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .calendar--configurator {
      width: 100%;
      margin: 0 0 42px; } }
  @media screen and (min-width: 1024px) {
    .calendar--configurator {
      lost-column: 1;
      flex-basis: auto; } }

.calendar__header-row {
  border: 1px solid #e6e6e9; }

.calendar__body-row:first-child {
  border: 1px solid #e6e6e9; }

.calendar__title {
  height: 3.0625rem;
  color: #070721;
  font-weight: normal;
  vertical-align: middle; }
  @media screen and (min-width: 640px) {
    .calendar__title {
      height: 3.5625rem; } }

.calendar__control {
  height: 3.0625rem;
  color: #0063d3;
  vertical-align: middle; }
  .calendar__control[aria-disabled] {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .calendar__control[aria-disabled] {
        clip: rect(1px 1px 1px 1px); } }
    @supports (-ms-ime-align: auto) {
      .calendar__control[aria-disabled] {
        clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 640px) {
    .calendar__control {
      height: 3.5625rem; } }

.calendar__control--prev,
.calendar__control--next {
  cursor: pointer;
  user-select: none; }

.calendar__header-cell {
  color: #070721;
  font-weight: normal;
  vertical-align: middle;
  height: 1.75rem; }
  @media screen and (min-width: 640px) {
    .calendar__header-cell {
      height: 2.25rem; } }

.calendar__cell {
  vertical-align: middle;
  user-select: none;
  height: 49px; }
  @media screen and (min-width: 640px) {
    .calendar__cell {
      height: 57px; } }
  .calendar__cell:not(.is-empty) {
    border: 1px solid #e6e6e9;
    text-align: center;
    color: #0063d3;
    cursor: pointer; }
  .calendar__cell:focus, .calendar__cell:hover:not(.is-empty) {
    border-color: #0063d3;
    border-style: double; }
  .calendar__cell.is-today {
    position: relative;
    color: #0063d3; }
    .calendar__cell.is-today:after {
      content: '';
      display: block;
      position: absolute;
      width: 0.25rem;
      height: 0.25rem;
      background-color: #0063d3;
      top: 2.25rem;
      left: calc(50% - 0.125rem);
      border-radius: 50%; }
      @media screen and (min-width: 640px) {
        .calendar__cell.is-today:after {
          top: 2.5rem; } }
  .calendar__cell.is-selected {
    background-color: #0063d3;
    color: #FFFFFF; }
    .calendar__cell.is-selected:after {
      background-color: #FFFFFF; }
  .calendar__cell.is-disabled {
    pointer-events: none;
    color: #9c9ca6; }
    .calendar__cell.is-disabled:after {
      background-color: #9c9ca6; }
  .calendar__cell:not(.is-disabled) {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.calendar__wrapper {
  position: relative; }

.caption {
  width: 100%;
  color: #003082;
  font-size: 0.875rem;
  border-bottom: 1px solid #e6e6e9;
  padding: 15px 0; }

.caption__icon {
  margin-right: 0.4375rem; }

.cart-configurator__item {
  position: relative;
  margin: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 1.75rem;
  border: 0; }
  .cart-configurator__item + .cart-configurator__item, h3 + .cart-configurator__item {
    border-top: 1px solid #e6e6e9;
    padding-top: 1.75rem; }
  .cart-configurator__item:last-child {
    padding-bottom: 0; }
    @media screen and (min-width: 912px) {
      .cart-configurator__item:last-child {
        padding-bottom: 1.75rem;
        border-bottom: 1px solid #e6e6e9; } }
  @media screen and (min-width: 912px) {
    .cart-configurator__item {
      border-top: 1px solid #e6e6e9;
      padding-top: 1.75rem; } }

.cart-configurator__item-inner {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  min-height: 2.8125rem; }

.cart-configurator__item-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%; }
  @media screen and (min-width: 480px) {
    .cart-configurator__item-content {
      margin-right: 0.9375rem; } }
  @media screen and (min-width: 640px) {
    .cart-configurator__item-content {
      margin-right: 1.25rem; } }
  @media screen and (min-width: 1024px) {
    .cart-configurator__item-content {
      flex-direction: row;
      align-items: center; } }

.cart-configurator__item-title {
  color: #003082; }
  @media screen and (min-width: 912px) {
    .cart-configurator__item-title {
      width: 100%;
      margin-right: 1.25rem; } }

.cart-configurator__item-price {
  width: 100%;
  color: #003082;
  white-space: nowrap; }
  @media screen and (min-width: 912px) {
    .cart-configurator__item-price {
      width: auto;
      flex-basis: auto; } }

.cart-configurator__item-qty {
  flex: 0 0 2.8125rem;
  margin-right: 0.9375rem; }
  @media screen and (min-width: 640px) {
    .cart-configurator__item-qty {
      margin-right: 1.25rem; } }

.cart-configurator__item-buttons {
  flex: 0 0 auto; }

.cart-configurator__item-button {
  touch-action: manipulation; }

.cart-configurator__item-unavailable {
  width: 5.5625rem;
  color: #db0029;
  font-size: 0.875rem; }

.cart-configurator__note {
  padding-top: 1.75rem;
  border-top: 1px solid #e6e6e9;
  font-size: 0.875rem;
  line-height: 1.42857;
  color: #6a6a7a; }

.cart-ticket {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%; }

.cart-ticket__title {
  width: 100%;
  margin-bottom: 14px; }

.cart-ticket__list {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #003082; }
  .cart-ticket__list li:before {
    content: none; }

.cart-ticket__item {
  display: flex;
  padding-left: 0;
  margin-bottom: 15px; }

.cart-ticket__item-label {
  flex: 1 1 auto;
  margin-right: 0.625rem; }

.cart-ticket__item-value {
  text-align: right;
  flex: 1 0 auto;
  white-space: nowrap; }

.cart-ticket__total {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  margin-bottom: 28px;
  padding-top: 14px;
  padding-left: 0;
  border-top: 2px solid #003082; }

.cart-ticket__total-label {
  flex: 1 1 auto;
  font-size: 1.125rem;
  line-height: 1.66667; }

.cart-ticket__total-value {
  flex: 1 1 5%;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: right;
  white-space: nowrap; }
  @media screen and (min-width: 640px) {
    .cart-ticket__total-value {
      font-size: 1.5rem;
      line-height: 1.25; } }

.cart-ticket__total-rule {
  flex: 0 0 auto;
  font-size: 0.875rem;
  line-height: 1.42857;
  color: #6a6a7a;
  width: 100%;
  margin-top: 10px; }

.cart-ticket__button {
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .cart-ticket__button {
      width: 30%;
      flex: 1 1 auto;
      order: 4; } }
  @media screen and (min-width: 912px) {
    .cart-ticket__button {
      width: 100%;
      order: 0; } }

.cart-ticket__motivators {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .cart-ticket__motivators {
      width: 70%;
      flex: 1 1 auto;
      padding-right: 28px;
      order: 3; } }
  @media screen and (min-width: 912px) {
    .cart-ticket__motivators {
      width: 100%;
      order: 0;
      padding-right: 0; } }

/*
@name Collapsible list
@description
An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.

@section Molecules
@type UI
@extended false

@markup
<ul class="list collapsibleList is-collapsible">
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <div class="collapsibleList__body">Body</section>
    </li>
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <section class="collapsibleList__body">Body</section>
    </li>
    <li class="collapsibleList__item">
        <h3 class="collapsibleList__header">Header</h3>
        <section class="collapsibleList__body">Body</section>
    </li>
</ul>
*/
.collapsibleList {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.collapsibleList__header {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 1.0625rem 2.1875rem 1.0625rem 0;
  margin: 0;
  font-size: 1rem;
  color: #0063d3; }
  .collapsibleList__header:hover {
    color: #003082; }

.collapsibleList__item {
  border-bottom: 0.0625rem solid #e6e6e9;
  list-style: none;
  margin: 0;
  padding: 0; }
  .collapsibleList__item:first-child {
    border-top: 0.0625rem solid #e6e6e9; }
  .collapsibleList__item:before {
    content: none; }

.collapsibleList__icon {
  display: none;
  position: absolute;
  right: 5px;
  top: calc(50% - 8px); }

.collapsibleList__body {
  padding-bottom: 1.0625rem; }

.is-collapsible .collapsibleList__body {
  display: none; }
  .is-collapsible .collapsibleList__body.is-expanded {
    display: block; }

.is-collapsible .collapsibleList__icon {
  display: block; }

/*
@name Collapsible text
@description
An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.

@section Molecules
@type UI
@extended false

@markup
<div class="list collapsibleText is-collapsible">
    <h3 class="collapsibleText__header">Header</h3>
    <section class="collapsibleText__body">Body</section>
</div>
*/
.collapsibleText {
  width: 100%; }

.collapsibleText__header {
  position: relative;
  display: none;
  margin-bottom: 0.875rem;
  font-size: 1rem;
  color: #0063d3;
  cursor: pointer; }
  .collapsibleText__header:hover {
    color: #003082; }

.collapsibleText__icon {
  margin-left: 0.5rem; }

.collapsibleText__body {
  padding-bottom: 1.0625rem; }

.is-collapsible .collapsibleText__header {
  display: block; }

.is-collapsible .collapsibleText__body {
  display: none; }
  .is-collapsible .collapsibleText__body.is-expanded {
    display: block; }

.component-overlay {
  background-color: rgba(243, 243, 244, 0.88);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.component-overlay__loader {
  position: absolute;
  width: 56px;
  height: 56px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.component-overlay__response {
  display: flex;
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.component-overlay__response-icon {
  flex: 0 0 auto;
  margin-right: 14px; }

.component-overlay__response-body {
  flex: 1 1 auto; }

.component-overlay--alert {
  color: #db0029; }

.component-overlay--calendar {
  width: calc(100% + 56px + 2px);
  margin-left: -29px;
  height: calc(100% - 77px);
  top: 77px; }
  @media screen and (min-width: 640px) {
    .component-overlay--calendar {
      width: 100%;
      margin-left: 0;
      top: 93px;
      height: calc(100% - 93px); } }

.contact__section {
  lost-flex-container: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 1.75rem; }
  .contact__section:last-child {
    margin-bottom: 0; }

.contact__title {
  margin-bottom: 1.75rem;
  width: 100%; }

.contact__icon {
  margin-right: 1rem;
  flex-shrink: 0; }

.contact__subtitle {
  font-size: 1rem;
  margin: 0; }

.contact__description {
  margin: 0;
  color: #6a6a7a;
  font-size: 0.875rem;
  line-height: 1.42857; }

.contact__button[disabled] {
  box-shadow: none;
  background: none;
  color: #6a6a7a; }
  .contact__button[disabled]:focus, .contact__button[disabled]:hover {
    color: #6a6a7a;
    box-shadow: none; }

.contact__status {
  display: none;
  font-weight: normal;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.42857; }
  .contact__status::before {
    content: '\02013';
    margin-right: 0.3125rem; }

@media screen and (min-width: 640px) {
  .contact--inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; } }

@media screen and (min-width: 640px) {
  .contact--inline .contact__section {
    lost-column: 1/2 2;
    flex-basis: auto; } }

@media screen and (min-width: 912px) {
  .contact--inline .contact__section {
    lost-column: 1/3 3;
    flex-basis: auto;
    margin-bottom: 0; } }

.cta-box {
  background-color: #FFFFFF;
  padding: 20px 30px 30px;
  box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15); }
  .cta-box > * + * {
    margin-top: 20px; }

.cta-box__graphic .ns-blue {
  fill: #003082; }

.cta-box__graphic .ns-yellow {
  fill: #ffc917; }

.cta-box__text {
  margin-bottom: 0;
  color: #003082;
  font-size: 1rem;
  line-height: 1.5625; }
  @media screen and (min-width: 640px) {
    .cta-box__text {
      font-size: 1.125rem;
      line-height: 1.38889; } }

.darkener {
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: -100%;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  background-color: rgba(7, 7, 33, 0.3);
  cursor: pointer;
  backface-visibility: hidden; }
  .darkener.fade-enter-active, .darkener.fade-leave-active {
    transition: opacity 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .darkener.fade-enter, .darkener.fade-leave-to {
    opacity: 0; }
  .darkener.is-visible {
    left: 0; }

/*
@name Definition list
@description A list with items, and each item has a title and description

@section Molecules
@category Lists
@type UI
@extended false

@markup
<dl class="definition-list {{modifier_class}}">
    <dt class="definition-list__title">Title 1</dt>
    <dt class="definition-list__content"><p>Content<p></dt>
    <dt class="definition-list__title">Title 2</dt>
    <dt class="definition-list__content">Content with a <a href="#">link</a> inside it.</dt>
</dl>
*/
.definition-list {
  margin-bottom: 0;
  margin-top: 0.875rem; }
  @media screen and (min-width: 480px) {
    .definition-list {
      display: flex;
      flex-flow: row wrap; }
      .definition-list .definition-list__title,
      .definition-list .definition-list__content {
        flex: 1 1 50%; } }

.definition-list__title--spaced {
  margin-bottom: 0.15625rem; }

.form-radiobutton {
  position: relative;
  vertical-align: top; }
  .form-radiobutton + .form-radiobutton {
    margin-top: 14px; }

.form-radiobutton__label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-left: 1.75rem; }
  @media screen and (min-width: 16px) {
    .form-radiobutton__label {
      width: 15rem; } }
  @media screen and (min-width: 640px) {
    .form-radiobutton__label {
      width: 50%; } }
  .form-radiobutton__label:focus {
    outline: none; }

.form-radiobutton__input {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-radiobutton__input {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .form-radiobutton__input {
      clip: rect(1px 1px 1px 1px); } }
  .form-radiobutton__input + .form-radiobutton__label:before {
    position: absolute;
    left: 0;
    top: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #FFFFFF;
    border: 0.0625rem solid #b5b5bc;
    border-radius: 50%;
    padding: 0;
    content: '';
    cursor: pointer; }
  .form-radiobutton__input + .form-radiobutton__label:after {
    position: absolute;
    left: 0.3125rem;
    top: 0.4375rem;
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 0;
    line-height: 0;
    content: '';
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; }
  .form-radiobutton__input:checked + .form-radiobutton__label {
    color: #003082; }
    .form-radiobutton__input:checked + .form-radiobutton__label:after {
      background-color: #0060ba; }
  .form-radiobutton__input:focus + .form-radiobutton__label:before {
    outline: 0.0625rem dotted;
    outline-offset: 0.3125rem; }

@media screen and (min-width: 0\0) {
  .form-radiobutton__label-text,
  .form-radiobutton__label-add-text {
    flex-shrink: 1;
    width: 50%; } }

@media screen and (min-width: 0\0) {
  .form-radiobutton__label-add-text {
    text-align: right; } }

.form-radiobutton__label-add-text {
  position: relative;
  top: -0.0625rem;
  color: #003082; }

/*

@name Expander
@description Component that equalizes heights between two DOM elements and applies a fade-out effect on the target.
@section components

@modifier .expander--280-220 - Modifier that sets min/maxHeight on mobile to 280 and 220 on desktop

@markup
<div class="expander {{modifier_class}}">
    <div class="expander__target"></div>
</div>

*/
.expander__target {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .is-expandable .expander__target:after {
    content: '';
    position: absolute;
    height: 6.25rem;
    width: 100%;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%); }
  .expander__target.is-expanded {
    max-height: 3000px !important; }
    .expander__target.is-expanded:after {
      display: none; }

.expander__button {
  margin-top: 1.75rem; }

.gallery {
  position: relative;
  width: 100%; }

.gallery__inner {
  position: relative; }

.gallery__carousel {
  position: relative;
  background: #f3f3f4;
  overflow: hidden;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  transition: max-height 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);
  max-height: 0; }
  .gallery--thema .gallery__carousel {
    margin-left: initial;
    margin-right: initial; }
  .touch .gallery__carousel {
    overflow-y: auto; }
    @media screen and (min-width: 1024px) {
      .touch .gallery__carousel {
        overflow-y: hidden; } }
  .gallery__carousel.has-nav:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.4) 100%);
    z-index: 1;
    pointer-events: none; }
  .gallery__carousel.is-ready {
    max-height: 37.5rem; }
  .gallery__carousel.is-draggable {
    cursor: move;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important; }
  .gallery__carousel.is-dragging {
    cursor: move;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important; }
  @media screen and (min-width: 640px) {
    .gallery__carousel {
      margin-left: 0;
      margin-right: 0; } }

.gallery__list {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap; }
  .gallery__list li:before {
    content: none; }

.gallery__item {
  flex: 0 0 auto;
  width: 100%;
  padding-left: 0; }

.gallery__picture {
  display: block;
  margin: 0; }
  .gallery--thema .gallery__picture {
    height: 100%;
    max-height: 9.375rem;
    overflow: hidden; }
    @media screen and (min-width: 640px) {
      .gallery--thema .gallery__picture {
        max-height: 18.75rem; } }

.gallery__image {
  display: block; }
  .gallery--thema .gallery__image {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%; }

.gallery__nav {
  position: absolute;
  bottom: 0.9375rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  z-index: 2; }

.gallery__nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap; }
  .gallery__nav-list li:before {
    content: none; }

.gallery__nav-item {
  padding-left: 0;
  flex: 0 0 auto;
  margin-right: 0.625rem; }
  .gallery__nav-item:only-child, .gallery__nav-item:last-child {
    margin-right: 0; }

.gallery__nav-link {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15);
  position: relative; }
  .gallery__nav-link:after {
    content: '';
    position: absolute;
    width: calc(100% + 0.375rem + 0.375rem);
    height: calc(100% + 0.625rem + 0.625rem);
    left: -0.375rem;
    top: -0.625rem; }
  .no-touch .gallery__nav-link:hover,
  .is-active .gallery__nav-link {
    background-color: #FFFFFF; }

@media screen and (min-width: 1200px) {
  .gallery__caption {
    display: none; } }

.no-touch .gallery__caption {
  display: none; }

.gallery__button {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: #003082;
  text-align: center; }
  .no-touch .gallery__button {
    display: block; }
  .gallery__button.is-hidden {
    display: none !important; }
  @media screen and (min-width: 1200px) {
    .touch .gallery__button {
      display: block; } }

.gallery__button--next {
  right: -1.25rem; }

.gallery__button--prev {
  left: -1.25rem; }
  .gallery__button--prev .gallery__button-icon {
    margin-left: -2px;
    transform: rotate(180deg); }

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 28px;
  width: 100%;
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .grid {
      grid-template-columns: repeat(12, 1fr); } }
  .grid li:before {
    display: none; }
  .no-cssgrid .grid {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 28px);
    margin: 0 -0.875rem; }

.no-cssgrid .grid__item {
  width: 100%;
  margin: 0 0.875rem 1.75rem; }

@media screen and (min-width: 640px) {
  .grid__item-m-col-6 {
    grid-column: span 6; }
    .no-cssgrid .grid__item-m-col-6 {
      width: calc(100%/2 - 28px); } }

@media screen and (min-width: 640px) {
  .grid__item-m-col-12 {
    grid-column: span 12; }
    .no-cssgrid .grid__item-m-col-12 {
      margin-top: 0;
      margin-bottom: 14px; } }

@media screen and (min-width: 912px) {
  .grid__item-l-col-4 {
    grid-column: span 4; }
    .no-cssgrid .grid__item-l-col-4 {
      width: calc(100%/3 - 28px); } }

@media screen and (min-width: 1200px) {
  .grid__item-xxl-col-4 {
    grid-column: span 4; }
    .no-cssgrid .grid__item-xxl-col-4 {
      width: calc(100%/3 - 28px); } }

@media screen and (min-width: 1200px) {
  .grid__item-xxl-col-8 {
    grid-column: span 8; }
    .no-cssgrid .grid__item-xxl-col-8 {
      margin: 0 0.875rem 1.75rem;
      width: calc(100%/3 - 28px); } }

@media screen and (min-width: 1200px) {
  .grid__item-xxl-row-2 {
    grid-row: span 2; } }

/*

@name icon-content
@description icon-content; block that has a list of icons and texts
@section Organisms
@type UI
@extended false

@markup
<section class="icon-content">
    <h2 class="icon-content__title">Inbegrepen</h2>
    <ul class="icon-content__list">
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#dagretour"></use>
            </svg>
            <p class="icon-content__description">Dagretour naar keuze</p>
        </li>
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#voucher"></use>
            </svg>
            <p class="icon-content__description">Voucher voor koffie en macaron XXL bij Grand Café Krasnapolsky</p>
        </li>
        <li class="icon-content__item">
            <svg class="icon icon--nsBlue icon--m icon-content__icon" viewBox="0 0 32 32" aria-hidden="true">
                <use xlink:href="#schedule"></use>
            </svg>
            <p class="icon-content__description">Te koop t/m 30 aug, geldig t/m 31 sep</p>
        </li>
    </ul>
</section>

*/
.icon-content {
  width: 100%; }

.icon-content__title {
  margin-bottom: 1.75rem; }

.icon-content__list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .icon-content__list li:before {
    content: none; }
  @media screen and (min-width: 640px) {
    .icon-content__list {
      display: flex;
      flex-flow: row wrap; } }

.icon-content__item {
  margin-bottom: 1.5rem;
  display: flex;
  padding: 0; }
  .icon-content__item:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 16px) and (max-width: 639.9px) {
    .icon-content__item {
      align-items: center; } }
  @media screen and (min-width: 640px) {
    .icon-content__item {
      lost-column: 1/2 2;
      flex-basis: auto;
      flex-direction: column;
      align-items: left; } }
  @media screen and (min-width: 912px) {
    .icon-content__item {
      lost-column: 0.33333;
      flex-basis: auto;
      margin-bottom: 0; } }

.icon-content__icon {
  flex: 0 0 auto;
  margin-right: 0.9375rem; }
  @media screen and (min-width: 640px) {
    .icon-content__icon {
      margin-right: 0;
      margin-bottom: 0.5rem; } }

.icon-content__description {
  margin: 0;
  color: #39394d; }

.icon-content__icon--padded {
  padding: 0.25rem; }

.icon-content--icon-in-line .icon-content__item {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: auto; }
  @media screen and (min-width: 640px) {
    .icon-content--icon-in-line .icon-content__item {
      align-items: center; } }

.icon-content--icon-in-line .icon-content__icon {
  margin-right: 14px; }

.icon-content--icon-in-line .icon-content__description {
  font-size: 0.875rem; }
  @media screen and (min-width: 640px) {
    .icon-content--icon-in-line .icon-content__description {
      font-size: 1rem; } }

.icon-content--icon-in-line .icon--ml #schedule {
  width: 48px;
  height: 41px; }

.icon-content--centered {
  width: 100%;
  margin: 0 0 1.75rem; }
  @media screen and (min-width: 640px) {
    .icon-content--centered {
      padding: 1.75rem 0; } }
  @media screen and (min-width: 768px) {
    .icon-content--centered .icon-content__list {
      justify-content: center; } }
  .icon-content--centered .icon-content__item {
    margin: 14px 0; }
    @media screen and (min-width: 640px) {
      .icon-content--centered .icon-content__item {
        margin: 14px 56px 14px 0; }
        .icon-content--centered .icon-content__item:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 768px) {
      .icon-content--centered .icon-content__item {
        margin: 0 56px 0 0; } }

.icon-text {
  position: relative;
  display: inline-block;
  padding-left: 33px; }

.icon-text__text {
  display: inline;
  color: #003082; }

.icon-text__icon {
  position: absolute;
  top: 0;
  left: 0; }

.information-bar {
  display: flex;
  flex-direction: column;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem; }
  @media screen and (min-width: 640px) {
    .information-bar {
      display: block; } }
  .information-bar > .list--inline {
    width: 100%;
    margin-top: 0.875rem; }
    @media screen and (min-width: 912px) {
      .information-bar > .list--inline {
        width: auto;
        margin-top: 0;
        margin-left: 1.75rem; } }

/*
@name Intro
@description Represents the large header on top of a page.
@section components

@modifier .intro--illustrated - Used to style intro's containing an illustration on desktop and tablet

@markup
<div class="intro {{modifier_class}}">
    <div class="intro__section">
        <div class="intro__title">Title</div>
        <div class="intro__content">Content</div>
    </div>
    <div class="intro__section">
        <img class="intro__illustration" loading="eager">
    </div>
</div>
*/
.intro {
  lost-flex-container: column;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1.75rem; }

.intro__section {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .intro__section {
      lost-column: 0.5;
      flex-basis: auto; } }

.intro__section--m-visible {
  display: none; }
  @media screen and (min-width: 768px) {
    .intro__section--m-visible {
      display: block; } }

.intro__title:not(:last-child) {
  margin-bottom: 0.875rem; }

.intro__subtitle {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 0.875rem;
  color: #003082; }
  @media screen and (min-width: 640px) {
    .intro__subtitle {
      font-size: 1.5rem;
      line-height: 1.25; } }
  @media screen and (min-width: 640px) {
    .intro__subtitle {
      margin-top: 0.4375rem;
      margin-bottom: 0; } }

.intro__content {
  color: #39394d; }

.intro__content-text {
  margin-bottom: 0; }

.intro__illustration {
  display: none; }
  @media screen and (min-width: 768px) {
    .intro__illustration {
      display: block;
      width: 100%; } }

.intro--illustrated {
  align-items: center; }
  .intro--illustrated .intro__section {
    lost-column: 1;
    flex-basis: auto; }
    @media screen and (min-width: 768px) {
      .intro--illustrated .intro__section {
        lost-column: 0.5;
        flex-basis: auto; } }

.link {
  position: relative; }

/*
@name List
@description A list with items, and each item has a square in front of it

@section Molecules
@category Lists
@type UI
@extended false

@modifier .list--arrow - chevrons instead of squares
@modifier .list--checkmark - checkmark instead of squares
@modifier .list--grey - grey links and list item images
@modifier .list--inline - horizontal list of items
@modifier .list--spaced - extra margin between items
@modifier .list--unstyled - unstyled list

@markup
<ul class="list {{modifier_class}}">
    <li><a href="javascript:void(0)">Trein + Uitje</a></li>
    <li><a href="javascript:void(0)">Trein + Hotel</a></li>
    <li><a href="javascript:void(0)">Cadeaukaart</a></li>
</ul>
*/
.list {
  margin-bottom: 0; }

.list--arrow .list__item {
  padding: 0; }
  .list--arrow .list__item:before {
    content: none; }

.list--arrow.list--inline .list__item {
  margin-bottom: 0; }

.list--checkmark .list__item {
  background: no-repeat left 5px;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016.940954%2014.35%22%3E%3Cpath%20fill%3D%22rgb%280%2C%20154%2C%2066%29%22%20d%3D%22M16.841.15c-.2-.2-.5-.2-.7%200l-11.8%2011.8-2.5-4.9c-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.4%201.3l3.3%206.4%2013.5-13.5c0-.1%200-.5-.1-.7z%22%2F%3E%3C%2Fsvg%3E);
  background-size: 0.875rem 0.75rem;
  padding-left: 1.75rem; }
  .list--checkmark .list__item:before {
    content: none; }

.list--small .list__item {
  font-size: 0.875rem;
  line-height: 1.42857; }

.list--inline {
  lost-flex-container: row;
  display: inline-flex; }
  .list--inline .list__item {
    margin-right: 1.75rem; }
    .list--inline .list__item:last-child {
      margin-right: 0; }

.list--grey .list__item {
  color: #6a6a7a; }

.list--grey a {
  color: #6a6a7a; }
  .list--grey a:hover, .list--grey a:focus {
    color: #070721; }

.list--grey.list--arrow a:hover, .list--grey.list--arrow a:focus {
  color: #070721; }

.list--spaced .list__item {
  margin-bottom: 0.3125rem; }

.list--spaced-1 .list__item {
  margin-bottom: 0.3125rem; }

.list--spaced-2 .list__item {
  margin-bottom: 0.625rem; }

.list--spaced-3 .list__item {
  margin-bottom: 0.9375rem; }

.list--spaced-4 .list__item {
  margin-bottom: 1.25rem; }

.list--spaced-5 .list__item {
  margin-bottom: 1.5625rem; }

.list--spaced-6 .list__item {
  margin-bottom: 1.875rem; }

.list--spaced-7 .list__item {
  margin-bottom: 2.1875rem; }

.list--spaced-8 .list__item {
  margin-bottom: 2.5rem; }

.list--spaced-9 .list__item {
  margin-bottom: 2.8125rem; }

.list--spaced-10 .list__item {
  margin-bottom: 3.125rem; }

.list--spaced .list__item:last-child,
.list--spaced-2 .list__item:last-child,
.list--spaced-3 .list__item:last-child,
.list--spaced-4 .list__item:last-child,
.list--spaced-5 .list__item:last-child {
  margin-bottom: 0; }

.list--reset > li {
  padding-left: inherit; }
  .list--reset > li:before {
    content: none; }

/*
@name map
@description Google maps and google maps info window styling.

@section Molecules
@category google map
@type UI
@extended false

@modifiers

@markup

<div ref="map-container" class="map"></div>

*/
/* info-window */
/* info-window-triangle */
.map {
  width: 100%;
  height: 15rem; }
  @media screen and (min-width: 640px) {
    .map {
      height: 30rem; } }
  @media screen and (min-width: 912px) {
    .map {
      height: 32.75rem; } }

.map__info-window {
  font-family: "NS Sans", "Segoe UI", Myriad, Verdana, sans-serif;
  background-color: #FFFFFF;
  padding: 20px;
  font-size: 0.875rem;
  line-height: 1.42857;
  width: 14.875rem;
  overflow: visible !important;
  box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15);
  animation: fadeIn 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);
  /* this is the close image of the info-window.
       it comes from the infobox utility.
       it dont have a class name and it is difficult to set a class name on it on the right way.
       that is why we use here an img element.
    */ }
  .map__info-window:before, .map__info-window:after {
    border: 0 solid transparent;
    border-left-width: 8px;
    border-right-width: 8px;
    margin-left: -4px;
    left: 50%;
    content: '';
    display: inline-block;
    position: absolute;
    border-top-width: 14px;
    bottom: -13px;
    border-top-color: #FFFFFF; }
  .map__info-window:before {
    border-top-width: 15px;
    bottom: -17px;
    border-top-color: rgba(7, 7, 33, 0.15); }
  .map__info-window img {
    width: 1rem; }

.map__info-window-title {
  color: #003082;
  font-weight: 700;
  margin-bottom: 7px; }

.map__info-window-content {
  margin-bottom: 0; }

.navigation__list {
  z-index: 30;
  cursor: default; }
  .navigation__list > li {
    padding-left: inherit; }
    .navigation__list > li:before {
      content: none; }
  @media screen and (min-width: 0) and (max-width: 911.9px) {
    .navigation__list {
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      right: -100%;
      background-color: #FFFFFF;
      transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
      .navigation__list.is-open {
        transform: translateX(-100%); } }
  @media screen and (min-width: 640px) and (max-width: 911.9px) {
    .navigation__list {
      max-width: 17.5rem;
      right: -18.125rem; }
      .navigation__list.is-open {
        transform: translateX(-18.125rem); } }
  @media screen and (min-width: 912px) {
    .navigation__list {
      display: inline; } }

@media screen and (min-width: 0) and (max-width: 911.9px) {
  .navigation__list-item:first-child {
    margin-top: 5.5rem; } }

@media screen and (min-width: 912px) {
  .navigation__list-item {
    display: inline; }
    .navigation__list-item:not(:last-of-type) {
      margin-right: 0.4375rem; } }

.navigation__list-item--close {
  position: absolute;
  top: 1.09375rem;
  right: 1.75rem; }
  @media screen and (min-width: 912px) {
    .navigation__list-item--close {
      position: absolute;
      left: -999em;
      clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .navigation__list-item--close {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 912px) {
      @supports (-ms-ime-align: auto) {
        .navigation__list-item--close {
          clip: rect(1px 1px 1px 1px); } } }

.navigation__link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.4375rem 0;
  padding: 0.4375rem 1.75rem;
  color: #003082; }
  @media screen and (min-width: 16px) and (max-width: 911.9px) {
    .navigation__link:focus {
      outline: 1px dotted #6a6a7a; } }
  @media screen and (min-width: 912px) {
    .navigation__link {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0.4375rem 0.4375rem; }
      .navigation__link:after {
        content: '';
        position: absolute;
        width: calc(100% - 0.4375rem * 2);
        height: 0.1875rem;
        bottom: -0.75rem;
        left: 0.4375rem;
        background-color: #003082;
        transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);
        transform: scaleX(0);
        transform-origin: 0; }
      .navigation__link:hover:after, .navigation__link:focus:after {
        transform: scaleX(1); } }

.navigation__link-text {
  width: fit-content; }
  @media screen and (min-width: 912px) {
    .navigation__link-text {
      display: inline-block;
      transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
      .navigation__link:hover .navigation__link-text,
      .navigation__link:focus .navigation__link-text {
        transform: translateY(-0.125rem); } }

.navigation__link-icon {
  transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .navigation__link:hover .navigation__link-icon {
    transform: translateX(0.625rem); }
  @media screen and (min-width: 912px) {
    .navigation__link-icon {
      display: none; } }

.navigation__button {
  right: -1.25rem;
  height: 3.625rem;
  width: 3.625rem;
  overflow: hidden; }
  @media screen and (min-width: 912px) {
    .navigation__button {
      display: none; } }
  .notification-bar + .site-header .navigation__button--close {
    position: fixed;
    top: 1.125rem;
    right: 0.125rem; }

.navigation__button--close {
  z-index: 31; }

/*

@name notification-bar
@description notification-bar; bar to display a message above or below the header menu
@section Organisms
@type UI
@extended false

@markup
<aside class="notification-bar notification-bar--alert">
    <div class="container notification-bar__container">
        <div class="notification-bar__icon-wrapper">
            <svg class="icon icon--nsWhite icon--sm notification-bar__icon" viewBox="0 0 16 16" aria-hidden="true">
                <use xlink:href="#alert"></use>
            </svg>
        </div>
        <div class="notification-bar__content">
            <p class="notification-bar__description">
                Landelijke storing OV-chipkaart, in- en uitchecken is tijdelijk niet mogelijk
            </p>
            <span class="notification-bar__links">
                <a href="javascript:void(0)" class="notification-bar__link">Geef feedback</a>
                <a href="javascript:void(0)" class="notification-bar__link">Geef feedback</a>
            </span>
        </div>
    </div>
</aside>
*/
.notification-bar {
  background-color: #003082;
  color: #FFFFFF; }

.notification-bar__container {
  min-height: 4.125rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start; }

.notification-bar__icon-wrapper {
  flex: 0 0 auto; }

.notification-bar__icon {
  margin-right: 0.875rem; }

.notification-bar__content {
  flex: 1 1 auto; }

.notification-bar__description {
  font-size: 0.875rem;
  line-height: 1.42857;
  margin-right: 0.875rem;
  margin-bottom: 5px; }
  @media screen and (min-width: 640px) {
    .notification-bar__description {
      font-size: 1rem;
      line-height: 1.5625; } }
  @media screen and (min-width: 640px) {
    .notification-bar__description {
      display: inline-block;
      margin-bottom: 0;
      width: auto; } }

.notification-bar__links {
  display: block; }
  @media screen and (min-width: 640px) {
    .notification-bar__links {
      display: inline-block; } }

.notification-bar__link {
  display: block;
  margin-right: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.42857;
  text-decoration: underline;
  font-weight: 700;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .notification-bar__link + .notification-bar__link {
    margin-top: 5px; }
  @media screen and (min-width: 640px) {
    .notification-bar__link {
      font-size: 1rem;
      line-height: 1.5625; } }
  .notification-bar__link:hover {
    color: #FFFFFF;
    text-decoration: none; }
  @media screen and (min-width: 640px) {
    .notification-bar__link {
      display: inline; }
      .notification-bar__link + .notification-bar__link {
        margin-top: 0; } }

.notification-bar--alert {
  background-color: #db0029; }

.notification-bar--reference {
  background-color: #ffc917;
  color: #003082; }
  @media screen and (min-width: 640px) {
    .notification-bar--reference {
      animation: fadeInDown 0.9s cubic-bezier(0.7, 0.27, 0.27, 1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0; } }
  .notification-bar--reference .notification-bar__container {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    @media screen and (min-width: 640px) {
      .notification-bar--reference .notification-bar__container {
        align-items: center; } }
  .notification-bar--reference .notification-bar__icon-wrapper {
    display: none; }
    @media screen and (min-width: 640px) {
      .notification-bar--reference .notification-bar__icon-wrapper {
        display: block; } }
  .notification-bar--reference .notification-bar__description {
    font-size: 1rem;
    line-height: 1.5625; }
    @media screen and (min-width: 640px) {
      .notification-bar--reference .notification-bar__description {
        font-size: 1.125rem;
        line-height: 1.38889; } }
    .notification-bar--reference .notification-bar__description > a:not([class]), .notification-bar--reference .notification-bar__description a[class=""] {
      display: inline;
      color: #003082;
      margin-right: 0;
      white-space: nowrap;
      text-decoration: none;
      box-shadow: 0 1px 0 #003082;
      font-weight: 700; }

.pane {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15); }

.pane__header {
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: flex-end;
  position: relative;
  padding: 0.875rem 1.75rem;
  min-height: 11.25rem; }
  @media screen and (min-width: 912px) {
    .pane__header {
      padding: 1.75rem; } }

.pane__header--desktop-only {
  display: none; }
  @media screen and (min-width: 912px) {
    .pane__header--desktop-only {
      display: block; } }

.pane__header-picture {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .pane__header-picture:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.pane__header-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  min-width: 100%;
  min-height: 1px;
  max-width: none; }

.pane__title {
  position: relative;
  margin-bottom: 0;
  color: #FFFFFF;
  font-weight: 700;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.pane__subtitle {
  position: relative;
  color: #FFFFFF;
  font-weight: normal;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.4); }

.pane__heading {
  margin-bottom: 0.875rem; }

.pane__ad {
  margin-bottom: 28px; }

.pane__content {
  padding: 1.75rem; }
  .pane__content p:last-child {
    margin-bottom: 0; }

.pane__content-title {
  display: none;
  font-weight: normal;
  margin-bottom: 1.75rem; }
  @media screen and (min-width: 912px) {
    .pane__content-title {
      display: block; } }

.pane__content-offset {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .pane__content-offset {
      margin-bottom: 42px; } }
  @media screen and (min-width: 1024px) {
    .pane__content-offset {
      lost-column: 0.875;
      flex-basis: auto; } }
  @media screen and (min-width: 1200px) {
    .pane__content-offset {
      lost-column: 0.75;
      flex-basis: auto; } }

/* modifiers */
.pane--large .pane__header,
.pane--large .pane__content {
  padding: 1.75rem; }
  @media screen and (min-width: 912px) {
    .pane--large .pane__header,
    .pane--large .pane__content {
      padding: 2.625rem; } }

.pane--desktop-heading-only .pane__title {
  font-size: 2.25rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .pane--desktop-heading-only .pane__title {
      font-size: 3rem;
      line-height: 1.25; } }

@media screen and (min-width: 912px) {
  .pane--desktop-heading-only .pane__header {
    padding-bottom: 0;
    min-height: 0; }
  .pane--desktop-heading-only .pane__content {
    padding-top: 28px; }
  .pane--desktop-heading-only .pane__header-picture {
    display: none; }
  .pane--desktop-heading-only .pane__title {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: normal;
    color: #003082;
    text-shadow: none; } }
  @media screen and (min-width: 912px) and (min-width: 640px) {
    .pane--desktop-heading-only .pane__title {
      font-size: 2.375rem;
      line-height: 1.18421; } }

@media screen and (min-width: 912px) {
  .pane--desktop-heading-only .pane__subtitle {
    display: none; } }

.pane--padded {
  padding: 30px; }
  @media screen and (min-width: 480px) {
    .pane--padded {
      padding: 50px; } }
  .pane--padded :last-child {
    margin-bottom: 0; }
  .pane--padded [class*="h-mb-"]:last-child {
    margin-bottom: 0 !important; }

.pane--primary {
  background-color: #003082;
  color: #FFFFFF; }

.pane--flat {
  box-shadow: none; }

.pane--content-compact .pane__content {
  padding: 0; }
  @media screen and (min-width: 640px) {
    .pane--content-compact .pane__content {
      lost-column: 0.66667;
      flex-basis: auto;
      lost-offset: 0.16667; } }
  @media screen and (min-width: 912px) {
    .pane--content-compact .pane__content {
      lost-column: 0.5;
      flex-basis: auto;
      lost-offset: 0.25; } }

.rich-content a:not([class]), .rich-content a[class=""] {
  box-shadow: 0 1px 0 #0063d3; }
  .rich-content a:not([class]):hover, .rich-content a[class=""]:hover {
    box-shadow: 0 1px 0 #003082; }

.rich-content > p {
  margin-bottom: 15px;
  color: #39394d; }
  .rich-content > p + .rich-content > p, .rich-content > p:last-child {
    margin-bottom: 0; }

.rich-content > ol:last-child, .rich-content > ul:last-child {
  margin-bottom: 0; }

.rich-content > h1,
.rich-content > h2,
.rich-content > h3 {
  margin-bottom: 0.625rem; }

.rich-content > h1 {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (min-width: 640px) {
    .rich-content > h1 {
      font-size: 3rem;
      line-height: 1.25; } }

.rich-content > h2 {
  font-size: 1.75rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .rich-content > h2 {
      font-size: 1.875rem;
      line-height: 1.33333; } }

.rich-content > h3 {
  font-size: 1.125rem;
  line-height: 1.38889;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (min-width: 640px) {
    .rich-content > h3 {
      font-size: 1.25rem;
      line-height: 1.25; } }

.rich-content > h4,
.rich-content > h5,
.rich-content > h6 {
  font-size: 1rem;
  line-height: 1.5625;
  font-weight: 700;
  margin-bottom: 0.625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (min-width: 640px) {
    .rich-content > h4,
    .rich-content > h5,
    .rich-content > h6 {
      font-size: 1.125rem;
      line-height: 1.38889; } }

@media screen and (min-width: 640px) {
  .rich-content--large {
    font-size: 1.125rem;
    line-height: 1.55556; } }

.rich-content--thema {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .rich-content--thema {
      lost-column: 0.66667;
      flex-basis: auto; } }

.section-title {
  margin-bottom: 1.75rem; }

.section {
  padding-top: 1.75rem; }

.section--contrast {
  background: #e6e6e9; }

.section--compressed {
  padding-top: 0.875rem; }

@media screen and (min-width: 480px) {
  .section--compressed {
    padding-top: 1.75rem; } }

.section--spaced {
  padding-top: 42px;
  padding-bottom: 42px; }
  @media screen and (min-width: 912px) {
    .section--spaced {
      padding-top: 98px;
      padding-bottom: 98px; } }

.showcase {
  width: 100%;
  margin-bottom: 1.75rem; }
  .showcase li:before {
    display: none; }

.showcase__item {
  margin: 0 0.875rem 1.75rem; }

@media screen and (min-width: 640px) {
  .showcase--one-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
    .showcase--one-two .showcase__item {
      width: calc(50% - 28px);
      margin: 0.875rem 0.875rem 0; }
      .showcase--one-two .showcase__item:first-child {
        width: 100%;
        margin-top: 0;
        margin-bottom: 14px; } }

@media screen and (min-width: 1200px) {
  .showcase--one-two {
    flex-direction: column;
    align-items: center;
    height: 34.25rem; }
    .showcase--one-two .showcase__item {
      width: calc((100% / 3) - 28px);
      height: calc((100% / 2) - 14px);
      min-height: initial;
      margin: 0; }
      .showcase--one-two .showcase__item:first-child {
        width: calc(((100% / 3) * 2) - 28px);
        height: 100%;
        margin-bottom: 0; } }

.showcase--three-in-line,
.showcase--four-in-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .showcase--three-in-line .showcase__item,
  .showcase--four-in-line .showcase__item {
    width: 100%;
    margin: 1.75rem 0 0; }
    .showcase--three-in-line .showcase__item:first-child,
    .showcase--four-in-line .showcase__item:first-child {
      margin-top: 0; }

@media screen and (min-width: 640px) {
  .showcase--three-in-line .showcase__item {
    lost-column: 1/2 2;
    flex-basis: auto; }
    .showcase--three-in-line .showcase__item:nth-child(-n+2) {
      margin-top: 0; } }

@media screen and (min-width: 1200px) {
  .showcase--three-in-line .showcase__item {
    lost-column: 1/3 3;
    flex-basis: auto; }
    .showcase--three-in-line .showcase__item:nth-child(-n+3) {
      margin-top: 0; } }

@media screen and (min-width: 640px) {
  .showcase--four-in-line .showcase__item {
    lost-column: 1/2 2;
    flex-basis: auto; }
    .showcase--four-in-line .showcase__item:nth-child(-n+2) {
      margin-top: 0; } }

@media screen and (min-width: 912px) {
  .showcase--four-in-line .showcase__item {
    lost-column: 1/3 3;
    flex-basis: auto; }
    .showcase--four-in-line .showcase__item:nth-child(-n+3) {
      margin-top: 0; } }

@media screen and (min-width: 1200px) {
  .showcase--four-in-line .showcase__item {
    lost-column: 1/4 4;
    flex-basis: auto; }
    .showcase--four-in-line .showcase__item:nth-child(-n+4) {
      margin-top: 0; } }

.showcase--mix {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 28px);
  margin: 0 -0.875rem; }
  .showcase--mix .showcase__item {
    margin: 0 0.875rem 1.75rem; }

.showcase__item--1-2 {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .showcase__item--1-2 {
      width: calc(100%/2 - 28px); } }

.showcase__item--1-3 {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .showcase__item--1-3 {
      width: calc(100%/2 - 28px); } }
  @media screen and (min-width: 912px) {
    .showcase__item--1-3 {
      width: calc(100%/3 - 28px); } }

.showcase__item--2-3 {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .showcase__item--2-3 {
      width: calc(100%/2 - 28px); } }
  @media screen and (min-width: 912px) {
    .showcase__item--2-3 {
      width: calc(100%/3*2 - 28px); } }

.showcase-container {
  width: 100%; }
  .showcase-container .showcase {
    width: calc(100% + (0.875rem * 2));
    margin-left: -0.875rem; }

.skiplink {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  top: 10px;
  z-index: 522;
  display: inline-block;
  padding: .5rem 1rem;
  border: 1px solid #003082;
  background-color: #fff; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .skiplink {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .skiplink {
      clip: rect(1px 1px 1px 1px); } }
  .skiplink:focus {
    position: fixed;
    left: 10px;
    clip-path: none; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .skiplink:focus {
        clip: auto; } }
    @supports (-ms-ime-align: auto) {
      .skiplink:focus {
        clip: auto; } }

.slider {
  position: relative;
  width: calc(100% + 56px);
  margin-top: -0.875rem;
  margin-right: -1.75rem;
  margin-bottom: 1.75rem;
  margin-left: -1.75rem; }

.slider__inner {
  overflow-x: auto; }
  .no-touch .slider__inner {
    overflow-x: hidden; }
  @media screen and (min-width: 1200px) {
    .touch .slider__inner {
      overflow-x: hidden; } }

.slider__list {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0.875rem 0.875rem; }
  .no-touch .slider__list {
    overflow-x: visible;
    transition: transform 0.2s ease-out; }
  @media screen and (min-width: 1200px) {
    .touch .slider__list {
      overflow-x: visible;
      transition: transform 0.2s ease-out; } }

.slider__item {
  width: 100%;
  flex: 0 0 auto;
  padding: 0 14px;
  margin-bottom: 0;
  display: flex;
  flex-flow: column nowrap; }
  .no-touch .slider__item:last-of-type {
    padding-right: 14px; }
  .touch .slider__item {
    width: 90%; }
  .slider__item:before {
    display: none; }
  .slider__item:last-of-type {
    padding-right: 28px; }
  @media screen and (min-width: 640px) {
    .slider__item {
      width: 50%; }
      .touch .slider__item {
        width: 45%; } }
  @media screen and (min-width: 912px) {
    .slider__item {
      width: 33.33333%; }
      .touch .slider__item {
        width: 30%; } }
  @media screen and (min-width: 1200px) {
    .touch .slider__item,
    .no-touch .slider__item {
      width: 25%; } }

.slider__item-content {
  flex: 1 0 auto; }

.slider__button {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: #003082;
  text-align: center; }
  .no-touch .slider__button {
    display: block; }
  .slider__button.is-hidden {
    display: none !important; }
  @media screen and (min-width: 1200px) {
    .touch .slider__button {
      display: block; } }

.slider__button--next {
  right: 0.5rem; }

.slider__button--prev {
  left: 0.5rem; }
  .slider__button--prev .slider__button-icon {
    margin-left: -2px;
    transform: rotate(180deg); }

.slider--inertial .slider__inner {
  -webkit-overflow-scrolling: touch; }
  .slider--inertial .slider__inner::-webkit-scrollbar {
    width: 0;
    background: transparent; }

.slider-container {
  width: 100%;
  position: relative; }

.sticker {
  display: inline-block;
  width: auto;
  padding: 2px 8px;
  margin-bottom: 0;
  background-color: #ffc917;
  color: #003082;
  font-weight: 700;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .sticker:empty {
    display: none; }

.sticker--nowrap {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap; }

.sticker--absolute {
  position: absolute; }

.sticker--resp-font-size {
  font-size: 0.875rem; }
  @media screen and (min-width: 640px) {
    .sticker--resp-font-size {
      font-size: 1.125rem; } }

.sticker--l {
  padding: 4px 10px; }

.sticker--secondary {
  background-color: #FFFFFF; }

.sticker--alert {
  background-color: #db0029;
  color: #FFFFFF; }

.sticky-book-it {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4.0625rem;
  z-index: 10;
  transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);
  background-color: #f3f3f4;
  border-top: solid 1px #e6e6e9;
  border-bottom: solid 1px #e6e6e9; }
  @media screen and (min-width: 640px) {
    .sticky-book-it {
      position: sticky;
      transform: translateY(4.0625rem); }
      .sticky-book-it.is-sticky {
        transform: translateY(0); } }
  @media screen and (min-width: 912px) {
    .sticky-book-it.l-hidden {
      display: none; } }

.sticky-book-it__container {
  align-items: center;
  flex-flow: row nowrap;
  height: 100%;
  padding-right: 14px;
  padding-left: 14px; }
  @media screen and (min-width: 480px) {
    .sticky-book-it__container {
      padding-right: 28px;
      padding-left: 28px; } }
  @media screen and (min-width: 640px) {
    .sticky-book-it__container {
      justify-content: flex-end; } }

@media screen and (min-width: 640px) {
  .sticky-book-it__header {
    min-width: 7.5rem;
    margin-right: 20px; } }

.sticky-book-it__price-description {
  margin-top: 4px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1; }

.sticky-book-it__price {
  font-size: 2rem;
  line-height: 1; }

.sticky-book-it__price-cents {
  font-size: 1rem;
  top: -0.625rem; }

.sticky-book-it__description {
  margin-bottom: 0; }

.sticky-book-it__description--secondary {
  color: #003082; }

.sticky-book-it__description--responsive {
  display: none; }
  @media screen and (min-width: 480px) {
    .sticky-book-it__description--responsive {
      display: inline; } }

.sticky-book-it__description-sub {
  display: none; }
  @media screen and (min-width: 912px) {
    .sticky-book-it__description-sub {
      display: inline; } }

.sticky-book-it__offer {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #003082; }

.sticky-book-it__button--full {
  width: 100%; }
  @media screen and (min-width: 480px) {
    .sticky-book-it__button--full {
      width: auto; } }

.sticky-book-it__status-title {
  color: #db0029;
  margin: 0;
  font-weight: normal;
  width: auto; }

.sticky-book-it__button--medium {
  display: none; }

@media screen and (min-width: 480px) {
  .sticky-book-it__button--small {
    display: none; }
  .sticky-book-it__button--medium {
    display: flex; } }

body.has-sticky-footer {
  padding-bottom: 65px; }

@supports (position: sticky) {
  @media screen and (min-width: 640px) {
    body.has-sticky-footer {
      padding-bottom: 0; } } }

.tag-list {
  lost-flex-container: row;
  display: inline-flex; }
  .tag-list > li {
    padding-left: inherit; }
    .tag-list > li:before {
      content: none; }
  .tag-list .tag-list__item {
    margin-right: 0.875rem; }
    .tag-list .tag-list__item:last-child {
      margin-right: 0; }

.tag-list__item {
  padding: 0.3125rem 0;
  margin-right: 0.875rem; }
  .tag-list__item:last-child {
    margin-right: 0; }

.tag-list__anchor {
  border-bottom: 1px solid currentColor;
  line-height: 1; }
  .tag-list--inverted .tag-list__anchor {
    color: #FFFFFF;
    opacity: 0.8; }
    .tag-list--inverted .tag-list__anchor:hover {
      opacity: 1; }

.tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 15rem;
  overflow: hidden;
  padding: 0;
  padding-top: 2.8125rem;
  border-bottom: 4px solid #ffc917;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0); }

.tile__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 84px);
  overflow: hidden;
  display: block;
  transform: scale(1); }
  .tile__picture:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-image: linear-gradient(to bottom, transparent 0%, transparent 10%, rgba(0, 0, 0, 0.55) 60%, rgba(0, 0, 0, 0.85) 100%);
    content: "";
    opacity: 0.45; }

.tile__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0; }
  .no-object-fit .tile__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: auto;
    max-width: none; }
    .no-object-fit .tile__image.is-wider {
      height: 100%;
      width: auto; }

.tile__title {
  position: relative;
  display: block;
  padding: 0 20px;
  margin-bottom: 20px;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.5rem;
  text-shadow: black 1px 1px 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tile__text {
  position: relative;
  display: block;
  min-height: 5.25rem;
  padding: 15px 6.5rem 20px 20px;
  margin-bottom: 0;
  background-color: #FFFFFF;
  color: #003082; }

.tile__super-text {
  display: block;
  position: relative;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 15px 6.5rem 0 20px;
  text-shadow: #000 1px 1px 1px; }
  @media screen and (min-width: 640px) {
    .tile__super-text {
      font-size: 1.125rem; } }

.tile__sub-text {
  display: block;
  font-size: 0.75rem;
  color: #6a6a7a; }

.tile__price,
.tile__offer {
  position: absolute;
  top: -10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 5.25rem;
  height: 5.25rem;
  padding: 10px;
  background-color: #ffc917;
  color: #003082; }

.tile__offer {
  justify-content: space-between;
  padding: 0 0 0.625rem; }

.tile__offer-description {
  display: inline-block;
  padding: 0.1875rem 0.625rem;
  background-color: #FFFFFF;
  font-size: 0.75rem;
  text-transform: uppercase; }

.tile__offer-discount {
  display: inline-block;
  padding: 0 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tile__price-text {
  font-size: 0.75rem; }

.tile__price-amount {
  font-weight: 700;
  font-size: 2rem;
  line-height: .9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tile__price--small .tile__price-amount {
  font-size: 1.125rem; }

.tile__price--tiny .tile__price-amount {
  font-size: 1rem; }

.tile__sticker {
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
  max-width: calc(100% - 20px);
  white-space: nowrap; }
  .tile__sticker:empty {
    display: none; }

.tile__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline-color: #FFFFFF;
  outline-offset: -3px; }
  @media screen and (min-width: 0\0) {
    .tile__link {
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px; }
      .tile__link:focus {
        outline: 2px dotted #FFFFFF; } }
  @supports (-ms-ime-align: auto) {
    .tile__link {
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px; }
      .tile__link:focus {
        outline: 2px dotted #FFFFFF; } }

.tile__link__text {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .tile__link__text {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .tile__link__text {
      clip: rect(1px 1px 1px 1px); } }

.tile--animated {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .tile--animated .tile__picture {
    transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
    .tile--animated .tile__picture:after {
      transition: opacity 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .tile--animated .tile__image {
    transition: transform 0.3s cubic-bezier(0.7, 0.27, 0.27, 1); }
  .tile--animated:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); }
    .tile--animated:hover .tile__picture {
      transform: scale(1.05); }
      .tile--animated:hover .tile__picture:after {
        opacity: 0.7; }
  .tile--animated:focus-within {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); }
    .tile--animated:focus-within .tile__picture {
      transform: scale(1.05); }
      .tile--animated:focus-within .tile__picture:after {
        opacity: 0.7; }

@media screen and (min-width: 912px) {
  .tile--highlighted {
    min-height: 360px; } }

@media screen and (min-width: 1200px) {
  .tile--highlighted .tile__title.tile__title {
    font-size: 3rem;
    line-height: 1.145; } }

.tile--featured .tile__picture {
  height: 100%; }

.tile--featured .tile__title {
  padding-right: 6.5rem;
  margin-bottom: 7px;
  font-size: 1.5rem; }
  @media screen and (min-width: 912px) {
    .tile--featured .tile__title {
      font-size: 2rem; } }

.tile--featured .tile__text {
  min-height: auto;
  padding: 0 6.5rem 20px 20px;
  background-color: transparent;
  color: #FFFFFF; }

.tile--featured .tile__sub-text {
  display: block;
  font-size: 0.75rem;
  color: #FFFFFF; }

.tile--featured .tile__price,
.tile--featured .tile__offer {
  top: auto;
  bottom: 10px; }

/* Component name is in camelcase to reuse NS Design template */
.timetableList {
  margin: 0; }

.timetableList__key {
  display: inline;
  font-weight: normal;
  color: #060922; }

.timetableList__value {
  display: inline; }
  .timetableList__value:after {
    content: '\A';
    white-space: pre-wrap; }

/*
@name pageIntro
@description Represents intro title text update on on the page.
@section components

@markup
<div class="pageIntro">
        <h1 class="pageIntro__title heading heading--xxxl heading--bold">title</h1>
        <div class="pageIntro__text">
            <p>text</p>
            <p>text</p>
        </div>
        <p  class="pageIntro__updatedOn">Bijgewerkt op 12 juni 2020</p>
</div>
*/
.pageIntro {
  width: 100%;
  margin-bottom: 28px;
  padding-top: 14px;
  padding-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .pageIntro {
      lost-column: 0.83333;
      flex-basis: auto;
      lost-offset: 0.08333;
      padding-top: 28px;
      padding-bottom: 28px; } }
  @media screen and (min-width: 912px) {
    .pageIntro {
      lost-column: 0.66667;
      flex-basis: auto;
      lost-offset: 0.16667; } }
  @media screen and (min-width: 640px) {
    .pageIntro {
      lost-column: 1;
      flex-basis: auto;
      lost-offset: 0; } }
  @media screen and (min-width: 768px) {
    .pageIntro {
      lost-column: 0.83333;
      flex-basis: auto;
      lost-offset: 0.08333;
      padding-top: 28px;
      padding-bottom: 28px;
      text-align: center; } }

.pageIntro__text {
  margin-top: 1.25rem;
  font-size: 1.125rem;
  color: #003082; }
  @media screen and (min-width: 768px) {
    .pageIntro__text {
      font-size: 1.25rem; } }

.pageIntro__updatedOn {
  color: #39394d;
  font-size: 0.875rem;
  margin-top: 1.25rem; }
  @media screen and (min-width: 768px) {
    .pageIntro__updatedOn {
      margin-top: 1.875rem; } }

/*

@name Panel
@description Panel; Displaying text and linking to a collection page inside a showcase
@section Organisms
@type UI
@extended false

@modifier .panel--highlighted      - Panel with a bigger font-size and a larger size

@markup
<div class='panel {{modifier_class}}'>
  <svg class="panel__background icon" aria-hidden="true">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="}"></use>
  </svg>
  <span class='panel__title'>{{title}}</span>
  <p class='panel__text'>{{text}}</p>
  <a href="#0" class="button button--secondary button--arrow" title="">
    <span class="button__text">{{text}}</span>
    <svg class="button__icon button__icon--right icon icon--textColor" width="11" height="11" viewBox="0 0 18 18" aria-hidden="true">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#chevron-mini"></use>
    </svg>
  </a>
</div>

*/
.panel {
  display: block;
  position: relative;
  padding: 25px;
  min-height: auto;
  overflow: hidden;
  background-color: #FFFFFF;
  border-bottom: 4px solid #ffc917; }

.panel__background {
  opacity: 0.1;
  width: 140px;
  height: 140px;
  bottom: 28px;
  right: -14px;
  position: absolute; }

.panel__title {
  display: block;
  padding: 0;
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 400;
  color: #003082;
  text-shadow: none;
  margin-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .panel__title {
      font-size: 1.875rem;
      line-height: 1.33333; } }

.panel__text {
  padding: 0;
  margin-bottom: 14px;
  font-size: 1rem;
  color: #39394d;
  min-height: auto; }

@media screen and (min-width: 912px) {
  .panel--highlighted .panel__background {
    width: 210px;
    height: 210px;
    bottom: 14px;
    right: -28px; } }

.panel--highlighted .panel__title {
  font-size: 1.75rem;
  line-height: 1.25; }
  @media screen and (min-width: 640px) {
    .panel--highlighted .panel__title {
      font-size: 1.875rem;
      line-height: 1.33333; } }

@media screen and (min-width: 912px) {
  .panel--highlighted .panel__text {
    margin-bottom: 28px;
    font-size: 1.125rem; } }

.product-overview {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }
  .product-overview li:before {
    content: none; }

.product-overview__item {
  lost-flex-container: row;
  margin-bottom: 1.75rem;
  padding: 0;
  width: 100%; }
  .product-overview__item:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 640px) {
    .product-overview__item {
      margin-bottom: 3.5rem; } }

.product-overview__item-content {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .product-overview__item-content {
      lost-column: 0.5;
      flex-basis: auto; } }
  @media screen and (min-width: 1024px) {
    .product-overview__item-content {
      lost-column: 5/12 2;
      flex-basis: auto; } }

.product-overview__item-gallery {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .product-overview__item-gallery {
      lost-column: 0.5;
      flex-basis: auto; } }
  @media screen and (min-width: 1024px) {
    .product-overview__item-gallery {
      lost-column: 6/12 2;
      flex-basis: auto; } }

.product-overview__title {
  margin-bottom: 0.3125rem; }
  @media screen and (min-width: 640px) {
    .product-overview__title {
      margin-bottom: 0.625rem; } }

.product-overview__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #003082;
  display: flex;
  margin-bottom: 0.625rem; }
  .product-overview__sublist li:before {
    content: none; }

.product-overview__sublist-item {
  padding-left: 0;
  font-size: 0.875rem;
  line-height: 1.42857; }
  @media screen and (min-width: 640px) {
    .product-overview__sublist-item {
      font-size: 1rem;
      line-height: 1.5625; } }
  .product-overview__sublist-item + .product-overview__sublist-item {
    padding-left: 0.625rem;
    border-left: 1px solid #b5b5bc;
    margin-left: 0.625rem; }
  @media screen and (min-width: 640px) {
    .product-overview__sublist-item {
      padding-right: 0.9375rem; }
      .product-overview__sublist-item + .product-overview__sublist-item {
        padding-left: 0.9375rem;
        margin-left: 0; } }

.product-overview__sublist-price {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap; }

.product-overview__rating {
  margin-top: -0.625rem;
  margin-bottom: 0.125rem; }
  @media screen and (min-width: 480px) {
    .product-overview__rating {
      margin-bottom: 0.625rem; } }

.product-overview__textlink {
  margin-bottom: 0.875rem; }
  @media screen and (min-width: 912px) {
    .product-overview__textlink {
      margin-bottom: 0; } }

.star-rating {
  display: flex; }

.star-rating__star + .star-rating__star {
  margin-left: 2px; }

.star-rating__text {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .star-rating__text {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .star-rating__text {
      clip: rect(1px 1px 1px 1px); } }

.banner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px; }
  @media screen and (min-width: 640px) {
    .banner {
      flex-direction: row;
      flex-grow: 1;
      padding: 0; } }
  .banner > * {
    position: relative;
    z-index: 1; }

.banner__visual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .banner__visual {
      margin-bottom: 0; } }

.banner__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0; }

.banner__background-image {
  position: absolute;
  bottom: 0;
  right: 42px;
  display: none;
  width: 60%; }
  @media screen and (min-width: 640px) {
    .banner__background-image {
      display: block; } }
  @media screen and (min-width: 912px) {
    .banner__background-image {
      bottom: -42px; } }

.banner__background-image--light {
  opacity: 0.3; }

.banner__background-image--dark {
  opacity: 0.1; }

.banner__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 14px; }
  .banner__text:last-child, .banner__text:empty {
    margin-bottom: 0; }
  @media screen and (min-width: 640px) {
    .banner__text {
      margin-bottom: 0; } }

.banner__text-body {
  color: #003082; }
  .banner__text-body:empty {
    display: none; }

.banner__button {
  white-space: nowrap; }

@media screen and (min-width: 640px) {
  .banner--two-column .banner__visual,
  .banner--two-column .banner__text {
    flex-basis: 50%; } }

@media screen and (min-width: 640px) {
  .banner--three-column .banner__visual,
  .banner--three-column .banner__text {
    flex-basis: calc(100% / 3); } }

.banner--dark-bg .banner__heading,
.banner--dark-bg .banner__text-body {
  color: #FFFFFF; }

.banner--a {
  border-bottom: 1px solid #b5b5bc; }
  @media screen and (min-width: 640px) {
    .banner--a {
      border-top: 1px solid #b5b5bc; } }
  .banner--a .banner__visual {
    display: block;
    order: 2;
    max-height: 30vw; }
    @media screen and (min-width: 480px) {
      .banner--a .banner__visual {
        max-height: 40vw; } }
    @media screen and (min-width: 640px) {
      .banner--a .banner__visual {
        order: 0;
        max-height: 50vw;
        padding: 30px 3vw 0; } }
    @media screen and (min-width: 912px) {
      .banner--a .banner__visual {
        max-height: 240px;
        padding: 30px 8% 0; } }
  .banner--a .banner__text {
    order: 1;
    padding: 0 0 20px; }
    @media screen and (min-width: 640px) {
      .banner--a .banner__text {
        order: 0;
        padding: 40px 20px 40px 14px; } }
  @media screen and (min-width: 912px) {
    .banner--a .banner__text-body {
      font-size: 1.125rem;
      padding-right: 30px; } }
  @media screen and (min-width: 1024px) {
    .banner--a .banner__text-body {
      padding-right: 45px; } }
  .banner--a .banner__heading {
    margin-bottom: 14px; }

@media screen and (min-width: 640px) {
  .banner--b,
  .banner--c {
    align-items: stretch; } }

@media screen and (min-width: 640px) {
  .banner--b .banner__visual,
  .banner--c .banner__visual {
    padding: 20px 14px 20px 20px; } }

@media screen and (min-width: 640px) {
  .banner--b .banner__text,
  .banner--c .banner__text {
    padding: 20px 14px; }
    .banner--b .banner__text:last-child,
    .banner--c .banner__text:last-child {
      padding-right: 20px;
      text-align: right; }
    .banner--b .banner__text .banner__button,
    .banner--c .banner__text .banner__button {
      text-align: left; } }

.banner--b .banner__text-body,
.banner--c .banner__text-body {
  font-size: 0.875rem;
  line-height: 1.42857;
  margin-bottom: 10px; }

.banner--b .banner__button,
.banner--c .banner__button {
  width: 100%; }
  @media screen and (min-width: 640px) {
    .banner--b .banner__button,
    .banner--c .banner__button {
      width: auto; } }

@media screen and (min-width: 640px) {
  .banner--b {
    align-items: center; } }

.banner--b .banner__visual {
  display: block; }

.banner--c {
  padding-top: 0; }
  .banner--c .banner__visual {
    height: 123px;
    margin-right: -20px;
    margin-left: -20px;
    overflow: hidden; }
    @media screen and (min-width: 640px) {
      .banner--c .banner__visual {
        height: auto;
        margin-right: 0;
        margin-left: 0; } }
  @media screen and (min-width: 640px) {
    .banner--c .banner__image {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); } }

.vcard__maplink {
  position: static; }

.vcard__address:not(:last-child) {
  margin-bottom: 7px; }

.vcard__maplink {
  margin-bottom: 14px; }

.site-footer__container {
  lost-flex-container: column;
  flex-flow: row wrap;
  align-items: flex-start;
  color: #39394d; }

.site-footer__section {
  lost-flex-container: row;
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }
  @media screen and (min-width: 640px) {
    .site-footer__section {
      padding-top: 2.625rem;
      padding-bottom: 2.625rem; } }
  .site-footer__section + .site-footer__section {
    border-top: solid 1px #e6e6e9; }

.site-footer__section-columns {
  lost-flex-container: row;
  width: 100%; }

.site-footer__column {
  lost-column: 1;
  flex-basis: auto; }
  @media screen and (min-width: 640px) {
    .site-footer__column {
      lost-column: 1/2 2;
      flex-basis: auto; } }
  @media screen and (min-width: 912px) {
    .site-footer__column {
      lost-column: 1/3 3;
      flex-basis: auto; } }
  .site-footer__column + .site-footer__column {
    margin-top: 28px; }
  @media screen and (min-width: 640px) {
    .site-footer__column:nth-child(2) {
      margin-top: 0; }
    .site-footer__column:nth-child(n+3) {
      margin-top: 28px; } }
  @media screen and (min-width: 912px) {
    .site-footer__column:nth-child(3) {
      margin-top: 0; }
    .site-footer__column:nth-child(n+4) {
      margin-top: 28px; } }

.site-footer__column-title {
  margin-bottom: 0.875rem;
  color: #003082;
  font-size: 1rem;
  line-height: 1.5625;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.site-footer__bottom {
  lost-flex-container: row;
  width: 100%;
  border-top: solid 0.0625rem #e6e6e9;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.site-footer__logo-ns {
  width: 3.1875rem;
  height: 1.25rem; }

.site-footer__logo-facebook {
  width: 1.1875rem;
  height: 1.1875rem; }

.site-footer__bottom-links {
  lost-column: 0.66667;
  flex-basis: auto;
  margin-top: 0;
  margin-bottom: 0;
  order: 1; }
  @media screen and (min-width: 640px) {
    .site-footer__bottom-links {
      lost-column: 0.33333;
      flex-basis: auto;
      order: 0; } }

.site-footer__follow {
  width: 100%;
  order: 0;
  margin-bottom: 1.75rem;
  color: #6a6a7a; }
  @media screen and (min-width: 640px) {
    .site-footer__follow {
      lost-column: 0.33333;
      flex-basis: auto;
      order: 1;
      margin-bottom: 0; } }

.site-footer__logo {
  lost-column: 0.33333;
  flex-basis: auto;
  lost-align: right;
  order: 2; }
  @media screen and (min-width: 640px) {
    .site-footer__logo {
      lost-column: 0.33333;
      flex-basis: auto; } }

.site-footer__logo-link {
  display: block; }

.site-footer__title {
  width: 100%;
  margin-bottom: 0.875rem; }

.site-footer__intro:last-child {
  margin-bottom: 0; }

.site-footer__section-columns--justified .site-footer__column {
  lost-column: 1;
  flex-basis: auto; }

@media screen and (min-width: 912px) {
  .site-footer__section-columns--justified .site-footer__column:nth-child(2n+1) {
    lost-column: 3/5 2;
    flex-basis: auto; } }

@media screen and (min-width: 1024px) {
  .site-footer__section-columns--justified .site-footer__column:nth-child(2n+1) {
    lost-column: 7/12 2;
    flex-basis: auto; } }

.site-footer__section-columns--justified .site-footer__column:nth-child(2n) {
  margin-top: 1.75rem; }
  @media screen and (min-width: 912px) {
    .site-footer__section-columns--justified .site-footer__column:nth-child(2n) {
      margin-top: 0;
      lost-column: 2/5 2;
      flex-basis: auto; } }
  @media screen and (min-width: 1024px) {
    .site-footer__section-columns--justified .site-footer__column:nth-child(2n) {
      lost-column: 1/3 2;
      flex-basis: auto; } }

@media screen and (min-width: 640px) {
  .site-footer__column--m-full {
    lost-column: 1;
    flex-basis: auto;
    margin-bottom: 28px; }
    .site-footer__column--m-full ~ .site-footer__column {
      margin-top: 0;
      margin-left: 28px; }
    .site-footer__column--m-full + .site-footer__column {
      margin-left: 0; }
    .site-footer__column--m-full .site-footer__intro {
      width: 50%; } }

@media screen and (min-width: 912px) {
  .site-footer__column--m-full {
    lost-column: 0.33333;
    flex-basis: auto;
    margin-bottom: 0; }
    .site-footer__column--m-full ~ .site-footer__column {
      margin-left: 0; }
    .site-footer__column--m-full .site-footer__intro {
      width: 100%; } }

.site-header {
  position: relative;
  z-index: 20;
  background: white;
  height: 3.75rem;
  width: 100%;
  box-shadow: 0 0.125rem 0 0 rgba(7, 7, 33, 0.15); }

.site-header__container {
  justify-content: space-between;
  align-items: center;
  height: 100%; }

.site-header__logo-container {
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
  margin-right: -0.3125rem; }
  @media screen and (min-width: 480px) {
    .site-header__logo-container {
      margin-left: -1.375rem; } }

.site-header__logo-link {
  margin-top: -0.5rem;
  line-height: 0;
  white-space: nowrap;
  display: block;
  padding: 0.5rem; }
  @media screen and (min-width: 480px) {
    .site-header__logo-link {
      padding: 0.375rem 1.375rem; } }

.site-header__logo-ns {
  display: inline-block;
  width: 3.1875rem;
  margin-top: 0.3125rem; }

.site-header__logo-spoordeelwinkel {
  width: 8.4375rem; }

.site-header__verticale-separator {
  width: 1px;
  height: 1.5rem;
  margin-top: 0.625rem;
  background-color: #b5b5bc; }

.site-header__back-link {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end; }

.site-header__back-link-icon {
  width: 1.125rem;
  margin-right: 0.3125rem;
  fill: currentColor; }

.site-header__back-link-extended {
  display: none; }
  @media screen and (min-width: 640px) {
    .site-header__back-link-extended {
      display: inline; } }

/* ===== TEMPLATES
 * These are layout styles based on specific templates, like the grid
 */
.page-detail__head {
  position: relative;
  lost-flex-container: row;
  width: 100%; }
  @media screen and (min-width: 640px) {
    .page-detail__head {
      margin-bottom: 56px; } }
  @media screen and (min-width: 912px) {
    .page-detail__head {
      margin-bottom: 70px; } }
  .page-detail__head:focus {
    outline: 0; }

.page-detail__content-box {
  margin-top: 28px;
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .page-detail__content-box {
      order: 1;
      lost-column: 0.5;
      flex-basis: auto;
      margin-top: 2.625rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 912px) {
    .page-detail__content-box {
      lost-column: 0.66667;
      flex-basis: auto; } }
  @media screen and (min-width: 1200px) {
    .page-detail__content-box {
      lost-column: 0.58333;
      flex-basis: auto; } }

@media screen and (min-width: 1200px) {
  .page-detail__content-box--extra-wide {
    lost-column: 0.66667;
    flex-basis: auto; } }

.page-detail__cta-box {
  order: 1;
  width: 100%;
  margin-bottom: 28px;
  align-self: flex-start; }
  @media screen and (min-width: 640px) {
    .page-detail__cta-box {
      lost-column: 1/2 2;
      flex-basis: auto;
      order: 2;
      margin-top: -58px; } }
  @media screen and (min-width: 912px) {
    .page-detail__cta-box {
      lost-column: 1/3 2;
      flex-basis: auto;
      order: 0;
      position: absolute;
      right: 0;
      top: 0;
      margin-bottom: 0; } }

.page-detail__cta-box--inline {
  order: 2;
  align-self: initial;
  width: initial;
  margin-top: 28px;
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .page-detail__cta-box--inline {
      lost-column: 1/2 2;
      flex-basis: auto;
      margin-top: 2.625rem;
      order: 2; } }
  @media screen and (min-width: 912px) {
    .page-detail__cta-box--inline {
      lost-column: 1/3 2;
      flex-basis: auto;
      margin-bottom: 0;
      order: 2;
      position: initial;
      right: initial;
      top: initial; } }

.page-detail__intro {
  width: 100%;
  order: 2; }
  @media screen and (min-width: 640px) {
    .page-detail__intro {
      order: 3;
      margin-bottom: 0; } }
  @media screen and (min-width: 912px) {
    .page-detail__intro {
      lost-column: 0.66667;
      flex-basis: auto; } }
  @media screen and (min-width: 1200px) {
    .page-detail__intro {
      lost-column: 0.58333;
      flex-basis: auto; } }

@media screen and (min-width: 640px) {
  .page-detail__content-box + .page-detail__intro {
    margin-top: 70px; } }

.page-detail__gallery {
  margin-bottom: 28px; }
  @media screen and (min-width: 640px) {
    .page-detail__gallery {
      margin-bottom: 56px; } }
  @media screen and (min-width: 912px) {
    .page-detail__gallery {
      margin-bottom: 70px; } }
  @media screen and (min-width: 16px) and (max-width: 639.9px) {
    .page-detail__gallery + .page-detail__info.boxed-content {
      border-top: 0;
      padding-top: 28px; } }

.page-detail__product-overview {
  width: 100%; }
  @media screen and (min-width: 16px) and (max-width: 911.9px) {
    .touch .page-detail__product-overview + .page-detail__info.boxed-content {
      border-top: 0;
      padding-top: 0; } }

.page-detail__content {
  width: 100%;
  margin-bottom: 28px;
  padding-top: 14px;
  padding-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .page-detail__content {
      lost-column: 0.83333;
      flex-basis: auto;
      lost-offset: 0.08333;
      padding-top: 28px;
      padding-bottom: 28px; } }
  @media screen and (min-width: 912px) {
    .page-detail__content {
      lost-column: 0.66667;
      flex-basis: auto;
      lost-offset: 0.16667; } }
  .page-detail__content.page-detail__content {
    padding-top: 0; }

.page-detail__info {
  width: 100%; }
  .page-detail__info .form__row,
  .page-detail__info .definition-list {
    display: block; }
    @media screen and (min-width: 912px) {
      .page-detail__info .form__row,
      .page-detail__info .definition-list {
        display: flex; } }

.page-error__text {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .page-error__text {
      lost-column: 1/2 2;
      flex-basis: auto; } }

.page-error__image {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .page-error__image {
      lost-column: 1/2 2;
      flex-basis: auto; } }

.page-landing .pane {
  margin-bottom: 1.75rem; }

.page-order__inner {
  width: 100%;
  padding-top: 0;
  padding-bottom: 14px; }
  @media screen and (min-width: 640px) {
    .page-order__inner {
      padding-bottom: 28px; } }
  @media screen and (min-width: 912px) {
    .page-order__inner {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; } }

.page-order__configurator {
  width: 100%;
  margin-bottom: 14px; }
  @media screen and (min-width: 912px) {
    .page-order__configurator {
      lost-column: 0.6;
      flex-basis: auto;
      margin-bottom: 0; } }
  @media screen and (min-width: 1024px) {
    .page-order__configurator {
      lost-column: 0.66667;
      flex-basis: auto; } }

.page-order__ticket {
  width: 100%; }
  @media screen and (min-width: 912px) {
    .page-order__ticket {
      lost-column: 0.4;
      flex-basis: auto; } }
  @media screen and (min-width: 1024px) {
    .page-order__ticket {
      lost-column: 0.33333;
      flex-basis: auto; } }

.page-order__icon-content {
  display: none;
  width: 100%; }
  @media screen and (min-width: 912px) {
    .page-order__icon-content {
      display: block; } }

.page-service__side {
  width: 100%;
  margin-bottom: -28px; }
  @supports (-ms-ime-align: auto) {
    .page-service__side {
      margin-bottom: -27px; } }
  @media screen and (min-width: 640px) {
    .page-service__side {
      margin-bottom: 28px; } }
  @media screen and (min-width: 912px) {
    .page-service__side {
      lost-column: 0.4;
      flex-basis: auto; } }
  @media screen and (min-width: 1024px) {
    .page-service__side {
      lost-column: 0.33333;
      flex-basis: auto; } }

.page-service__body {
  width: 100%;
  margin-bottom: 14px; }
  @media screen and (min-width: 912px) {
    .page-service__body {
      lost-column: 0.6;
      flex-basis: auto;
      margin-bottom: 0; } }
  @media screen and (min-width: 1024px) {
    .page-service__body {
      lost-column: 0.66667;
      flex-basis: auto; } }

/* ===== HELPERS
 * These are bare, unclassed HTML elements. The Elements layer binds
 * onto bare HTML element (or 'type') selectors only.
 */
/*
@name Accessibility helper
@description Helpers for accessibility to hide/show DOM elements.
@section helpers
*/
.acc-show,
.h-acc-show {
  position: static;
  left: auto;
  clip-path: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .acc-show,
    .h-acc-show {
      clip: auto; } }
  @supports (-ms-ime-align: auto) {
    .acc-show,
    .h-acc-show {
      clip: auto; } }

.acc-show-relative,
.h-acc-show-relative {
  position: relative;
  left: auto;
  clip-path: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .acc-show-relative,
    .h-acc-show-relative {
      clip: auto; } }
  @supports (-ms-ime-align: auto) {
    .acc-show-relative,
    .h-acc-show-relative {
      clip: auto; } }

.acc-show-absolute,
.h-acc-show-absolute {
  position: absolute;
  left: auto;
  clip-path: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .acc-show-absolute,
    .h-acc-show-absolute {
      clip: auto; } }
  @supports (-ms-ime-align: auto) {
    .acc-show-absolute,
    .h-acc-show-absolute {
      clip: auto; } }

.acc-show-fixed,
.h-acc-show-fixed {
  position: fixed;
  left: auto;
  clip-path: none; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .acc-show-fixed,
    .h-acc-show-fixed {
      clip: auto; } }
  @supports (-ms-ime-align: auto) {
    .acc-show-fixed,
    .h-acc-show-fixed {
      clip: auto; } }

.acc-hide,
.h-acc-hide {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .acc-hide,
    .h-acc-hide {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .acc-hide,
    .h-acc-hide {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 480px) {
  .h-acc-show-sm {
    position: static;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-acc-show-sm {
      clip: auto; } }

@media screen and (min-width: 480px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-sm {
        clip: auto; } }
  .h-acc-show-relative-sm {
    position: relative;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-acc-show-relative-sm {
      clip: auto; } }

@media screen and (min-width: 480px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-relative-sm {
        clip: auto; } }
  .h-acc-show-absolute-sm {
    position: absolute;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-acc-show-absolute-sm {
      clip: auto; } }

@media screen and (min-width: 480px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-absolute-sm {
        clip: auto; } }
  .h-acc-show-fixed-sm {
    position: fixed;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-acc-show-fixed-sm {
      clip: auto; } }

@media screen and (min-width: 480px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-fixed-sm {
        clip: auto; } }
  .h-acc-hide-sm {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-acc-hide-sm {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 480px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-hide-sm {
        clip: rect(1px 1px 1px 1px); } } }

@media screen and (min-width: 640px) {
  .h-acc-show-m {
    position: static;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-acc-show-m {
      clip: auto; } }

@media screen and (min-width: 640px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-m {
        clip: auto; } }
  .h-acc-show-relative-m {
    position: relative;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-acc-show-relative-m {
      clip: auto; } }

@media screen and (min-width: 640px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-relative-m {
        clip: auto; } }
  .h-acc-show-absolute-m {
    position: absolute;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-acc-show-absolute-m {
      clip: auto; } }

@media screen and (min-width: 640px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-absolute-m {
        clip: auto; } }
  .h-acc-show-fixed-m {
    position: fixed;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-acc-show-fixed-m {
      clip: auto; } }

@media screen and (min-width: 640px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-fixed-m {
        clip: auto; } }
  .h-acc-hide-m {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-acc-hide-m {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 640px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-hide-m {
        clip: rect(1px 1px 1px 1px); } } }

@media screen and (min-width: 768px) {
  .h-acc-show-ml {
    position: static;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-acc-show-ml {
      clip: auto; } }

@media screen and (min-width: 768px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-ml {
        clip: auto; } }
  .h-acc-show-relative-ml {
    position: relative;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-acc-show-relative-ml {
      clip: auto; } }

@media screen and (min-width: 768px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-relative-ml {
        clip: auto; } }
  .h-acc-show-absolute-ml {
    position: absolute;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-acc-show-absolute-ml {
      clip: auto; } }

@media screen and (min-width: 768px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-absolute-ml {
        clip: auto; } }
  .h-acc-show-fixed-ml {
    position: fixed;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-acc-show-fixed-ml {
      clip: auto; } }

@media screen and (min-width: 768px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-fixed-ml {
        clip: auto; } }
  .h-acc-hide-ml {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-acc-hide-ml {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 768px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-hide-ml {
        clip: rect(1px 1px 1px 1px); } } }

@media screen and (min-width: 912px) {
  .h-acc-show-l {
    position: static;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-acc-show-l {
      clip: auto; } }

@media screen and (min-width: 912px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-l {
        clip: auto; } }
  .h-acc-show-relative-l {
    position: relative;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-acc-show-relative-l {
      clip: auto; } }

@media screen and (min-width: 912px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-relative-l {
        clip: auto; } }
  .h-acc-show-absolute-l {
    position: absolute;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-acc-show-absolute-l {
      clip: auto; } }

@media screen and (min-width: 912px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-absolute-l {
        clip: auto; } }
  .h-acc-show-fixed-l {
    position: fixed;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-acc-show-fixed-l {
      clip: auto; } }

@media screen and (min-width: 912px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-fixed-l {
        clip: auto; } }
  .h-acc-hide-l {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-acc-hide-l {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 912px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-hide-l {
        clip: rect(1px 1px 1px 1px); } } }

@media screen and (min-width: 1024px) {
  .h-acc-show-xl {
    position: static;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-acc-show-xl {
      clip: auto; } }

@media screen and (min-width: 1024px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-xl {
        clip: auto; } }
  .h-acc-show-relative-xl {
    position: relative;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-acc-show-relative-xl {
      clip: auto; } }

@media screen and (min-width: 1024px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-relative-xl {
        clip: auto; } }
  .h-acc-show-absolute-xl {
    position: absolute;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-acc-show-absolute-xl {
      clip: auto; } }

@media screen and (min-width: 1024px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-absolute-xl {
        clip: auto; } }
  .h-acc-show-fixed-xl {
    position: fixed;
    left: auto;
    clip-path: none; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-acc-show-fixed-xl {
      clip: auto; } }

@media screen and (min-width: 1024px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-show-fixed-xl {
        clip: auto; } }
  .h-acc-hide-xl {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-acc-hide-xl {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 1024px) {
    @supports (-ms-ime-align: auto) {
      .h-acc-hide-xl {
        clip: rect(1px 1px 1px 1px); } } }

.h-acc-no-outline:focus {
  outline: 0 !important; }

.h-color-grey {
  color: #39394d !important; }

.h-color-white {
  color: #fff !important; }

.h-color-primary {
  color: #003082 !important; }

.h-display-n {
  display: none !important; }

.h-display-i {
  display: inline !important; }

.h-display-b {
  display: block !important; }

.h-display-ib {
  display: inline-block !important; }

@media screen and (min-width: 480px) {
  .h-display-n-sm {
    display: none !important; }
  .h-display-i-sm {
    display: inline !important; }
  .h-display-b-sm {
    display: block !important; }
  .h-display-ib-sm {
    display: inline-block !important; } }

@media screen and (min-width: 640px) {
  .h-display-n-m {
    display: none !important; }
  .h-display-i-m {
    display: inline !important; }
  .h-display-b-m {
    display: block !important; }
  .h-display-ib-m {
    display: inline-block !important; } }

@media screen and (min-width: 768px) {
  .h-display-n-ml {
    display: none !important; }
  .h-display-i-ml {
    display: inline !important; }
  .h-display-b-ml {
    display: block !important; }
  .h-display-ib-ml {
    display: inline-block !important; } }

@media screen and (min-width: 912px) {
  .h-display-n-l {
    display: none !important; }
  .h-display-i-l {
    display: inline !important; }
  .h-display-b-l {
    display: block !important; }
  .h-display-ib-l {
    display: inline-block !important; } }

@media screen and (min-width: 1024px) {
  .h-display-n-xl {
    display: none !important; }
  .h-display-i-xl {
    display: inline !important; }
  .h-display-b-xl {
    display: block !important; }
  .h-display-ib-xl {
    display: inline-block !important; } }

@media screen and (min-width: 16px) and (max-width: 479.9px) {
  .h-hide-s-sm {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 16px) and (max-width: 479.9px) and (-ms-high-contrast: active), screen and (min-width: 16px) and (max-width: 479.9px) and (-ms-high-contrast: none) {
    .h-hide-s-sm {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 16px) and (max-width: 479.9px) {
    @supports (-ms-ime-align: auto) {
      .h-hide-s-sm {
        clip: rect(1px 1px 1px 1px); } } }

@media screen and (min-width: 16px) and (max-width: 639.9px) {
  .h-hide-s-m {
    position: absolute;
    left: -999em;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }
  @media screen and (min-width: 16px) and (max-width: 639.9px) and (-ms-high-contrast: active), screen and (min-width: 16px) and (max-width: 639.9px) and (-ms-high-contrast: none) {
    .h-hide-s-m {
      clip: rect(1px 1px 1px 1px); } }

@media screen and (min-width: 16px) and (max-width: 639.9px) {
    @supports (-ms-ime-align: auto) {
      .h-hide-s-m {
        clip: rect(1px 1px 1px 1px); } } }

.no-scroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

.h-m-0 {
  margin: 0px !important; }

.h-mt-0 {
  margin-top: 0px !important; }

.h-mt-1 {
  margin-top: 14px !important; }

.h-mt-2 {
  margin-top: 28px !important; }

.h-mt-3 {
  margin-top: 42px !important; }

.h-mt-4 {
  margin-top: 56px !important; }

.h-mt-5 {
  margin-top: 70px !important; }

.h-ml-0 {
  margin-left: 0px !important; }

.h-ml-1 {
  margin-left: 14px !important; }

.h-ml-2 {
  margin-left: 28px !important; }

.h-ml-3 {
  margin-left: 42px !important; }

.h-ml-4 {
  margin-left: 56px !important; }

.h-ml-5 {
  margin-left: 70px !important; }

.h-mb-0 {
  margin-bottom: 0px !important; }

.h-mb-1 {
  margin-bottom: 14px !important; }

.h-mb-2 {
  margin-bottom: 28px !important; }

.h-mb-3 {
  margin-bottom: 42px !important; }

.h-mb-4 {
  margin-bottom: 56px !important; }

.h-mb-5 {
  margin-bottom: 70px !important; }

.h-mr-0 {
  margin-right: 0px !important; }

.h-mr-1 {
  margin-right: 14px !important; }

.h-mr-2 {
  margin-right: 28px !important; }

.h-mr-3 {
  margin-right: 42px !important; }

.h-mr-4 {
  margin-right: 56px !important; }

.h-mr-5 {
  margin-right: 70px !important; }

.h-pt-0 {
  padding-top: 0px !important; }

.h-pt-1 {
  padding-top: 14px !important; }

.h-pt-2 {
  padding-top: 28px !important; }

.h-pt-3 {
  padding-top: 42px !important; }

.h-pt-4 {
  padding-top: 56px !important; }

.h-pt-5 {
  padding-top: 70px !important; }

.h-pr-0 {
  padding-right: 0px !important; }

.h-pr-1 {
  padding-right: 14px !important; }

.h-pr-2 {
  padding-right: 28px !important; }

.h-pr-3 {
  padding-right: 42px !important; }

.h-pr-4 {
  padding-right: 56px !important; }

.h-pr-5 {
  padding-right: 70px !important; }

.h-pb-0 {
  padding-bottom: 0px !important; }

.h-pb-1 {
  padding-bottom: 14px !important; }

.h-pb-2 {
  padding-bottom: 28px !important; }

.h-pb-3 {
  padding-bottom: 42px !important; }

.h-pb-4 {
  padding-bottom: 56px !important; }

.h-pb-5 {
  padding-bottom: 70px !important; }

@media screen and (min-width: 480px) {
  .h-mt-sm-0 {
    margin-top: 0px !important; }
  .h-mt-sm-1 {
    margin-top: 14px !important; }
  .h-mt-sm-2 {
    margin-top: 28px !important; }
  .h-mt-sm-3 {
    margin-top: 42px !important; }
  .h-mt-sm-4 {
    margin-top: 56px !important; }
  .h-mt-sm-5 {
    margin-top: 70px !important; }
  .h-ml-sm-0 {
    margin-left: 0px !important; }
  .h-ml-sm-1 {
    margin-left: 14px !important; }
  .h-ml-sm-2 {
    margin-left: 28px !important; }
  .h-ml-sm-3 {
    margin-left: 42px !important; }
  .h-ml-sm-4 {
    margin-left: 56px !important; }
  .h-ml-sm-5 {
    margin-left: 70px !important; }
  .h-mb-sm-0 {
    margin-bottom: 0px !important; }
  .h-mb-sm-1 {
    margin-bottom: 14px !important; }
  .h-mb-sm-2 {
    margin-bottom: 28px !important; }
  .h-mb-sm-3 {
    margin-bottom: 42px !important; }
  .h-mb-sm-4 {
    margin-bottom: 56px !important; }
  .h-mb-sm-5 {
    margin-bottom: 70px !important; }
  .h-mr-sm-0 {
    margin-right: 0px !important; }
  .h-mr-sm-1 {
    margin-right: 14px !important; }
  .h-mr-sm-2 {
    margin-right: 28px !important; }
  .h-mr-sm-3 {
    margin-right: 42px !important; }
  .h-mr-sm-4 {
    margin-right: 56px !important; }
  .h-mr-sm-5 {
    margin-right: 70px !important; }
  .h-pt-sm-0 {
    padding-top: 0px !important; }
  .h-pt-sm-1 {
    padding-top: 14px !important; }
  .h-pt-sm-2 {
    padding-top: 28px !important; }
  .h-pt-sm-3 {
    padding-top: 42px !important; }
  .h-pt-sm-4 {
    padding-top: 56px !important; }
  .h-pt-sm-5 {
    padding-top: 70px !important; }
  .h-pr-sm-0 {
    padding-right: 0px !important; }
  .h-pr-sm-1 {
    padding-right: 14px !important; }
  .h-pr-sm-2 {
    padding-right: 28px !important; }
  .h-pr-sm-3 {
    padding-right: 42px !important; }
  .h-pr-sm-4 {
    padding-right: 56px !important; }
  .h-pr-sm-5 {
    padding-right: 70px !important; }
  .h-pb-sm-0 {
    padding-bottom: 0px !important; }
  .h-pb-sm-1 {
    padding-bottom: 14px !important; }
  .h-pb-sm-2 {
    padding-bottom: 28px !important; }
  .h-pb-sm-3 {
    padding-bottom: 42px !important; }
  .h-pb-sm-4 {
    padding-bottom: 56px !important; }
  .h-pb-sm-5 {
    padding-bottom: 70px !important; } }

@media screen and (min-width: 640px) {
  .h-mt-m-0 {
    margin-top: 0px !important; }
  .h-mt-m-1 {
    margin-top: 14px !important; }
  .h-mt-m-2 {
    margin-top: 28px !important; }
  .h-mt-m-3 {
    margin-top: 42px !important; }
  .h-mt-m-4 {
    margin-top: 56px !important; }
  .h-mt-m-5 {
    margin-top: 70px !important; }
  .h-ml-m-0 {
    margin-left: 0px !important; }
  .h-ml-m-1 {
    margin-left: 14px !important; }
  .h-ml-m-2 {
    margin-left: 28px !important; }
  .h-ml-m-3 {
    margin-left: 42px !important; }
  .h-ml-m-4 {
    margin-left: 56px !important; }
  .h-ml-m-5 {
    margin-left: 70px !important; }
  .h-mb-m-0 {
    margin-bottom: 0px !important; }
  .h-mb-m-1 {
    margin-bottom: 14px !important; }
  .h-mb-m-2 {
    margin-bottom: 28px !important; }
  .h-mb-m-3 {
    margin-bottom: 42px !important; }
  .h-mb-m-4 {
    margin-bottom: 56px !important; }
  .h-mb-m-5 {
    margin-bottom: 70px !important; }
  .h-mr-m-0 {
    margin-right: 0px !important; }
  .h-mr-m-1 {
    margin-right: 14px !important; }
  .h-mr-m-2 {
    margin-right: 28px !important; }
  .h-mr-m-3 {
    margin-right: 42px !important; }
  .h-mr-m-4 {
    margin-right: 56px !important; }
  .h-mr-m-5 {
    margin-right: 70px !important; }
  .h-pt-m-0 {
    padding-top: 0px !important; }
  .h-pt-m-1 {
    padding-top: 14px !important; }
  .h-pt-m-2 {
    padding-top: 28px !important; }
  .h-pt-m-3 {
    padding-top: 42px !important; }
  .h-pt-m-4 {
    padding-top: 56px !important; }
  .h-pt-m-5 {
    padding-top: 70px !important; }
  .h-pr-m-0 {
    padding-right: 0px !important; }
  .h-pr-m-1 {
    padding-right: 14px !important; }
  .h-pr-m-2 {
    padding-right: 28px !important; }
  .h-pr-m-3 {
    padding-right: 42px !important; }
  .h-pr-m-4 {
    padding-right: 56px !important; }
  .h-pr-m-5 {
    padding-right: 70px !important; }
  .h-pb-m-0 {
    padding-bottom: 0px !important; }
  .h-pb-m-1 {
    padding-bottom: 14px !important; }
  .h-pb-m-2 {
    padding-bottom: 28px !important; }
  .h-pb-m-3 {
    padding-bottom: 42px !important; }
  .h-pb-m-4 {
    padding-bottom: 56px !important; }
  .h-pb-m-5 {
    padding-bottom: 70px !important; } }

@media screen and (min-width: 768px) {
  .h-mt-ml-0 {
    margin-top: 0px !important; }
  .h-mt-ml-1 {
    margin-top: 14px !important; }
  .h-mt-ml-2 {
    margin-top: 28px !important; }
  .h-mt-ml-3 {
    margin-top: 42px !important; }
  .h-mt-ml-4 {
    margin-top: 56px !important; }
  .h-mt-ml-5 {
    margin-top: 70px !important; }
  .h-ml-ml-0 {
    margin-left: 0px !important; }
  .h-ml-ml-1 {
    margin-left: 14px !important; }
  .h-ml-ml-2 {
    margin-left: 28px !important; }
  .h-ml-ml-3 {
    margin-left: 42px !important; }
  .h-ml-ml-4 {
    margin-left: 56px !important; }
  .h-ml-ml-5 {
    margin-left: 70px !important; }
  .h-mb-ml-0 {
    margin-bottom: 0px !important; }
  .h-mb-ml-1 {
    margin-bottom: 14px !important; }
  .h-mb-ml-2 {
    margin-bottom: 28px !important; }
  .h-mb-ml-3 {
    margin-bottom: 42px !important; }
  .h-mb-ml-4 {
    margin-bottom: 56px !important; }
  .h-mb-ml-5 {
    margin-bottom: 70px !important; }
  .h-mr-ml-0 {
    margin-right: 0px !important; }
  .h-mr-ml-1 {
    margin-right: 14px !important; }
  .h-mr-ml-2 {
    margin-right: 28px !important; }
  .h-mr-ml-3 {
    margin-right: 42px !important; }
  .h-mr-ml-4 {
    margin-right: 56px !important; }
  .h-mr-ml-5 {
    margin-right: 70px !important; }
  .h-pt-ml-0 {
    padding-top: 0px !important; }
  .h-pt-ml-1 {
    padding-top: 14px !important; }
  .h-pt-ml-2 {
    padding-top: 28px !important; }
  .h-pt-ml-3 {
    padding-top: 42px !important; }
  .h-pt-ml-4 {
    padding-top: 56px !important; }
  .h-pt-ml-5 {
    padding-top: 70px !important; }
  .h-pr-ml-0 {
    padding-right: 0px !important; }
  .h-pr-ml-1 {
    padding-right: 14px !important; }
  .h-pr-ml-2 {
    padding-right: 28px !important; }
  .h-pr-ml-3 {
    padding-right: 42px !important; }
  .h-pr-ml-4 {
    padding-right: 56px !important; }
  .h-pr-ml-5 {
    padding-right: 70px !important; }
  .h-pb-ml-0 {
    padding-bottom: 0px !important; }
  .h-pb-ml-1 {
    padding-bottom: 14px !important; }
  .h-pb-ml-2 {
    padding-bottom: 28px !important; }
  .h-pb-ml-3 {
    padding-bottom: 42px !important; }
  .h-pb-ml-4 {
    padding-bottom: 56px !important; }
  .h-pb-ml-5 {
    padding-bottom: 70px !important; } }

@media screen and (min-width: 912px) {
  .h-mt-l-0 {
    margin-top: 0px !important; }
  .h-mt-l-1 {
    margin-top: 14px !important; }
  .h-mt-l-2 {
    margin-top: 28px !important; }
  .h-mt-l-3 {
    margin-top: 42px !important; }
  .h-mt-l-4 {
    margin-top: 56px !important; }
  .h-mt-l-5 {
    margin-top: 70px !important; }
  .h-ml-l-0 {
    margin-left: 0px !important; }
  .h-ml-l-1 {
    margin-left: 14px !important; }
  .h-ml-l-2 {
    margin-left: 28px !important; }
  .h-ml-l-3 {
    margin-left: 42px !important; }
  .h-ml-l-4 {
    margin-left: 56px !important; }
  .h-ml-l-5 {
    margin-left: 70px !important; }
  .h-mb-l-0 {
    margin-bottom: 0px !important; }
  .h-mb-l-1 {
    margin-bottom: 14px !important; }
  .h-mb-l-2 {
    margin-bottom: 28px !important; }
  .h-mb-l-3 {
    margin-bottom: 42px !important; }
  .h-mb-l-4 {
    margin-bottom: 56px !important; }
  .h-mb-l-5 {
    margin-bottom: 70px !important; }
  .h-mr-l-0 {
    margin-right: 0px !important; }
  .h-mr-l-1 {
    margin-right: 14px !important; }
  .h-mr-l-2 {
    margin-right: 28px !important; }
  .h-mr-l-3 {
    margin-right: 42px !important; }
  .h-mr-l-4 {
    margin-right: 56px !important; }
  .h-mr-l-5 {
    margin-right: 70px !important; }
  .h-pt-l-0 {
    padding-top: 0px !important; }
  .h-pt-l-1 {
    padding-top: 14px !important; }
  .h-pt-l-2 {
    padding-top: 28px !important; }
  .h-pt-l-3 {
    padding-top: 42px !important; }
  .h-pt-l-4 {
    padding-top: 56px !important; }
  .h-pt-l-5 {
    padding-top: 70px !important; }
  .h-pr-l-0 {
    padding-right: 0px !important; }
  .h-pr-l-1 {
    padding-right: 14px !important; }
  .h-pr-l-2 {
    padding-right: 28px !important; }
  .h-pr-l-3 {
    padding-right: 42px !important; }
  .h-pr-l-4 {
    padding-right: 56px !important; }
  .h-pr-l-5 {
    padding-right: 70px !important; }
  .h-pb-l-0 {
    padding-bottom: 0px !important; }
  .h-pb-l-1 {
    padding-bottom: 14px !important; }
  .h-pb-l-2 {
    padding-bottom: 28px !important; }
  .h-pb-l-3 {
    padding-bottom: 42px !important; }
  .h-pb-l-4 {
    padding-bottom: 56px !important; }
  .h-pb-l-5 {
    padding-bottom: 70px !important; } }

@media screen and (min-width: 1024px) {
  .h-mt-xl-0 {
    margin-top: 0px !important; }
  .h-mt-xl-1 {
    margin-top: 14px !important; }
  .h-mt-xl-2 {
    margin-top: 28px !important; }
  .h-mt-xl-3 {
    margin-top: 42px !important; }
  .h-mt-xl-4 {
    margin-top: 56px !important; }
  .h-mt-xl-5 {
    margin-top: 70px !important; }
  .h-ml-xl-0 {
    margin-left: 0px !important; }
  .h-ml-xl-1 {
    margin-left: 14px !important; }
  .h-ml-xl-2 {
    margin-left: 28px !important; }
  .h-ml-xl-3 {
    margin-left: 42px !important; }
  .h-ml-xl-4 {
    margin-left: 56px !important; }
  .h-ml-xl-5 {
    margin-left: 70px !important; }
  .h-mb-xl-0 {
    margin-bottom: 0px !important; }
  .h-mb-xl-1 {
    margin-bottom: 14px !important; }
  .h-mb-xl-2 {
    margin-bottom: 28px !important; }
  .h-mb-xl-3 {
    margin-bottom: 42px !important; }
  .h-mb-xl-4 {
    margin-bottom: 56px !important; }
  .h-mb-xl-5 {
    margin-bottom: 70px !important; }
  .h-mr-xl-0 {
    margin-right: 0px !important; }
  .h-mr-xl-1 {
    margin-right: 14px !important; }
  .h-mr-xl-2 {
    margin-right: 28px !important; }
  .h-mr-xl-3 {
    margin-right: 42px !important; }
  .h-mr-xl-4 {
    margin-right: 56px !important; }
  .h-mr-xl-5 {
    margin-right: 70px !important; }
  .h-pt-xl-0 {
    padding-top: 0px !important; }
  .h-pt-xl-1 {
    padding-top: 14px !important; }
  .h-pt-xl-2 {
    padding-top: 28px !important; }
  .h-pt-xl-3 {
    padding-top: 42px !important; }
  .h-pt-xl-4 {
    padding-top: 56px !important; }
  .h-pt-xl-5 {
    padding-top: 70px !important; }
  .h-pr-xl-0 {
    padding-right: 0px !important; }
  .h-pr-xl-1 {
    padding-right: 14px !important; }
  .h-pr-xl-2 {
    padding-right: 28px !important; }
  .h-pr-xl-3 {
    padding-right: 42px !important; }
  .h-pr-xl-4 {
    padding-right: 56px !important; }
  .h-pr-xl-5 {
    padding-right: 70px !important; }
  .h-pb-xl-0 {
    padding-bottom: 0px !important; }
  .h-pb-xl-1 {
    padding-bottom: 14px !important; }
  .h-pb-xl-2 {
    padding-bottom: 28px !important; }
  .h-pb-xl-3 {
    padding-bottom: 42px !important; }
  .h-pb-xl-4 {
    padding-bottom: 56px !important; }
  .h-pb-xl-5 {
    padding-bottom: 70px !important; } }

.h-text-normal {
  font-weight: 400; }

.h-text-bold {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.h-text-strikethrough {
  position: relative; }
  .h-text-strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 0.0625rem solid;
    border-color: inherit;
    transform: rotate(-25deg); }

.h-text-small {
  font-size: 0.875rem;
  line-height: 1.42857; }

.h-text-large {
  font-size: 1.125rem;
  line-height: 1.38889; }

.h-text-xs {
  font-size: 1rem;
  line-height: 1.5625; }
  @media screen and (min-width: 640px) {
    .h-text-xs {
      font-size: 1.125rem;
      line-height: 1.38889; } }

.visuallyhidden {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .visuallyhidden {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .visuallyhidden {
      clip: rect(1px 1px 1px 1px); } }

[v-cloak] {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    [v-cloak] {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    [v-cloak] {
      clip: rect(1px 1px 1px 1px); } }

.h-visible-s {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-s {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-s {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 16px) {
    .h-visible-s {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 16px) and (-ms-high-contrast: active), screen and (min-width: 16px) and (-ms-high-contrast: none) {
    .h-visible-s {
      clip: auto; } }
  @media screen and (min-width: 16px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-s {
          clip: auto; } } }

.h-visible-sm {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-sm {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-sm {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 480px) {
    .h-visible-sm {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
    .h-visible-sm {
      clip: auto; } }
  @media screen and (min-width: 480px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-sm {
          clip: auto; } } }

.h-visible-m {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-m {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-m {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 640px) {
    .h-visible-m {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
    .h-visible-m {
      clip: auto; } }
  @media screen and (min-width: 640px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-m {
          clip: auto; } } }

.h-visible-ml {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-ml {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-ml {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 768px) {
    .h-visible-ml {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .h-visible-ml {
      clip: auto; } }
  @media screen and (min-width: 768px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-ml {
          clip: auto; } } }

.h-visible-l {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-l {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-l {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 912px) {
    .h-visible-l {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 912px) and (-ms-high-contrast: active), screen and (min-width: 912px) and (-ms-high-contrast: none) {
    .h-visible-l {
      clip: auto; } }
  @media screen and (min-width: 912px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-l {
          clip: auto; } } }

.h-visible-xl {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-xl {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-xl {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 1024px) {
    .h-visible-xl {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), screen and (min-width: 1024px) and (-ms-high-contrast: none) {
    .h-visible-xl {
      clip: auto; } }
  @media screen and (min-width: 1024px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-xl {
          clip: auto; } } }

.h-visible-xxl {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-xxl {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-xxl {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 1200px) {
    .h-visible-xxl {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 1200px) and (-ms-high-contrast: active), screen and (min-width: 1200px) and (-ms-high-contrast: none) {
    .h-visible-xxl {
      clip: auto; } }
  @media screen and (min-width: 1200px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-xxl {
          clip: auto; } } }

.h-visible-xxxl {
  position: absolute;
  left: -999em;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .h-visible-xxxl {
      clip: rect(1px 1px 1px 1px); } }
  @supports (-ms-ime-align: auto) {
    .h-visible-xxxl {
      clip: rect(1px 1px 1px 1px); } }
  @media screen and (min-width: 1408px) {
    .h-visible-xxxl {
      position: static;
      left: auto;
      clip-path: none; } }
  @media screen and (min-width: 1408px) and (-ms-high-contrast: active), screen and (min-width: 1408px) and (-ms-high-contrast: none) {
    .h-visible-xxxl {
      clip: auto; } }
  @media screen and (min-width: 1408px) {
      @supports (-ms-ime-align: auto) {
        .h-visible-xxxl {
          clip: auto; } } }

.h-w-5 {
  width: 5% !important; }

.h-w-10 {
  width: 10% !important; }

.h-w-15 {
  width: 15% !important; }

.h-w-20 {
  width: 20% !important; }

.h-w-25 {
  width: 25% !important; }

.h-w-30 {
  width: 30% !important; }

.h-w-35 {
  width: 35% !important; }

.h-w-40 {
  width: 40% !important; }

.h-w-45 {
  width: 45% !important; }

.h-w-50 {
  width: 50% !important; }

.h-w-55 {
  width: 55% !important; }

.h-w-60 {
  width: 60% !important; }

.h-w-65 {
  width: 65% !important; }

.h-w-70 {
  width: 70% !important; }

.h-w-75 {
  width: 75% !important; }

.h-w-80 {
  width: 80% !important; }

.h-w-85 {
  width: 85% !important; }

.h-w-90 {
  width: 90% !important; }

.h-w-95 {
  width: 95% !important; }

.h-w-100 {
  width: 100% !important; }

/* ===== TRUMPS
 * This layer trumps all other layers, and has the power to override anything at all
 * that has gone before it. May contain utility and helper classes, hacks and overrides.
 * A lot of the declarations in this layer will carry !important. This is the highest
 * specificity layer – it includes the most explicit types of rule, with the most narrow focus.
 */
html.wf-active body {
  font-family: "NS Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

/*# sourceMappingURL=main.css.map */