/*
@name Intro
@description Represents the large header on top of a page.
@section components

@modifier .intro--illustrated - Used to style intro's containing an illustration on desktop and tablet

@markup
<div class="intro {{modifier_class}}">
    <div class="intro__section">
        <div class="intro__title">Title</div>
        <div class="intro__content">Content</div>
    </div>
    <div class="intro__section">
        <img class="intro__illustration" loading="eager">
    </div>
</div>
*/

.intro {
    @include grid-column-container();
    width: 100%;
    margin-bottom: toRem($grid-spacing-2);
}

.intro__section {
    width: 100%;

    @include respond-min($m) {
        @include grid-column(1/2);
    }
}

.intro__section--m-visible {
    display: none;

    @include respond-min($ml) {
        display: block;
    }
}

.intro__title:not(:last-child) {
    margin-bottom: toRem($grid-spacing);
}

.intro__subtitle {
    @include fontSize('m');

    margin-bottom: toRem($grid-spacing);

    color: $ns-blue;

    @include respond-min($m) {
        margin-top: toRem($grid-spacing / 2);
        margin-bottom: 0;
    }
}

.intro__content {
    color: $ns-grey-3;
}

.intro__content-text {
    margin-bottom: 0;
}

.intro__illustration {
    display: none;

    @include respond-min($ml) {
        display: block;
        // required to ensure correct display on IE11
        width: 100%;
    }
}

.intro--illustrated {
    align-items: center;

    .intro__section {
        @include grid-column(1);

        @include respond-min($ml) {
            @include grid-column(1/2);
        }
    }
}
