/*
@name Form
@description
Class to create a form markup with labels and their input fields. The `.form__label`
and `.form__input` are the wrappers around the labels and provide the division of
the space between them.

@section Atoms
@type UI
@extended false
@category form

@markup
<div class="form">
    <div class="form__row">
        <div class="form__label">
            <label>Name</label>
        </div>
        <div class="form__input">
            <input type="text" value="" placeholder="Input field"/>
        </div>
    </div>
</div>
*/

.form {
}

.form--centerLabels {

    .form__label {
        justify-content: flex-end;

        padding-right: toRem($grid-gutter / 2);
    }

}

.form__row {

    &:not(:last-child) {
        margin-bottom: toRem($default-margin-bottom);
    }

    @include respond-min($sm) {
        display: flex;
        flex-flow: row wrap;

        @include target('ie11') {
            flex-wrap: nowrap;
        }

    }

}

.form__label {
    display: flex;
    justify-content: left;
    align-items: center;

    @include respond-min($sm) {
        flex: 1 1 50%;
    }
}

.form__label--spaced {
    margin-bottom: toRem($default-margin-bottom / 4);
}

.form__label--title {
    color: $ns-blue;
    font-weight: 700;
    @include fontSmoothing;
}

.form__label--right-aligned {
    flex-direction: row-reverse;
    padding-right: $spacing-2;
}

.form__input {
    @include respond-min($sm) {
        flex: 1 1 50%;
    }
}


