// SLIDER
// ======

// SETTINGS
// --------

// VARS

$sliderTeaseWidth: 90%;
$sliderButtonSize: 40px;

// BASE
// ----

// SLIDER

.slider {
    position: relative;

    width: calc(100% + 56px);

    margin-top: toRem(-$grid-gutter / 2);
    margin-right: toRem(-$grid-gutter);
    margin-bottom: toRem($grid-gutter);
    margin-left: toRem(-$grid-gutter);
}

// PARTS
// -----

// INNER

.slider__inner {
    overflow-x: auto;

    .no-touch & {
        overflow-x: hidden;
    }

    @include respond-min($xxl) {

        .touch & {
            overflow-x: hidden;
        }

    }

}

// LIST

.slider__list {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: toRem($grid-gutter / 2) toRem($grid-gutter / 2);

    .no-touch & {
        overflow-x: visible;
        transition: transform 0.2s ease-out;
    }

    @include respond-min($xxl) {

        .touch & {
            overflow-x: visible;
            transition: transform 0.2s ease-out;
        }

    }

}

// ITEM

.slider__item {
    width: 100%;
    flex: 0 0 auto;
    padding: 0 ($grid-gutter / 2);
    margin-bottom: 0;
    display: flex;
    flex-flow: column nowrap;

    .no-touch & {
        &:last-of-type {
            padding-right: $grid-gutter / 2;
        }
    }

    .touch & {
        width: $sliderTeaseWidth;
    }

    // Qualified to target only :before pseudo when it's in a list-item. forgive me.
    // TODO: refactor list so :before styling is no longer applied on base level. After that, remove this.
    &:before {
        display: none;
    }

    &:last-of-type {
        padding-right: $grid-gutter;
    }

    @include respond-min($m) {
        width: percentage(1/2);

        .touch & {
            width: $sliderTeaseWidth / 2;
        }

    }

    @include respond-min($l) {
        width: percentage(1/3);

        .touch & {
            width: $sliderTeaseWidth / 3;
        }

    }

    @include respond-min($xxl) {

        .touch &,
        .no-touch & {
            width: percentage(1/4);
        }

    }



}

.slider__item-content {
    flex: 1 0 auto;
}

// BUTTON

.slider__button {
    display: none;

    position: absolute;
    top: 50%;
    z-index: 1; // temp. in stacking index!

    width: toRem($sliderButtonSize);
    height: toRem($sliderButtonSize);

    overflow: hidden;

    background-color: #fff;

    border: 0;
    border-radius: 50%;

    transform: translateY(-50%);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4);
    cursor: pointer;

    color: $ns-blue;
    text-align: center;

    .no-touch & {
        display: block;
    }

    &.is-hidden {
        // Important needed to override modernizr specificity level
        display: none !important;
    }

    @include respond-min($xxl) {

        .touch & {
            display: block;
        }

    }

}

.slider__button--next {
    right: toRem(8px);
}

.slider__button--prev {
    left: toRem(8px);

    .slider__button-icon {
        margin-left: -2px;
        transform: rotate(180deg);
    }

}

// OPTIONS
// -------

// INERTIAL
.slider--inertial {

    .slider__inner {
        // enables inertial scrolling on iOS
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 0; // Remove scrollbar space
            background: transparent; // Make scrollbar invisible
        }
    }
}

// EXTENSIONS
// ----------

.slider-container {
    width: 100%;
    position: relative;
}
