.information-bar {

    display: flex;
    flex-direction: column;
    padding-top: toRem($grid-spacing);
    padding-bottom: toRem($grid-spacing);

    @include respond-min($m) {
        display: block;
    }

    > .list--inline {
        width: 100%;
        margin-top: toRem($grid-gutter/2);
        @include respond-min($l) {
            width: auto;
            margin-top: 0;
            margin-left: toRem($grid-gutter);
        }
    }
}
