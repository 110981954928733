// ICON TEXT
// =========


// BASE
// ----

.icon-text {
    position: relative;

    display: inline-block;

    padding-left: 33px;
}

// PARTS
// -----

// TEXT

.icon-text__text {
    display: inline;
    color: $ns-blue;
}

// ICON

.icon-text__icon {
    position: absolute;
    top: 0;
    left: 0;
}
