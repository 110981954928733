.boxed-content {
    @include grid-row-container;

    padding-top: $grid-spacing-2;
    padding-bottom: $grid-spacing-2;
    border-top: solid toRem(1px) $border-color;

    @include respond-min($m) {
        padding-top: $grid-spacing-4;
        padding-bottom: $grid-spacing-4;
    }
}

.boxed-content__section {
    width: 100%;

    @include respond-min($m) {
        @include grid-column(1/2);
    }

    & + & {
        margin-top: $grid-spacing-4;

        @include respond-min($m) {
            margin-top: 0;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.boxed-content__header {
    margin-bottom: toRem($grid-spacing);

    @include respond-min($m) {
        margin-bottom: toRem($grid-spacing-2);
    }
}
