/*

@name Separator
@description Separator; used for thema pages.
@section Atoms
@type UI
@extended false

@markup
<hr class="separator" />
*/

.separator {
    width: 100%;
    background-color: $white;
    height: toRem(4px);
}
