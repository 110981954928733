/*
@name Form selectbox
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Atoms
@type UI
@extended false
@category form

@markup
<select class="form-select">
    <option>Optie 1</option>
    <option>Optie 2</option>
    <option>Optie 3</option>
 </select>

*/

.form-select {
    @include input;

    height: toRem(45px);
    padding-right: toRem($input-horizontal-space * 2.5);
    display: block;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: $ns-white;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2235px%22%20height%3D%2235px%22%20viewBox%3D%220%200%2035%2035%22%20enable-background%3D%22new%200%200%2035%2035%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpolygon%20points%3D%2217.5%2C20.3%2012%2C14.8%2012%2C16.2%2017.5%2C21.7%2023%2C16.2%2023%2C14.8%20%20%20%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20%23003082%3B%20%7D%3C%2Fstyle%3E%3C%2Fsvg%3E");
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2235px%22%20height%3D%2245px%22%20viewBox%3D%220%20-5%2035%2045%22%20enable-background%3D%22new%200%20-5%2035%2045%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cpath%20d%3D%22M0-5v45h35V-5H0z%20M23%2C16.2l-5.5%2C5.5L12%2C16.2v-1.4l5.5%2C5.5l5.5-5.5V16.2z%22%2F%3E%3C%2Fg%3E%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20%230063d3%3B%20%7D%3C%2Fstyle%3E%3C%2Fsvg%3E");
        cursor: pointer;
    }

    /* Option styling for Firefox */
    option {
        padding-left: toRem(10px / 2);
    }

    // Remove arrows in IE
    &::-ms-expand {
        display:none;
    }

    // Style IE value
    &:focus::-ms-value {
        background: transparent;
        color: $ns-blue;
        outline: toRem(1px) dotted;
    }
}

.form-select--fullWidth {
    display: block;
    width: 100%;
}
