.h-color-grey {
  color: $ns-grey-3 !important;
}

.h-color-white {
  color: #fff !important;
}

.h-color-primary {
  color: $ns-blue !important;
}
